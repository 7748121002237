import "../.././App.css";
import drag from "../../images/Settings/drag.svg";
import down from "../../images/Settings/down.svg";
import clear from "../../images/delete.svg";
import linkIMG from "../../images/Settings/link.svg";
import { useEffect, useState, useRef } from "react";
import socialsData from "../../Components/social.js";

function SocialItemList(props) {
  
  const [isOpen, setisOpen] = useState(false);
  const [title, setTitle] = useState(props.name);
  const [link, setLink] = useState(props.link);
  const [socials, setSocials] = useState(socialsData);

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState("Custom Link");
  const [selectedSocialIcon, setSelectedSocialIcon] = useState("");

  useEffect(() => {
    if (props.data.title !== null) {
      setSelectedSocial(props.data.title);
      var icon = socialsData.find((item) => item.title === props.data.title);
      setSelectedSocialIcon(icon?.icon);
    } else {
      setSelectedSocial("Custom Link");
      setSelectedSocialIcon(linkIMG);
    }
  }, [props.data]);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (navMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setNavMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [navMenuOpen]);

  return (
    <div
      className="navItem socialItemBlock"
      draggable
    >
      <div className="navEditableVisible" onClick={(e) => setisOpen(!isOpen)}>
        <div>
          <img className="dragIcon" src={drag} alt="Drag" />
          {selectedSocialIcon === "" ? <img
            className="socialIcon"
            src={linkIMG}
            alt="SocialIcon"
          /> : <img
            className="socialIcon"
            src={selectedSocialIcon}
            alt="SocialIcon"
          />}
          <p className="socialIconFix">{selectedSocial}</p>
        </div>
        <img
          className={isOpen === true ? "downRotated" : ""}
          src={down}
          alt="Down"
        />
      </div>

      {isOpen === true ? (
        <div className="customLinkRow">
          <p>Social network</p>
          <div className="navLinkRow">
            <div className="select socialLinkSelect" ref={ref}>
              <div
                className="selectMain"
                onClick={(e) => setNavMenuOpen(!navMenuOpen)}
              >
                <img src={selectedSocialIcon} alt="Icon" />
                <p className="selectMainairbnb">{selectedSocial}</p>
              </div>

              {navMenuOpen === true ? (
                <div className="selectListOption socialSelectOptions">
                  {socials.map((social) => (
                    <div
                      className="selectOption"
                      onClick={(e) => {
                        setSelectedSocial(social.title);
                        setSelectedSocialIcon(social.icon);
                        setNavMenuOpen(false);

                        var data = props.socialArray;
                        var index = data.findIndex(
                          (el) => el.id === props.data.id
                        );
                        data[index].title = social.title;

                        props.data.title = social.title;

                        setTitle(social.title)
                      }}
                    >
                      <img src={social.icon} alt="Icon" />
                      <p>{social.title}</p>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <p>URL</p>
          <div className="navLinkRow customLinkURL">
            <input
              type="text"
              placeholder={"URL"}
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                props.updateSocialArray(props.data.id, { url: e.target.value });
              }}
            />
            <img className="inputLink" src={linkIMG} alt="Link" />
           
          </div>
          <div
            className="deleteRow"
            onClick={(e) => {
              setisOpen(false);
              props.deleteItem(props.data.id);
            }}
          >
            <img src={clear} alt="Clear" />
            <p>Delete</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SocialItemList;
