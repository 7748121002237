import React, { useEffect, useRef, useState } from 'react'
import '../../App.css'
import PublicationModal from './PublicationModal';
import notes from "../../images/notes-icon.svg";
import DashBoardModal from './DashBoardModal';
import axios from 'axios';
import { Link } from 'react-router-dom';
import close from '../../../src/images/Settings/close.svg'
function PageModal({ redirectPage, setdashboardModal, pageModal, setpageModal, openPublicationModal }) {
    const popupRef = useRef();

    

    const goBackTo = () => {
        setpageModal(false);
        setdashboardModal(true);
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (pageModal  && popupRef?.current && !popupRef?.current.contains(e.target)) {
                setpageModal(false)
                setdashboardModal(false)
                openPublicationModal()
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [pageModal]);
    return (
        <>
            <div className='welcomeOverlayPopup'></div>
                <div className='welcomeModal' ref={popupRef}>
                    <div className="popupTopClose closePersonalwebsiteModal" onClick={() => {setpageModal(false);setdashboardModal(false);openPublicationModal()}}>
                        <img src={close}  alt="Icon" />
                    </div>
                    <p className='welcomeTitle'>Write your first page</p>
                    <p className='welcomeText'>Now is the perfect time to create your first page on your personal website. We recommend starting with an "About Me" page, where you can introduce yourself and share your work with your visitors.</p>

                    <div className="popupButtons">
                        <button className='settingsWhite billingSaveBtn' type="button" onClick={goBackTo}>
                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg><span>Back</span>
                        </button>
                        <Link to={`${`/dashboard/page/${redirectPage}`}`}>
                            <button className='settingsBlue billingSaveBtn verifyLoader' onClick={openPublicationModal}>
                                <span>Let's write</span><svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg", marginLeft: "5px", marginTop: '1px' }}>
                                    <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </Link>
                    </div>

                </div>
            {/* {showPublicationModal && <PublicationModal activeStep={activeStep} setActiveStep={setActiveStep} />} */}
        </>
    )
}

export default PageModal