import '../.././App.css';

function PaymentMethod({paymentMethods}) {

    let defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.default);

    if( defaultPaymentMethod === undefined ) {
        defaultPaymentMethod = paymentMethods[0];
    }
    
    return (
        <div className="method">
            <div className={`card-icon ${defaultPaymentMethod.brand}`}>
            </div>
            <div className='methodData'>
                <p>{defaultPaymentMethod.brand} •••• {defaultPaymentMethod.last4}</p>
                <p>Expiry {defaultPaymentMethod.exp_month}/{defaultPaymentMethod.exp_year}</p>
            </div>
        </div>
    );
}

export default PaymentMethod;
