import '../.././App.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import pay_method from '../../images/Settings/bank-card-line.svg'
import userAvatar from '../../images/user-avatar.svg'
import profileAssistant from '../../images/profile-assistant.png'
import { planOptions } from '../Component'

function Step5(props) {
    const [activeTab, setActiveTab] = useState('month');
    const [name, setName] = useState(null)
    const [activePlan, setActivePlan] = useState('free_plan')
    const [cardNumber, setCardNumber] = useState(null)
    const [cvvCode, setCvvCode] = useState(null)
    const [monthInput, setMonthInput] = useState(null);
    const [yearInput, setYearInput] = useState(null);
    const [expiry, setExpiry] = useState('');

    const [validateName, setValidateName] = useState(false)
    const [validateExpiryMonth, setValidateExpiryMonth] = useState(false)
    const [validateExpiryYear, setValidateExpiryYear] = useState(false)
    const [validateNumber, setValidateNumber] = useState(false)
    const [validateCVV, setValidateCVV] = useState(false)
    const [isProfileAssistant, setProfileAssistant] = useState(false);
    const [profileImageSrc, setprofileImageSrc] = useState('')


    useEffect(() => {
        var data = props.data[4].data[0]
        if (data !== undefined && data != []) {
            setName(data.cardName)
            setActivePlan(data.plan)
            setExpiry(data.expiry)
            setCardNumber(data.cardNumber)
            setCvvCode(data.cvv)
            setActiveTab(data.planType)
        }

        var profileData = props.data[3]?.data[0] || ''

        if (profileData) {
            setprofileImageSrc(profileData.profileImageSrc);
        }

    }, [])

    function cc_format(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []

        var i;
        var len;

        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    const handleProfileAssistant = () => {
        setProfileAssistant(!isProfileAssistant);
    };
    const handleCardNumberKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 13) {
            event.preventDefault();
        }
    };

    const focusSibling = function (target, direction, callback) {
        const nextTarget = target[direction];
        nextTarget && nextTarget.focus();

        callback && callback(nextTarget);
    }

    const handleMonthInput = (event) => {
        const value = event.target.value.toString();
        let valuemonth = value;

        if (value.length === 1 && parseInt(value, 10) > 1) {
            valuemonth = "0" + value;
        } else if (value === "00") {
            valuemonth = "01";
        } else if (parseInt(value, 10) > 12) {
            valuemonth = "12";
        } else {
            if (value.length >= 2) {
                focusSibling(event.target, "nextElementSibling", (nextTarget) => {
                    if (nextTarget.tagName === "INPUT" && nextTarget.type === "text") {
                        nextTarget.focus();
                        nextTarget.select();
                    }
                });
            }
        }
        setMonthInput(valuemonth);
        if (value.length > 0) {
            setValidateExpiryMonth(false);
        }
    };

    const handleMonthInputBlur = (event) => {
        const value = event.target.value.toString();
        let valuemonth = value;

        if (parseInt(value, 10) > 0 && parseInt(value, 10) < 10) {
            if (value.length < 2) {
                valuemonth = "0" + value;
            }
        }

        setMonthInput(valuemonth);

        if (value.length > 0) {
            setValidateExpiryMonth(false);
        }
    }

    const handleYearInput = (event) => {
        const value = event.target.value.toString();
        if (event.key === "Backspace" && event.target.selectionStart === 0) {
            focusSibling(event.target, "previousElementSibling");
        }

        setYearInput(value);
        if (value.length > 0) {
            setValidateExpiryYear(false);
        }
    };

    const handleYearInputBlur = (event) => {
        const value = event.target.value.toString();
        let valueyear = value;

        if (parseInt(value, 10) > 0 && parseInt(value, 10) < 10) {
            if (value.length < 2) {
                valueyear = "0" + value;
            }
        }

        setYearInput(valueyear);

        if (value.length > 0) {
            setValidateExpiryYear(false);
        }
    }

    props.step(5)

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='step'>
            <div className='step_content step-billing'>
                <div className="step-user-avatar">
                    <div className='user-avatar-wrapper'>
                        <img src={userAvatar} alt="userAvatar" />
                        {profileImageSrc && <img src={profileImageSrc} alt="userAvatar" className="user-avatar" />}
                    </div>
                    <h2 className='step_title'>
                        Billing
                    </h2>
                    <p className='step_contentSubtitle'>
                        Start your web profile for free, unlimited or choose the Assistant Plan to have an assistant always at your side.
                    </p>
                </div>
                <div className='step_content_wrapp'>
                    <div>
                        <div className="step-plan-buttons">
                            <button onClick={() => handleTabClick('month')} className={activeTab === 'month' ? 'active' : ''} >Monthly</button>
                            <button onClick={() => handleTabClick('year')} className={activeTab === 'year' ? 'active' : ''}>Yearly (Save 20%)</button>
                            <button className='step-plan-background'></button>
                        </div>
                        <div className="step-plan-content">
                            {activeTab === 'month' &&
                                <div className='step-plan-card'>
                                    <div
                                        onClick={(e) => setActivePlan("free_plan")}
                                        className={
                                            activePlan === "free_plan"
                                                ? "step-plan-item billingPlanActive"
                                                : "step-plan-item"
                                        }
                                    >
                                        <div className='step-plan-top step-plan-free'>
                                            <h2>Free</h2>
                                            <p>$0</p>
                                            <input type="radio" className='step-plan-check' name='plan' checked={activePlan === "free_plan" ? true : false} />
                                        </div>
                                        <div className="step-plan-brief">
                                            <ul className='step-plan-list'>
                                                <li className='step-plan-icon-page'>
                                                    3 Pages
                                                </li>
                                                <li className='step-plan-icon-publication'>
                                                    5 Publications / month
                                                </li>
                                                <li className='step-plan-icon-newsletter'>
                                                    5 Newsletters / month
                                                </li>
                                            </ul>
                                            <ul className='step-plan-list-two'>
                                                <li className='step-plan-icon-banner'>
                                                    “Built with” Banner
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        onClick={(e) => setActivePlan("business_plan")}
                                        className={
                                            activePlan === "business_plan"
                                                ? "step-plan-item billingPlanActive"
                                                : "step-plan-item"
                                        }
                                    >
                                        <div className='step-plan-top step-plan-free'>
                                            <h2>Plan 1</h2>
                                            <p>$18/month</p>
                                            <input type="radio" className='step-plan-check' name='plan' checked={activePlan === "business_plan" ? true : false} />
                                        </div>
                                        <div className="step-plan-brief">
                                            <ul className='step-plan-list'>
                                                <li className='step-plan-icon-page'>
                                                    Unlimited Pages
                                                </li>
                                                <li className='step-plan-icon-publication'>
                                                    Unlimited Publications
                                                </li>
                                                <li className='step-plan-icon-newsletter'>
                                                    Unlimited Newsletters
                                                </li>
                                            </ul>
                                            <ul className='step-plan-list-two'>
                                                <li className='step-plan-icon-builts'>
                                                    “Built with” Banner
                                                </li>
                                                <li className='step-plan-icon-form'>
                                                    Contact Form
                                                </li>
                                                <li className='step-plan-icon-upload'>
                                                    Upload Files & Videos
                                                </li>
                                                <li className='step-plan-icon-ai'>
                                                    Write with AI
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 'yearly' &&

                                <div className='step-plan-card'>
                                    <div
                                        className={
                                            activePlan === "free_plan"
                                                ? "step-plan-item billingPlanActive"
                                                : "step-plan-item"
                                        }
                                        onClick={(e) => setActivePlan("free_plan")}
                                    >
                                        <div className='step-plan-top step-plan-free'>
                                            <h2>Free</h2>
                                            <p>$0</p>
                                            <input type="radio" className='step-plan-check' name='plan' checked={activePlan === "free_plan" ? true : false} />
                                        </div>
                                        <div className="step-plan-brief">
                                            <ul className='step-plan-list'>
                                                <li className='step-plan-icon-page'>
                                                    3 Pages
                                                </li>
                                                <li className='step-plan-icon-publication'>
                                                    5 Publications / month
                                                </li>
                                                <li className='step-plan-icon-newsletter'>
                                                    5 Newsletters / month
                                                </li>
                                            </ul>
                                            <ul className='step-plan-list-two'>
                                                <li className='step-plan-icon-banner'>
                                                    “Built with” Banner
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        onClick={(e) => setActivePlan("business_plan")}
                                        className={
                                            activePlan === "business_plan"
                                                ? "step-plan-item billingPlanActive"
                                                : "step-plan-item"
                                        }
                                    >
                                        <div className='step-plan-top step-plan-free'>
                                            <h2>Plan 1</h2>
                                            <p>$18/month</p>
                                            <input type="radio" className='step-plan-check' name='plan' checked={activePlan === "business_plan" ? true : false} />
                                        </div>
                                        <div className="step-plan-brief">
                                            <ul className='step-plan-list'>
                                                <li className='step-plan-icon-page'>
                                                    Unlimited Pages
                                                </li>
                                                <li className='step-plan-icon-publication'>
                                                    Unlimited Publications
                                                </li>
                                                <li className='step-plan-icon-newsletter'>
                                                    Unlimited Newsletters
                                                </li>
                                            </ul>
                                            <ul className='step-plan-list-two'>
                                                <li className='step-plan-icon-builts'>
                                                    “Built with” Banner
                                                </li>
                                                <li className='step-plan-icon-form'>
                                                    Contact Form
                                                </li>
                                                <li className='step-plan-icon-upload'>
                                                    Upload Files & Videos
                                                </li>
                                                <li className='step-plan-icon-ai'>
                                                    Write with AI
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="profile-assistant-card">
                            <div className="profile-item">
                                <div className="profile-assistant-content">
                                    <div className="profile-check-box">
                                        <input type="checkbox" className='step-plan-check' id="assistant-check" onChange={handleProfileAssistant} checked={isProfileAssistant} />
                                        <label htmlFor="assistant-check" className="profile-assistant-name">
                                            Profile Assistant
                                            <span className="profile-sub-text">$18/month</span>
                                        </label>
                                    </div>
                                    <p className="profile-sub-text">
                                        We are here to help manage your web profile, in order to make sure it’s always updated with your latest content. <Link to={"/"}>Read more</Link> about the service.
                                    </p>
                                </div>
                            </div>
                            <div className="profile-item">
                                <img src={profileAssistant} alt="profile-img" />
                            </div>
                        </div>
                    </div>

                    {
                        activePlan === "business_plan" &&
                        <>
                            <div className="popupRow popupRow-card">
                                <div className='card-holder'>
                                    <h2 className='label-text'>Cardholder Name</h2>
                                    <input type="text" onChange={e => {
                                        setName(e.target.value)
                                        setValidateName(false)
                                    }} onBlur={e => {
                                        if (e.target.value.length === 0) {
                                            setValidateName(true)
                                        }
                                    }} value={name} placeholder='The name on the card' className={(validateName === true) ? 'inputError' : ''} />
                                    {
                                        (validateName === true) ? <p className='validateError'>The field is not filled</p> : ''
                                    }
                                </div>
                            </div>

                            <div className="popupRow card-number-details">
                                <div className='paymentMethod'>
                                    <h2 className='label-text'>Card Number</h2>
                                    <input type="text" placeholder='1234 1234 1234 1234' className={(validateNumber === true) ? 'cardNumber inputError' : 'cardNumber'}
                                        onKeyPress={handleCardNumberKeyPress}
                                        value={cardNumber}
                                        onChange={e => {
                                            var test = cc_format(e.target.value)
                                            setCardNumber(test)

                                            if (test.length > 0) {
                                                setValidateNumber(false)
                                            }

                                        }} />
                                    {
                                        (validateNumber === true) ? <p className='validateError'>The field is not filled</p> : ''
                                    }
                                    <img className='payMethod' src={pay_method} alt="Icon" />
                                </div>
                                <div className='card-wrap-details'>
                                    <div className='expire-date'>
                                        <div className={`${validateExpiryMonth ? 'inputError' : ''} exp-wrapper`}>
                                            <input
                                                className='expMonth'
                                                autocomplete="off"
                                                id="month"
                                                maxlength="2"
                                                pattern="[0-9]*"
                                                inputmode="numerical"
                                                placeholder="MM"
                                                type="text"
                                                data-pattern-validate
                                                value={monthInput}
                                                onChange={handleMonthInput}
                                                onBlur={handleMonthInputBlur}
                                            />

                                            <input
                                                className='expYear'
                                                autocomplete="off"
                                                id="year"
                                                maxlength="2"
                                                pattern="[0-9]*"
                                                inputmode="numerical"
                                                placeholder="YY"
                                                type="text"
                                                data-pattern-validate
                                                value={yearInput}
                                                onChange={handleYearInput}
                                                onBlur={handleYearInputBlur}
                                            />
                                        </div>
                                        {
                                            (validateExpiryYear === true) ? <p className='validateError'>The field is not filled</p> : ''
                                        }
                                    </div>
                                    <div className='cvvBlock'>
                                        <input type="text" value={cvvCode} placeholder='CVC' className={(validateCVV === true) ? 'inputError cvvfields' : 'cvvfields'} onChange={e => {
                                            if (e.target.value.length > 4) {
                                                return false
                                            } else {
                                                setCvvCode(e.target.value)
                                                if (e.target.value.length > 0) {
                                                    setValidateCVV(false)
                                                }
                                            }
                                        }} />
                                        {
                                            (validateCVV === true) ? <p className='validateError'>The field is not filled</p> : ''
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="card-number-price">
                                <p>
                                    <span>Total:</span> $24/month
                                </p>
                            </div>
                        </>
                    }

                    <div className="regButtons regBack">
                        <button onClick={e => props.step(4)} className='backButton'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z" fill="#525866" />
                            </svg>
                            Back
                        </button>
                        <button
                            onClick={(e) => {
                                if (
                                    activePlan === 'business_plan' &&
                                    name &&
                                    name.length > 0 &&
                                    monthInput &&
                                    monthInput.length > 0 &&
                                    yearInput &&
                                    yearInput.length > 0 &&
                                    cardNumber &&
                                    cardNumber.length > 0 &&
                                    cvvCode &&
                                    cvvCode.length > 0
                                ) {
                                    const copy = [...props.data];
                                    const BillingIndex = copy[4]?.data?.findIndex((item) => item.hasOwnProperty('plan'));

                                    if (BillingIndex !== -1) {
                                        copy[4].data[BillingIndex] = {
                                            isProfileAssistant: isProfileAssistant,
                                            planType: activeTab,
                                            plan: activePlan,
                                            cardName: name,
                                            expiry: `${monthInput}/${yearInput}`,
                                            cardNumber: cardNumber,
                                            cvv: cvvCode,
                                            status: 'done',
                                        };
                                    } else {
                                        copy[4].data.push({
                                            isProfileAssistant: isProfileAssistant,
                                            planType: activeTab,
                                            plan: activePlan,
                                            cardName: name,
                                            expiry: `${monthInput}/${yearInput}`,
                                            cardNumber: cardNumber,
                                            cvv: cvvCode,
                                            status: 'done',
                                        });
                                    }
                                    props.addData(copy);
                                    props.step(6);

                                    if (!name || name.length === 0) {
                                        setValidateName(true);
                                    }
                                    if (!monthInput || monthInput.length === 0) {
                                        setValidateExpiryMonth(true);
                                    }
                                    if (!yearInput || yearInput.length === 0) {
                                        setValidateExpiryYear(true);
                                    }
                                    if (!cardNumber || cardNumber.length === 0) {
                                        setValidateNumber(true);
                                    }
                                    if (!cvvCode || cvvCode.length === 0) {
                                        setValidateCVV(true);
                                    }

                                } else {
                                    const copy = [...props.data];
                                    const BillingIndex = copy[4]?.data?.findIndex((item) => item.hasOwnProperty('plan'));

                                    if (BillingIndex !== -1) {
                                        copy[4].data[BillingIndex] = {
                                            isProfileAssistant: isProfileAssistant,
                                            planType: activeTab,
                                            plan: activePlan,
                                            cardName: '',
                                            expiry: '',
                                            cardNumber: '',
                                            cvv: '',
                                            status: 'done',
                                        };
                                    } else {
                                        copy[4].data.push({
                                            isProfileAssistant: isProfileAssistant,
                                            planType: activeTab,
                                            plan: activePlan,
                                            cardName: '',
                                            expiry: '',
                                            cardNumber: '',
                                            cvv: '',
                                            status: 'done',
                                        });
                                    }
                                    copy[4].status = "done";
                                    props.addData(copy);
                                    props.step(6);
                                }
                            }}
                            className='btn_toStep'
                        >
                            Continue
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z" fill="white" />
                            </svg>
                        </button>

                    </div>
                    <div className='regContact'>
                        <p>
                            Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Step5;