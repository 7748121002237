import '../.././App.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import userAvatar from '../../images/user-avatar.svg';
import axios from "axios";
import BgFormAnimate from '../../Pages/BgFormAnimate';
import RegStatus from './RegStatus';
import logo from "../../images/register-logo.svg"
import { dataURLtoFile } from '../../utils/functions';

function Step6(props) {
    const [profileImageSrc, setprofileImageSrc] = useState()
    const [profileSlug, setProfileSlug] = useState('')
    const [profileSlugValidate, setProfileSlugValidate] = useState(false)
    const [profileSlugError, setProfileSlugError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [featureImage, setFeatureImage] = useState(null);

    const step4Data = props?.data[3].data[0];
    useEffect(() => {
        var data = props.data[5].data[0]

        var profileData = props.data[3]?.data[0] || ''

        if (profileData) {
            setprofileImageSrc(profileData);
        }

        if (data !== undefined && data != []) {
            setProfileSlug(data?.profileSlug)
        }
        setImageFile(step4Data?.profileImageSrc || null)
        setFeatureImage(step4Data?.coverImageSrc || null)
    }, [])

    const handleBlur = () => {
        if (!isLoading) {
            const sanitizedValue = profileSlug
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^a-z0-9-]/g, '')
                .replace(/-+/g, '-')
                .replace(/^-+|-+$/g, '');

            setProfileSlug(sanitizedValue);
        }
    };

    const handleKeyPress = (e) => {
        if (e?.key === "Enter") {
            handleCreateWebProfile(e);
        }
    };

    const handleCreateWebProfile = async (e) => {
        e.preventDefault();

        if (profileSlug?.length === 0) {
            setProfileSlugValidate(true);
            return;
        }

        setIsLoading(true);

        var profileImageUpload = '';
        var coverImageUpload = '';
        var uploadeProfileCroppeddMedia = '';
        var uploadeCoverCroppeddMedia = '';
        setFeatureImage(step4Data?.profileImageSrc || '');

        try {
            const registerData = { profileSlug };
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/checkProfileURL`, registerData);
            if (response?.data?.success) {
                var copy = [...props.data]
                if (imageFile !== null && featureImage !== null) {
                    try {
                        const profileImageFile = step4Data?.profileImage || '';
                        if (profileImageFile !== '') {
                            const profileImageFormData = new FormData();
                            profileImageFormData.append('files', profileImageFile);
                            profileImageFormData.append('slug', profileSlug);
                            profileImageUpload = await axios.post(
                                `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                                profileImageFormData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            );

                            const profileCroppedImageFile = await dataURLtoFile(step4Data?.profileImageSrc, "profile-picture.jpg");
                            const profileCroppedImageFormData = new FormData();
                            profileCroppedImageFormData.append('files', profileCroppedImageFile);
                            profileCroppedImageFormData.append('slug', profileSlug);
                            uploadeProfileCroppeddMedia = await axios.post(
                                `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                                profileCroppedImageFormData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            );
                        }


                        const coverImageFile = step4Data?.coverImage || '';
                        if (coverImageFile !== '') {
                            const coverImageFormData = new FormData();
                            coverImageFormData.append('files', coverImageFile);
                            coverImageFormData.append('slug', profileSlug);
                            coverImageUpload = await axios.post(
                                `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                                coverImageFormData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            );

                            const coverCroppedImageFile = await dataURLtoFile(step4Data?.coverImageSrc, "cover-picture.jpg");
                            const coverCroppedImageFormData = new FormData();
                            coverCroppedImageFormData.append('files', coverCroppedImageFile);
                            coverCroppedImageFormData.append('slug', profileSlug);
                            uploadeCoverCroppeddMedia = await axios.post(
                                `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                                coverCroppedImageFormData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            );
                        }
                    } catch (error) {
                        setIsLoading(true);
                        console.log(error);
                    }
                    const profileUrlIndex = copy[5].data.findIndex(item => item.hasOwnProperty('profileSlug'));
                    if (profileUrlIndex !== -1) {
                        copy[5].data[profileUrlIndex].profileSlug = profileSlug;
                    } else {
                        copy[5].data.push({
                            profileSlug: profileSlug, profileImageSrc, imageId: {
                                profileImageId: profileImageUpload?.data?.result?.[0]?._id || "",
                                profileCroppedId: uploadeProfileCroppeddMedia?.data?.result[0]?._id || '',
                                coverImageId: coverImageUpload?.data?.result?.[0]?._id || "",
                                coverCroppedId: uploadeCoverCroppeddMedia?.data?.result[0]?._id || ''
                            }
                        });

                    }

                }

                copy[5].status = 'done'
                props.addData(copy)
                props.step(7)
            } else {
                setProfileSlugError(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 201) {
                setProfileSlugError(true);
            } else {
                console.log(error, "error");
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            <BgFormAnimate />
            <div>
                <div className='step register-content'>
                    <div className="regLogo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='step_content'>
                        <div className="step-user-avatar">
                            {/* <div className='user-avatar-wrapper'>
                                <img src={userAvatar} alt="userAvatar" />
                                {profileImageSrc && <img src={profileImageSrc} alt="userAvatar" className="user-avatar" />}
                            </div> */}
                            <h2 className='step_title'>Unique URL</h2>
                            <p className='step_contentSubtitle'>
                                Choose a custom URL that reflects your identity. Make sure it's easy for others to remember and share.
                            </p>
                        </div>
                        <div className='step_content_wrapp'>
                            <div className='user-profile'>
                                <div className="user_email">
                                    <h1>Profile URL</h1>
                                    <div className='profile-url-input'>
                                        <div className='profile-url-default'>
                                            <p>
                                                https://persone.me/
                                            </p>
                                        </div>
                                        <input autoFocus={true} value={profileSlug}
                                            onBlur={handleBlur}
                                            onKeyPress={(e) => { handleKeyPress(e) }}
                                            readOnly={isLoading}
                                            onChange={e => {
                                                setProfileSlugError(false);
                                                setProfileSlug(e.target.value)

                                                if (e.target.value.length > 0) {
                                                    setProfileSlugValidate(false)
                                                }
                                            }
                                            } placeholder='Enter your job title' className={(profileSlugValidate === true) ? 'first_name inputError' : 'first_name'} type="text" />
                                    </div>

                                    {
                                        (profileSlugError === true) ?
                                            <>
                                                <p className='validateError'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                        <path d="M8.00016 5.83301V8.49967M8.00016 11.1663H8.00683M14.6668 8.49967C14.6668 12.1816 11.6821 15.1663 8.00016 15.1663C4.31826 15.1663 1.3335 12.1816 1.3335 8.49967C1.3335 4.81778 4.31826 1.83301 8.00016 1.83301C11.6821 1.83301 14.6668 4.81778 14.6668 8.49967Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    This URL is already taken, please try another one.
                                                </p>
                                            </>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="regButtons regBack">
                                <button onClick={e => props.step(4)} className='backButton'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z" fill="#525866" />
                                    </svg>
                                    Back</button>
                                <button className={`finish_btn ${isLoading || profileSlugError || profileSlug?.length === 0 ? 'disabledBtn' : ''}`} onClick={handleCreateWebProfile}>
                                    {isLoading ? (
                                        <div className="settingPage-loader">
                                            <div className="ring-loader"></div>
                                        </div>
                                    ) : (
                                        <>
                                            Finish
                                        </>
                                    )}
                                </button>
                            </div>
                            <div className='register_nav'>
                                <RegStatus statusReg={props.data} setStatusReg={props.addData} activeStep={props.activeStep} setActiveStep={props.step} />
                            </div>
                            {/* <div className='regContact'>
                                <p>
                                    Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step6;