import { useRef, useState, useEffect } from 'react';
import '.././App.css';
import link from '.././images/link.svg'
import more from '.././images/more.svg'
import { Link } from 'react-router-dom';
import PageActionDropdown from './PageActionDropdown';

function NewsletterListItem(props) {
    let data = props.data;
    let type = props.type;
    let isOpen = props.isOpen;
    let togglePopup = props.togglePopup;

    const ref = useRef();
    const [activateEdit, setActivateEdit] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (activateEdit && ref.current && !ref.current.contains(e.target) && (e.target.classList.contains('dropdown'))) {
                setActivateEdit(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [activateEdit]);

    const [checkboxActive, setCheckboxActive] = useState(false)

    useEffect(() => {
        if (props.allSelected === true) {
            setCheckboxActive(true)
        } else {
            setCheckboxActive(false)
        }
    }, [props.allSelected])

    var createdAt = data.createdAt;
    
    var createdAtDate = new Date(createdAt);
    
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    
    var formattedCreatedAt = createdAtDate.toLocaleDateString('en-US', options);

    const handleCheckboxChange = (itemId) => {
        props.handleCheckboxChange(itemId);
    };

      const changeStatus = (newStatus) => {
        data.status = newStatus;
        props.updateStatus(data._id, newStatus);
      };

      useEffect(() => {
        function handleClickOutside(event) {            
            if (isOpen && (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, togglePopup]);  


      function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    return (
        <div className="pagesTableItem newsletterTable newsLetterList">                         
            <div className="pagesTableCheck"><input type="checkbox" checked={props.selectedItems.includes(data._id)} onChange={() => handleCheckboxChange(data._id)} /> {data.status === "Deleted" ? data?.subject : <Link className='editTitle' to={`/dashboard/newsletter/${props.data._id}`}>{data.subject}</Link>}</div>
            <div className='statusTab'><span className={(data.status === "Send") ? 'greenStatus' : (data.status === 'Draft') ? 'draftStatus' : (data.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}>{data.status === "Send"? "Sent" : data.status }</span></div>
            
            <div><span className={`${data.status === 'Draft' ? 'toSubject draftnewsletter' : 'toSubject'}`}>{`${data.status === 'Draft' ? "-" : data?.sendTo?.plan === "All Subscribers" || data?.sendTo?.plan === "" || data?.sendTo?.plan === undefined ? "All Subscribers" : data?.sendTo?.plan }`}</span></div>
            <div>{formattedCreatedAt}</div>
            <div className='pagesTableLink'><Link target={'_blank'} to={data.link}><button className='pagesLinkButton'><img src={link} alt="Link" /> Link</button></Link></div>
            <div className='pagesTableAction newsletterAction' onClick={togglePopup} ref={ref}>
                <img src={more} alt="More" />
            </div>
            {
                (isOpen === true) ? <PageActionDropdown togglePopup={togglePopup} changeStatus={changeStatus}  setPages={props.setPages} updateActivateEdit={updateActivateEdit} updateOriginalData={props.updateOriginalData} type={type} data={data} index={props.data._id} setShowNotification={props?.setShowNotification} /> : ''
            }
        </div>
    );
}
export default NewsletterListItem;
