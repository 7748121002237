import { useState, useEffect } from 'react';
import axios from 'axios';
import '.././App.css';
import calculatePercentage from './Function';
import { Link } from "react-router-dom"
import linkExternal from ".././images/link-external.svg";
import arrow from ".././images/icon-arrow-view.svg";
import edit from ".././images/pen.svg";

function InfoList({ analyticsData, isLoading, filtersData }) {

    
    const [topPages, setTopPages] = useState(analyticsData.topPages);
    const [filters, setFilters] = useState();
    const [pages, setPages] = useState([])

    const refData = {
        Regular: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99998 13.3341H14M2 13.3341H3.11636C3.44248 13.3341 3.60554 13.3341 3.75899 13.2973C3.89504 13.2646 4.0251 13.2108 4.1444 13.1377C4.27895 13.0552 4.39425 12.9399 4.62486 12.7093L13 4.33414C13.5523 3.78185 13.5523 2.88642 13 2.33413C12.4477 1.78185 11.5523 1.78185 11 2.33414L2.62484 10.7093C2.39424 10.9399 2.27894 11.0552 2.19648 11.1898C2.12338 11.3091 2.0695 11.4391 2.03684 11.5752C2 11.7286 2 11.8917 2 12.2178V13.3341Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> ,
        Link: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.47203 12.2423L7.52922 13.1851C6.22748 14.4868 4.11693 14.4868 2.81518 13.1851C1.51343 11.8834 1.51343 9.7728 2.81518 8.47106L3.75799 7.52825M12.2433 8.47106L13.1861 7.52825C14.4878 6.2265 14.4878 4.11595 13.1861 2.8142C11.8843 1.51245 9.77378 1.51245 8.47203 2.8142L7.52922 3.75701M5.66729 10.333L10.334 5.6663" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        File: <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33317 1.51367V4.26737C8.33317 4.64073 8.33317 4.82742 8.40583 4.97003C8.46975 5.09547 8.57173 5.19745 8.69718 5.26137C8.83978 5.33403 9.02647 5.33403 9.39984 5.33403H12.1535M8.33317 11.334H4.33317M9.6665 8.66732H4.33317M12.3332 6.65947V11.4673C12.3332 12.5874 12.3332 13.1475 12.1152 13.5753C11.9234 13.9516 11.6175 14.2576 11.2412 14.4493C10.8133 14.6673 10.2533 14.6673 9.13317 14.6673H4.8665C3.7464 14.6673 3.18635 14.6673 2.75852 14.4493C2.3822 14.2576 2.07624 13.9516 1.88449 13.5753C1.6665 13.1475 1.6665 12.5874 1.6665 11.4673V4.53398C1.6665 3.41388 1.6665 2.85383 1.88449 2.426C2.07624 2.04968 2.3822 1.74372 2.75852 1.55197C3.18635 1.33398 3.7464 1.33398 4.8665 1.33398H7.00769C7.49687 1.33398 7.74146 1.33398 7.97163 1.38924C8.17571 1.43824 8.3708 1.51905 8.54974 1.6287C8.75157 1.75239 8.92453 1.92534 9.27043 2.27124L11.3959 4.39673C11.7418 4.74263 11.9148 4.91558 12.0385 5.11741C12.1481 5.29636 12.2289 5.49145 12.2779 5.69552C12.3332 5.9257 12.3332 6.17029 12.3332 6.65947Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        Images: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.84822 13.8186L7.24592 9.42091C7.50993 9.1569 7.64193 9.0249 7.79415 8.97544C7.92805 8.93193 8.07228 8.93193 8.20617 8.97544C8.35839 9.0249 8.4904 9.15691 8.75441 9.42091L13.1228 13.7893M9.3335 10L11.2459 8.08758C11.5099 7.82357 11.6419 7.69156 11.7942 7.6421C11.928 7.5986 12.0723 7.5986 12.2062 7.6421C12.3584 7.69156 12.4904 7.82357 12.7544 8.08758L14.6668 10M6.66683 6C6.66683 6.73638 6.06988 7.33333 5.3335 7.33333C4.59712 7.33333 4.00016 6.73638 4.00016 6C4.00016 5.26362 4.59712 4.66667 5.3335 4.66667C6.06988 4.66667 6.66683 5.26362 6.66683 6ZM4.5335 14H11.4668C12.5869 14 13.147 14 13.5748 13.782C13.9511 13.5903 14.2571 13.2843 14.4488 12.908C14.6668 12.4802 14.6668 11.9201 14.6668 10.8V5.2C14.6668 4.0799 14.6668 3.51984 14.4488 3.09202C14.2571 2.71569 13.9511 2.40973 13.5748 2.21799C13.147 2 12.5869 2 11.4668 2H4.5335C3.41339 2 2.85334 2 2.42552 2.21799C2.04919 2.40973 1.74323 2.71569 1.55148 3.09202C1.3335 3.51984 1.3335 4.0799 1.3335 5.2V10.8C1.3335 11.9201 1.3335 12.4802 1.55148 12.908C1.74323 13.2843 2.04919 13.5903 2.42552 13.782C2.85334 14 3.41339 14 4.5335 14Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        Videos: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.977 7.6158C11.2574 7.77842 11.3976 7.85973 11.4506 7.973C11.4964 8.0703 11.4964 8.18429 11.4506 8.28159C11.3976 8.39486 11.2574 8.47617 10.977 8.63879L7.94949 10.4277C7.685 10.5774 7.55275 10.6523 7.42386 10.6468C7.31024 10.6419 7.20355 10.5781 7.14201 10.4763C7.07108 10.3591 7.07108 10.1618 7.07108 9.7672V6.03118C7.07108 5.63655 7.07108 5.43923 7.14201 5.32201C7.20355 5.22023 7.31024 5.15641 7.42386 5.15153C7.55275 5.14606 7.685 5.22098 7.94949 5.37069L10.977 7.15959Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.16096 2.6665H9.83904C11.891 2.6665 12.917 2.6665 13.6255 3.37498C14.334 4.08345 14.334 5.10946 14.334 7.16142V8.83859C14.334 10.8906 14.334 11.9166 13.6255 12.6251C12.917 13.3335 11.891 13.3335 9.83904 13.3335H6.16096C4.109 13.3335 3.083 13.3335 2.37452 12.6251C1.66604 11.9166 1.66604 10.8906 1.66604 8.83859V7.16142C1.66604 5.10946 1.66604 4.08345 2.37452 3.37498C3.083 2.6665 4.109 2.6665 6.16096 2.6665Z" stroke="#425466" stroke-width="1.5"/>
        </svg>,
        Newsletter: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2388_6714)">
            <path d="M6.99952 9.00012L13.9995 2.00012M7.08457 9.21883L8.83665 13.7242C8.991 14.1211 9.06817 14.3195 9.17937 14.3774C9.27577 14.4277 9.39061 14.4277 9.48706 14.3776C9.59833 14.3198 9.67574 14.1215 9.83056 13.7247L14.2241 2.46625C14.3639 2.10813 14.4337 1.92907 14.3955 1.81465C14.3623 1.71528 14.2844 1.6373 14.185 1.60411C14.0706 1.56588 13.8915 1.63576 13.5334 1.77552L2.27489 6.16908C1.87817 6.32389 1.67981 6.4013 1.62201 6.51257C1.5719 6.60903 1.57196 6.72386 1.62219 6.82026C1.68013 6.93146 1.87858 7.00864 2.27547 7.16299L6.78081 8.91506C6.86137 8.94639 6.90166 8.96206 6.93558 8.98625C6.96564 9.0077 6.99193 9.03399 7.01338 9.06406C7.03758 9.09798 7.05324 9.13826 7.08457 9.21883Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2388_6714">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>,
        Page: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6667 7.33333H8.33333M11.6667 10H8.33333M11.6667 4.66667H8.33333M6 2L6 14M5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2C2 4.07989 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> 
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/page/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const result = response?.data?.combinedResults;
            const draftPages = result.filter(page => page.status === "Draft").slice(0, 10);
            setPages(draftPages);
        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const [currentState, setCurrentState] = useState("all");


    const handleElementClick =  (itemName, tabName, itemTitle) => {
        let filterData = filters;

        if (tabName === 'page' && !filters.includes('visit:page')) {
            filterData += `;event:page==${itemName}`;
            filtersData[tabName] = { title: 'Page', value: itemTitle, filterName: `;event:page==${itemName}`, operation: 'is' };
          } else if (tabName === 'source' && !filters.includes('visit:source')) {
            filterData += `;visit:source==${itemName}`;
            filtersData[tabName] = { title: 'Source', value: itemTitle, filterName: `;visit:source==${itemName}`, operation: 'is' };
          } 
        
      };
      useEffect(() => {
        if (currentState == 'publications') {
          setTopPages(analyticsData.topPages.filter(page => page.pageType === 'publication'));
        } else if (currentState == 'pages') {
          setTopPages(analyticsData.topPages.filter(page => ['home', 'page', 'contact'].includes(page.pageType)));
        } else {
          setTopPages(analyticsData.topPages);
        }
      }, [currentState, analyticsData]);

      const handleDetailsClick = () => {
        
    
      };
      

    return (
        <div className="infoBoxes">
            <div className="infoTitle infobox-content">
                <div className="infoTitleHeader">
                    <p className='dashUserStatus'>Top Content & Top Sources</p>
                    <div className="infoTabBtnAnalytics">
                        <button className={currentState === "all" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("all")} >
                            All
                        </button>
                        <button className={currentState === "pages" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("pages")} >
                            Pages
                        </button>
                        <button className={currentState === "publications" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("publications")} >
                            Publications
                        </button>
                        <button className={currentState === "newsletters" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("newsletters")} >
                            Newsletters
                        </button>
                        <button className={currentState === "sources" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("sources")} >
                        Sources
                        </button>
                    </div>
                </div>

                <div className="infoTitleGrid infoTitleGridAnalytics">
                    <div className="infoReferrersBlock infoTitles">
                        <div> Source </div>
                        <div> Visitors </div>
                    </div>
                    <div className="infoRefWrap">
                        {!isLoading ? (
                            topPages && topPages.length > 0 ? (
                                topPages.map((item, index) => (
                                    <div key={index} className="infoReferrersBlock">
                                        <div className="title">
                                            <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, topPages.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                                            <p onClick={() => handleElementClick(item?.page || "/", "page", item?.page || "Home")}>{item?.page || "/"} </p>
                                            <Link to={`https://jourmal-next-front.vercel.app${item?.page}`} target="blank"><img src={linkExternal} alt={item?.page || "/"} /></Link>
                                        </div>
                                        <div>
                                            <p className="graphNumber">{item?.visitors || 0}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-data">No data yet</p>
                            )
                        ) : (
                            <>
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            </>
                        )}
                    </div>
                    {!isLoading && topPages && topPages.length > 0 && (
                        <div
                            className="viewAll"
                            onClick={() => {
                                if (currentState === "all") {
                                    handleDetailsClick("topPages", "Top Content");
                                } else if (currentState === "pages") {
                                    handleDetailsClick("topPages", "Top Pages");
                                } else if (currentState === "publications") {
                                    handleDetailsClick("topPages", "Top Publications");
                                } else {
                                    handleDetailsClick("topPages", "Top Newsletters");
                                }
                            }}
                        >
                            <Link to="#">
                                View All
                                <img src={arrow} alt="Logo" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className="infoReferrers infoDraft">
                <div className="infoTitleHeader">
                    <p className='dashUserStatus'>Drafts</p>
                </div>
                <div className="infoTitleGrid">
                    <div className='infoReferrersBlock referrersBlock referrersTitle'>
                        <div>Name</div>
                        <div>Edit</div>
                    </div>
                    {
                        pages.map((item, index) => (
                            <div key={index} className='infoReferrersBlock referrersBlock'>
                                <div>
                                    <Link className='titleLogo' target="_blank" to={`/dashboard/${item?.page_type ? item.page_type : 'newsLetter'}/${item?._id}`}>
                                        {item?.page_type == 'page'? refData.Page : item?.page_type == 'publication' ? refData[item.publication_type]: refData.Newsletter }{item?.page_type == 'page' || item?.page_type == 'publication' ? item?.title : item?.subject }
                                    </Link>
                                </div>
                                <div className='draft-pen-icon'>
                                    <Link target="_blank" to={`/dashboard/${item?.page_type ? item.page_type : 'newsLetter'}/${item?._id}`}>
                                        <p className='graphNumber'><img src={edit} alt="Logo" /></p>
                                    </Link>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default InfoList;
