import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { format } from "date-fns";

import AnalyticsOnlineVisitors from "../Components/AnalyticsOnlineVisitors"
import AnalyticsCharts from "../Components/AnalyticsCharts";
import InfoListAnalytics from "../Components/InfoListAnalytics";
import DatePicker from "../Components/DatePicker";
import FilterPopup from "../Components/FilterPopup";

import dateFormatter, { formatISO, getCompareDate, today, currentMonth, currentYear, getDateRangeOfMonth, getDateRangeOfYear, firstDateOfLastMonth, lastDateOfLastMonth, firstDateOfYear, lastDateOfYear, getNextDate, getPrevDate } from "../utils/dateFormatter";

import search from ".././images/search.svg";
import down from ".././images/Settings/down.svg";


import ".././App.css";
import NotificationMessage from "../Components/NotificationMessage";

export default function Analytics(props) {
  props.setActivePage("Contact-Analytics");
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [analyticsData, setAnalyticsData] = useState([]);
  const [onlineVisitors, setOnlineVisitors] = useState(0)

  const [isLoading, setIsLoading] = useState(false);
  const [filtersData, setFiltersData] = useState({ page: '', browser: '', browserVersion: '', os: '', osVersion: '', size: '', country: '', region: '', city: '' });
  const [apiFilter, setapiFilter] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [showPeriods, setShowPeriods] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCompareDatePicker, setShowCompareDatePicker] = useState(false);
  const [customDates, setCustomDates] = useState({ startDate: '', endDate: '' });
  const [compareCustomDates, setCompareCustomDates] = useState({ startDate: '', endDate: '' });
  const [customDate, setCustomDate] = useState('');

  const [selectedPeriodLbl, setSelectedPeriodLbl] = useState('Last 30 Days');
  const [selectedComapreLbl, setSelectedComapreLbl] = useState('Previous period');

  const [nextDateDisable, setNextDateDisable] = useState(true);
  const [prevDateDisable, setPrevDateDisable] = useState(false);
  const [subscriberNotify, setSubscriberNotify] = useState(false);
  const [subscriberCount, setSubscriberCount]= useState(0)

  var startDate = format(new Date(), "MMMM d, yyyy");
  var endDate = format(new Date(), "MMMM d, yyyy");

  const [datePickerValue, setDatePickerValue] = useState(startDate + " - " + endDate);

  const datevalue = (data) => {
    setDatePickerValue(data)
  }

  const popupRef = useRef(null);
  const periodsRef = useRef(null);
  const filterRef = useRef(null);
  const customRef = useRef(null);

  // Close the popup when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }

      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }

      if (periodsRef.current && !periodsRef.current.contains(event.target)) {
        setShowPeriods(false);
        setShowDatePicker(false);
      }
      if (customRef.current && !customRef.current.contains(event.target)) {
        setShowCompareDropdown(false);
        setShowCompareDatePicker(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const filters = [
    { value: "pages", label: "Contents" },
    { value: "sources", label: "Sources" },
    { value: "location", label: "Locations" },
    { value: "devices", label: "Devices" },
  ];
  const periods = [
    { value: "day", label: "Today" },
    { value: "7d", label: "Last 7 Days" },
    { value: "30d", label: "Last 30 Days" },
    { value: "month", label: "Month to Date" },
    { value: "last_month", label: "Last Month" },
    { value: "year", label: "Year to Date" },
    { value: "12mo", label: "Last 12 Months" },
    { value: "all", label: "All Time" },
    { value: "custom", label: "Custom Range" },
    { value: "compare", label: "Compare" }
  ];
  const comparePeriod = [
    { value: "disable", label: "Disable comparison" },
    { value: "previous_period", label: "Previous period" },
    { value: "year_over_year", label: "Year over year" },
    { value: "custom_period", label: "Custom period" }
  ];

  const ref = useRef();
  const [selectedPeriod, setSelectedPeriod] = useState(periods[2]); // Default to "Last 30 Days"
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [tempPeriod, setTempPeriod] = useState(periods[2]);
  const [previousDate, setPreviousDate] = useState(getCompareDate(today, '30d', 'previous_period'));

  const [selectedFilter, setSelectedFilter] = useState([]);


  const [showCompare, setShowCompare] = useState(false);
  const [showCompareDropdown, setShowCompareDropdown] = useState(false);
  const [selectedComaprePeriod, setSelectedComaprePeriod] = useState(comparePeriod[1]);
  const [selectedComapreValue, setSelectedComapreValue] = useState({ label: '', value: '' });


  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const userData = async () =>{
    try{
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        )
        setSubscriberCount(response?.data?.subscriberCount)
        if(response?.data?.subscriberCount > 0){
            setSubscriberNotify(true)
        }
    }catch(error){
        console.log(error, "error");  
    }
  }
  useEffect(()=>{
      userData()
  },[])

  const fetchData = async (filterData = '', filtersData = {}) => {
    try {
      let setPeriod = selectedPeriod?.value || '30d';

      
      if (setPeriod === 'custom' && (customDates.startDate == '' || customDates.endDate == '')) {
        return false;
      }

      if (setPeriod === 'compare') {
        setPeriod = tempPeriod?.value || '30d';
      }

      let date = today;
      let compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value);

      if (showCompare) {
        if ( selectedComaprePeriod.value === 'custom_period' && (compareCustomDates.startDate == '' || compareCustomDates.endDate == '')) {
          return false;
        }
        
        setPeriod = tempPeriod?.value || '30d';
      }

      const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today);
      compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value, compareCustomDates);

      if (setPeriod === 'day') {
        setPeriod = 'day';
        date = selectedDate;
        compareDate = previousDate || getCompareDate(selectedDate, setPeriod, selectedComaprePeriod.value);
      } else if (setPeriod === 'month') {
        date = `${customDates.startDate},${customDates.endDate}`;
        compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'last_month') {
        date = `${firstDateOfLastMonth},${lastDateOfLastMonth}`;
        compareDate = getCompareDate(firstDateOfLastMonth, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'year') {
        date = `${customDates.startDate},${customDates.endDate}`;
        compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'all') {
        setPeriod = 'custom';
        date = `${createdAtDate},${today}`;
      } else if (setPeriod === 'custom') {
        setPeriod = 'custom';
        date = `${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`;
        compareDate = getCompareDate(`${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`, setPeriod, selectedComaprePeriod.value,compareCustomDates);
      }

      const period = setPeriod;

      setapiFilter(filterData);
      setFiltersData(filtersData);
      setIsLoading(true);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/getAnalyticsData/${JSON.parse(localStorage.getItem("userinfo"))?._id}?filters=${filterData}&period=${period}&date=${date}&compare=${showCompare}&compareDate=${compareDate}`,
      )

      const result = response?.data?.result;

      setAnalyticsData(result);
      setOnlineVisitors(result?.realtimeVisitors || 0);
      
      setIsLoading(false);

      //setAllFiltered(response?.data?.result);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
  }

  useEffect(() => {
    const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today);
    const createdAtYear = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today, 'year');

    if (selectedComaprePeriod.value == 'custom_period' && compareCustomDates.startDate !== '' && compareCustomDates.endDate !== '') {
      setSelectedComapreLbl(`${dateFormatter(compareCustomDates.startDate, 'date', '', false)} - ${dateFormatter(compareCustomDates.endDate, 'date', '', false)}`)
    } else if( selectedComaprePeriod.value === 'disable'){
      setSelectedComapreLbl('Previous period')
    } else{
        setSelectedComapreLbl(selectedComaprePeriod.label)
    }
    if (selectedPeriod.value === 'day') {
      setCustomDate(selectedDate);
      setNextDateDisable(false);
      setPrevDateDisable(false);

      setSelectedPeriodLbl(dateFormatter(selectedDate, 'date', '', true));

      if (createdAtDate === selectedDate) {
        setPrevDateDisable(true);
      }

      if (today === selectedDate) {
        setNextDateDisable(true);
      }
    } else if (selectedPeriod.value === 'month') {

    } else if (selectedPeriod.value === 'year') {
      setNextDateDisable(false);
      setPrevDateDisable(false);

      if (selectedYear == currentYear) {
        setNextDateDisable(true);
      }
      if (selectedYear == createdAtYear) {
        setPrevDateDisable(true);
      }
      if (createdAtYear === currentYear) {
        setNextDateDisable(true);
        setPrevDateDisable(true);
      }
      setSelectedPeriodLbl(`Year of ${selectedYear}`);
    } else if (selectedPeriod.value === 'custom' && customDates.startDate !== '' && customDates.endDate !== '') {
      setSelectedPeriodLbl(`${dateFormatter(customDates.startDate, 'date', '', false)} - ${dateFormatter(customDates.endDate, 'date', '', false)}`)
    } else {
      if (selectedPeriod.value !== 'compare') {
        setSelectedPeriodLbl(selectedPeriod.label)
      }
    }
    if (showDatePicker === false && showCompareDatePicker === false) {
      fetchData(apiFilter, filtersData);
    }

  }, [selectedPeriod, selectedDate, customDates, showCompare, selectedComaprePeriod, compareCustomDates]);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (datePickerOpen && ref.current && !ref.current.contains(e.target)) {
        setDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [datePickerOpen]);

  const handlePrevDate = () => {
    if (!prevDateDisable) {

      if (selectedPeriod.value == 'day') {
        const prevDate = getPrevDate(selectedDate);

        setSelectedDate(prevDate);
        setCustomDate(prevDate);

        if (showCompare) {
          const comPrevDate = getPrevDate(previousDate);
          setPreviousDate(comPrevDate);
        }
      }

      if (selectedPeriod.value === 'month') {
        const createdMonth = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today, 'month');
        const prevMonth = getPrevDate(selectedPeriodLbl, 'month');
        const monthRange = getDateRangeOfMonth(prevMonth);

        setCustomDates(monthRange);
        setSelectedPeriodLbl(prevMonth);
        setNextDateDisable(false);

        if (prevMonth === createdMonth) {
          setPrevDateDisable(true);
        }
      }

      if (selectedPeriod.value === 'year') {
        const prevYear = getPrevDate(selectedPeriodLbl, 'year');
        const yearRange = getDateRangeOfYear(`Year of ${prevYear}`);

        setSelectedYear(prevYear);
        setCustomDates(yearRange);
      }
    }
  };

  const handleNextDate = () => {
    if (!nextDateDisable) {
      if (selectedPeriod.value == 'day') {
        const nextDate = getNextDate(selectedDate);
        setSelectedDate(nextDate);
        setCustomDate(nextDate);
        if (showCompare) {
          const comNextDate = getNextDate(previousDate);
          setPreviousDate(comNextDate);
        }
      }

      if (selectedPeriod.value === 'month') {
        const nextMonth = getNextDate(selectedPeriodLbl, 'month');
        const monthRange = getDateRangeOfMonth(nextMonth);

        setCustomDates(monthRange);
        setSelectedPeriodLbl(nextMonth);
        setPrevDateDisable(false);

        if (nextMonth === currentMonth) {
          setNextDateDisable(true);
        }
      }

      if (selectedPeriod.value === 'year') {
        const nextYear = getNextDate(selectedPeriodLbl, 'year');
        const yearRange = getDateRangeOfYear(`Year of ${nextYear}`);

        setSelectedYear(nextYear)
        setCustomDates(yearRange);
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        selectedPeriod.length > 0
          ? (document.getElementsByClassName(
            "select-container"
          )[0].style.marginBottom = "20px")
          : (document.getElementsByClassName(
            "select-container"
          )[0].style.marginBottom = "0px");
      } else {
        document.getElementsByClassName(
          "select-container"
        )[0].style.marginBottom = "";
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedPeriod]);

  const handleRemoveFilter = async (filterTitle, filterValue) => {

    filtersData[filterTitle] = {};

    const updatedApiFilter = apiFilter.replace(filterValue, '');
    const updatedFilterData = filtersData;

    setapiFilter(updatedApiFilter);
    setFiltersData(updatedFilterData);

    await fetchData(updatedApiFilter, updatedFilterData);
  };

  const handleChangeComaprePeriod = (period) => {
    if (period.value === 'disable') {
      setShowCompare(false);
      setSelectedComaprePeriod(comparePeriod[1]);
    } else {
      setSelectedComaprePeriod(period);
    }
    setSelectedComapreValue(period)
    if (period.value === 'custom_period') {
      setShowCompareDatePicker(true);
    }
    setShowCompareDropdown(false);
  }

  const handleChangePeriod = (period) => {
    setShowPeriods(false);
    setSelectedPeriod(period);
    setCustomDate('');

    if (period.value !== 'compare') {
      setTempPeriod(period)
    }

    setNextDateDisable(true);
    setPrevDateDisable(true);

    if (period.value === 'custom') {
      setShowDatePicker(true)
    } else if (period.value === 'compare') {
      setShowCompare(!showCompare);
    } else {
      setSelectedPeriod(period);
    }

    if (period.value === 'day') {
      setCustomDate(today)
      setSelectedDate(today);
      setNextDateDisable(true);
      setPrevDateDisable(false);
      setSelectedPeriodLbl(dateFormatter(today, 'date', '', true))
    }

    if (period.value === 'month') {
      const monthRange = getDateRangeOfMonth(currentMonth);

      setSelectedPeriodLbl(currentMonth);
      setCustomDates(monthRange);
      setNextDateDisable(true);
      setPrevDateDisable(false);
    }

    if (period.value === 'year') {
      const yearRange = getDateRangeOfYear(`Year of ${currentYear}`);

      setSelectedYear(currentYear);
      setCustomDates(yearRange);

      setNextDateDisable(true);
      setPrevDateDisable(false);
    }

    if (period.value === 'all') {
      setShowCompare(false);
    }

    if (showCompare) {
      const comPrevDate = getCompareDate(today, period.value, selectedComaprePeriod.value);
      setPreviousDate(comPrevDate)

    }
  }

  const handleChangeFilter = (filter) => {
    setShowFilters(false);
    setSelectedFilter(filter);
  }

  return (
    <section className="settings analytics">
      <div className="container">
        <div className="dashboardTop">
          <div className="dashboardTopText">
            <div className="dashboardTopLeft">
              <h1 className="pageTitle">Analytics</h1>
              <p className="pageSubTitle">Track, manage and forecast your customers and orders.</p>
            </div>
          </div>
        </div>

        <div className="analyticsContent">
          <div className="online-visitors" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <AnalyticsOnlineVisitors onlineVisitors={onlineVisitors} showTooltip={showTooltip} />
            <ul className="browser">
              {
                Object.entries(filtersData).map(([title, value]) => {
                  if (title && value) {
                    // Check if value is an object
                    if (typeof value === 'object' && value.title && value.value) {
                      const filterValues = value.value.split('|');
                      return (
                        <React.Fragment key={title}> {/* Use a unique key */}
                          <li>
                            {value.title}{value.operation === 'is' ? ':' : ' is not'} {filterValues.map((value, index) => (
                              <React.Fragment key={index}>
                                <b>{value}</b> {index < filterValues.length - 1 ? 'or ' : null}
                              </React.Fragment>
                            ))}
                            <span className="icon-remove" onClick={() => handleRemoveFilter(title, value.filterName)}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-4">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z">
                                </path>
                              </svg>
                            </span>
                          </li>
                        </React.Fragment>
                      );
                    }
                  }
                  return null; // Return null if either title or value is falsy
                }).flat() // Flatten the array of fragments 
              }
            </ul>

          </div>

          <div className="analyticsSelect">
            <div className="filter-selections" ref={filterRef}>
              <div className="select-container homepage-select selected-filter" onClick={() => setShowFilters(!showFilters)}>
                Filter <img src={search} alt='Filter' />
              </div>
              <div className="filter-options selections-dropdown">
                {showFilters && filters.map((filter) => (
                  <div
                    key={filter.value}
                    onClick={() => {
                      handleChangeFilter(filter);
                      setShowPopup(true); // Set showPopup to true here
                    }}
                  >
                    {filter.label}
                  </div>
                ))}
              </div>
            </div>

            {/* Render the popup if showPopup is true */}
            {
              !isLoading &&
              <FilterPopup
                selectedFilter={selectedFilter}
                showPopup={showPopup} // Pass the state controlling popup visibility
                setShowPopup={setShowPopup} // Pass the function to update popup visibility
                popupRef={popupRef}
                analyticsData={analyticsData}
                filtersData={filtersData}
                apiFilter={apiFilter}
                fetchData={fetchData}
              />
            }

            {/* Arrow-section Show condition */}
            {selectedPeriod &&
              (selectedPeriod.value === "day" || selectedPeriod.value === "month" || selectedPeriod.value === "year") && (
                <div className="arrow-section">
                  <button className={prevDateDisable && 'disable'} onClick={handlePrevDate}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                  </button>
                  <button className={nextDateDisable && 'disable'} onClick={handleNextDate}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                  </button>
                </div>
              )}

            <div className="period-selections datepickerButton" ref={periodsRef}>
              <div className="selected-period" onClick={() => setShowPeriods(!showPeriods)} >{selectedPeriodLbl} <img src={down} alt={selectedPeriod.label} /></div>
              <div className="periods-options selections-dropdown">
                {showPeriods && periods.map((period) => (
                  <div
                    key={period.value}
                    className={((selectedPeriod.value !== 'day' && selectedPeriod.value !== 'month' && selectedPeriod.value !== 'year') && ((showCompare && tempPeriod.value === period.value) || (!showCompare && ((selectedPeriod.value !== 'compare' && selectedPeriod.value === period.value) || selectedPeriod.value === 'compare' && tempPeriod.value === period.value)))) && `selected`}
                    onClick={() => { handleChangePeriod(period) }}
                  >
                    {period.value === 'compare' && showCompare ? 'Disable comparison' : period.label}
                  </div>
                ))}

                {
                  showDatePicker && <DatePicker datevalue={datevalue} setShowDatePicker={setShowDatePicker} setCustomDates={setCustomDates} />
                }

              </div>
            </div>

            {
              showCompare &&
              <>
                <span> vs. </span>
                <div className="period-selections compare-period datepickerButton" ref={customRef}>
                  <div className="selected-period" onClick={() => setShowCompareDropdown(!showCompareDropdown)} >{selectedComapreLbl} <img src={down} alr={selectedComapreLbl} /></div>
                  <div className="periods-options selections-dropdown">
                    {showCompareDropdown && comparePeriod.map(period => (
                      <div
                        key={period.value}
                        className={selectedComaprePeriod.value === period.value ? 'selected' : ''}
                        onClick={() => handleChangeComaprePeriod(period)}
                      >
                        {period.label}
                      </div>
                    ))}
                    {showCompareDatePicker &&
                      <DatePicker datevalue={datevalue} setShowDatePicker={setShowCompareDatePicker} setCustomDates={setCompareCustomDates} />
                    }
                  </div>
                </div>
              </>
            }

          </div>
        </div>

        <AnalyticsCharts graphData={analyticsData.graphData} compareData={analyticsData.compareData} isLoading={isLoading} aggregateData={analyticsData.aggregateData} compareAggregateData={analyticsData.compareAggregateData} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} setSelectedDate={setSelectedDate} showCompare={showCompare} setTempPeriod={setTempPeriod} setPreviousDate={setPreviousDate} />

        <InfoListAnalytics analyticsData={analyticsData} fetchData={fetchData} isLoading={isLoading} filtersData={filtersData} apiFilter={apiFilter} />
        {(subscriberNotify && subscriberCount > 0) && (
            <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
        )}
      </div>
    </section>
  );
}
