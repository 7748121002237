import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Chart } from 'chart.js';
export const TotalEarningChart = () => {
    const [totalEarning, setTotalEarning] = useState([])
    const userId = `${JSON.parse(localStorage.getItem("userinfo"))?._id}`

    const fetchGetTotalEarningsGraph = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/subscriber/getTotalEarningsGraph/${userId}`,
            );
            setTotalEarning(response?.data?.result)
        } catch (error) {
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchGetTotalEarningsGraph()
    }, [userId]);

    useEffect(() => {
        const ctx = document.getElementById('totalEarningChart').getContext('2d');
        const xValues = totalEarning.map(item => item.date);
        const yValues = totalEarning.map(item => item.amount);
        var gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, 'rgba(101,116,205, 0.2)');
        gradient.addColorStop(1, 'rgba(101,116,205, 0)');
        const earningChart = new Chart(ctx, {
            type: 'line',
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false
                    }
                },
                
            },
            data: {
                labels: xValues,
                datasets: [{
                    label: "", 
                    data: yValues,
                    fill: true,
                    backgroundColor: gradient,
                    borderColor: 'rgba(60,70,110,0.2)',
                    pointRadius: 0,
                    pointHitRadius: 0,
                    borderColor: 'rgba(101,116,205)',
                    pointBorderColor: "transparent",
                    tension: 0.3,
                    pointBackgroundColor: 'rgba(101,116,205)',
                    pointHoverBackgroundColor: 'rgba(71, 87, 193)',
                    display: false, 
                }]
            },
        });
        return () => {
            // Cleanup chart on unmount
            earningChart.destroy();
        };
    }, [totalEarning]);
    
    return (
        <>
            <canvas id="totalEarningChart"></canvas>
        </>
    )
}
