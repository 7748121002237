import { useEffect, useState } from 'react';
import '.././App.css';

function AuthorBlock(props) {

    const [name, setName] = useState('')
    const [url, setURL] = useState('')

    useEffect(() => {
        setName(props.data.author)
        setURL(props.data.website)
    }, [props.data])


    const updateAuthorData = () => {
        props.updateAuthor(props.data,
            {
                author: name,
                website: url
            });
    };

    return (
        <div className="adressInputBlock authorInput authorBlockInputs">
            <input type="text" placeholder='Full name....' className={(props.data.author !== '') ? 'inputReadOnly' : ''} value={name} onChange={e => setName(e.target.value)}  onBlur={updateAuthorData} />
            <input type="text" placeholder='Website (optional)' className={(props.data.website !== '') ? 'inputReadOnly' : ''} value={url} onChange={e => setURL(e.target.value)}  onBlur={updateAuthorData} />
        </div>
    );
}

export default AuthorBlock; 