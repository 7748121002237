import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ".././App.css";
import login from "../images/reset.svg";
import back from "../images/back.svg";
import PassReset from "../Components/PassReset";
import axios from "axios";
import BgFormAnimate from './BgFormAnimate';

function NewPass() {
  const [newPassword, setNewpassword] = useState("");
  const [pass, setPass] = useState("");
  const history = useNavigate()
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [validatePass, setValidatePass] = useState(false);
  const [validateRePass, setValidateRePass] = useState(false);
  const [matchPassword, setMatchPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [authDone, setAuthDone] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    // token verify function
    if (token) {
      const tokenVerify = () => {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/user/verifytoken?token=${token}`
          )
          .then((response) => {
            console.log(response, "response");
          })
          .catch((err) => {
            if (err.response.status === 400) {
             history("/login")
            }
          });
      };
      tokenVerify();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword.length >= 8) {
      setValidatePass(false);
    } else {
      setValidatePass(true);
    }

    if (pass.length > 0) {
      setValidateRePass(false);
    } else {
      setValidateRePass(true);
    }

    if (pass !== newPassword) {
      setMatchPassword(false);
    } else {
      setMatchPassword(true);
    }
    if (
      newPassword.length >= 8 &&
      pass === newPassword
    ) {
      setAuthDone(true);
      resetPassword();
    }
  }

  const resetPassword = () => {
    setIsLoading(true);
    setAuthDone(false)
    if (token) {

      const data = {
        newPassword: newPassword,
        confirmPassword: pass,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/resetPassword?token=${token}`,
          data
        )
        .then((response) => {
          setAuthDone(true)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 400) {
            history("/login")
            setAuthDone(false);
            setError(true);
            setErrorData(err.response.data.message);
          }
          if (err.response.status === 409) {
            setAuthDone(false);
            setError(true);
            setErrorData(err.response.data.message);
          }
        });
    }
  };

  const isUppercase = /[A-Z]/.test(newPassword);
  const isLowercase = /[a-z]/.test(newPassword);
  const hasNumber = /[0-9]/.test(newPassword);
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);
  const isMinLength = newPassword.length >= 8;

  const [box, setBox] = useState(false);

  
  const fulfilledRequirementsCount = [
    isUppercase,
    isLowercase,
    hasSpecialChar,
    hasNumber,
  ].filter(Boolean).length;

  const handlePasswordChange = (e) => {
    setNewpassword(e.target.value);

    if(e.target.value.length > 0) {
        if( e.target.value.length >= 8 && (hasNumber && hasSpecialChar && isLowercase && isUppercase ) ) {
          setBox(false);
        } else {
          setBox(true);
        }
        setValidatePass(false);
    } else {
      setValidatePass(true);
    }
  };

  return (
    <>
    <BgFormAnimate />
    <form className='form-design' onSubmit={handleSubmit}>
      <section className="login">
        {authDone === false ? (
          <div className="loginWindow">
            <img src={login} alt="Login" />

            <p className="loginTitle">Set new password</p>
            <p className="loginSubtitle">
              Your new password must be different to previously used passwords.
            </p>

            <div className="loginRow">
              <p>Password</p>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={newPassword}
                autoFocus={true}
                onChange={handlePasswordChange}
                onBlur={(e) => {
                  if (newPassword.length === 0) {
                    setValidatePass(true);
                  }
                }}
                className={validatePass === true ? "inputError" : " "}
              />
              {validatePass === true ? (
                <p className="validateError">Password doesn't meet criteria</p>
              ) : (
                ""
              )}
            </div>
            {box && (
              <div className="requirements-box">
                <p>Your password must contain:</p>
                <ul className="requirement-list">
                  <li className={isMinLength ? "fulfilled" : ""}>
                    <p>At least 8 characters</p>
                  </li>
                  <li
                    className={
                      fulfilledRequirementsCount >= 4 ? "fulfilled" : ""
                    }
                  >
                    <p>And the Following:</p>
                    <ul>
                      <li className={isLowercase ? "fulfilled" : ""}>
                        <p>Lower case letters (a - z)</p>
                      </li>
                      <li className={isUppercase ? "fulfilled" : ""}>
                        <p>Upper case letters (A - Z)</p>
                      </li>
                      <li className={hasNumber ? "fulfilled" : ""}>
                        <p>Numbers (0 - 9)</p>
                      </li>
                      <li className={hasSpecialChar ? "fulfilled" : ""}>
                        <p>Special characters (e.g. !@#$%^&*)</p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            )}


            <div className="loginRow">
              <p>Confirm Password</p>
              <input
                className={validateRePass === true ? "inputError" : ""}
                type="password"
                name="password"
                placeholder="Enter your password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                  if (e.target.value.length > 0) {
                    setValidateRePass(false);
                    setMatchPassword(true);
                  }
                }}
              />
              {validateRePass === true ? (
                <p className="validateError">The field is not filled</p>
              ) : (
                ""
              )}
              {!matchPassword ? (
                <p className="validateError">Passwords do not match</p>
              ) : (
                ""
              )}
              {errorData ? (
                <p className="validateError">{errorData}</p>
              ) : (
                ""
              )}
            </div>

            <button
              className={`blueButton loginButton ${isLoading ? 'btn_loading' : ''}`}
              type="submit"
            >
              {isLoading ?
                <div className="settingPage-loader">
                  <div className="ring-loader"></div>
                </div>
                : "Reset password"}

            </button>
            <p className="loginBottom resetBottom">
              <Link to={"/login"}>
                <img src={back} alt="Back" />
                Back to log in
              </Link>
            </p>
          </div>
        ) : (
          <PassReset />
        )}
      </section>
    </form>
    </>
  );
}

export default NewPass;