import '../.././App.css';
function RegStatus(props) {
    var activeStep = props.activeStep
    return (
        <div className='RegStatus'>
            <div className='RegStatus_content'>
                <div className='RegStatus_point' onClick={e => {
                        if ({ ...props.statusReg } && props.statusReg[1].status === 'done') {
                            props.setActiveStep(2)
                        }
                    }}>
                    <div className={`navigation-dots ${activeStep === 2 ? "activeStep" : 'defalut-navigation-dots'}`}></div>
                </div>

                <div className='RegStatus_point' onClick={e => {
                        if ({ ...props.statusReg } && props.statusReg[2].status === 'done') {
                            props.setActiveStep(3)
                        }
                    }}>
                    <div className={`navigation-dots ${activeStep === 3 ? "activeStep" : 'defalut-navigation-dots'}`}></div>
                </div>

                <div className='RegStatus_point' onClick={e => {
                        if ({ ...props.statusReg } && props.statusReg[3].status === 'done') {
                            props.setActiveStep(4)
                        }
                    }}>
                    <div className={`navigation-dots ${activeStep === 4 ? "activeStep" : 'defalut-navigation-dots'}`}></div>
                </div>

                <div className='RegStatus_point' onClick={e => {
                        if ({ ...props.statusReg } && props.statusReg[4].status === 'done') {
                            props.setActiveStep(6)
                        }
                    }}>
                    <div className={`navigation-dots ${activeStep === 6 ? "activeStep" : 'defalut-navigation-dots'}`}></div>
                </div>
            </div>
        </div>

    );
}

export default RegStatus;