import React, { useEffect } from 'react'
import './ImageEditor.css'
import AvatarEditor from 'react-avatar-editor'
import { useState, useRef  } from 'react'


export default function ImageEditor(props) {
    const { zoom } = props;
    const cropRef = useRef(null);
    const [Image, setImage] = useState(props.image);
    const position = props.position;
     
    useEffect(() => {
        setImage(props.oldImage === undefined ? props.image : props.oldImage)
    }, []);

    

    const handleSave = async () => {
        if (cropRef) {
            const dataUrl = cropRef.current.getImage().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            props.setFeatureImage(URL.createObjectURL(blob));
            props.setUploadedImage(null);
        }
    };

    if (props.zoom) {
        props?.setZoom(props.zoom)
    }
    
    const handlePositionChange = (newPosition) => {
        props?.setPosition(newPosition)
    };

    return (
        <>
            <div className="imageEditor imageUploaded">
                <AvatarEditor
                    ref={cropRef}
                    image={Image}
                    width={props.type === "featureImg" ? 384 : 100}
                    height={props.type === "featureImg" ? 215 : 100}
                    border={0}
                    borderRadius={props.type === "featureImg" ? 0 : 50}
                    color={[0, 0, 0, 0]}
                    scale={zoom}
                    rotate={0}
                    position={position}
                    crossOrigin="anonymous"
                    onPositionChange={handlePositionChange}
                />
            </div>
            <span id='clickFunction' onClick={handleSave}></span>
        </>

    )
}
