
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import '../.././App.css';

import close from '../../images/Settings/close.svg'
import NotificationMessage from '../NotificationMessage';

const stripePromise = loadStripe("pk_test_51PFt1t07v2TyS3y7ylP92wKRAwxymdw9dojOSJZ2nBMGtYUtFdV0FpuLHK6ZgBv9a9cJZQu2IgXRE7GfT7q9BLHq00Ou7adzyJ");

const PaymentDetailsForm = ({setIsAddPaymentOpen, updateData, setCardNotify}) => {

    const stripe = useStripe();
    const elements = useElements();
    

    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const cardElementStyles = {
        base: {
            color: '#000000',
            fontFamily: 'Inter, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '22px',
            height: '22px',
          '::placeholder': {
            color: '#667085',
          },
        },
    };

    const handleUpdateButton = async () => {
        try {
            setIsLoading(true);

            setErrorMessage('')

            if (!stripe || !elements) {
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);

            const userResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );

            const userData = userResponse?.data?.result?.user;

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    name: name,
                    email: userData.email,
                },
            });

            if (error) {
                setErrorMessage(error.message)
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updatePaymentMethod/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    {
                        paymentMethodId: paymentMethod.id,
                    }
                );

                await updateData();

                setIsAddPaymentOpen(false);
                setCardNotify(true)
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log({error, message: error.message});
        }        
    }

    return(
        <>
        <div className="popupRow popupRow-one">
            <div className='card-holder-name'>
                <p>Name on card</p>
                <input type="text" onChange={e => setName(e.target.value)} value={name} placeholder='Name on the card'/>
            </div>

            <div>
            <p>Expiry</p>
                <div className="exp-wrapper">
                    <CardExpiryElement options={{style: cardElementStyles}} />            
                </div>
            </div>
        </div>

        <div className="popupRow popupRow-two">
            <div className='paymentMethod'>
                <p>Card number</p>
                <CardNumberElement options={{ style: cardElementStyles, showIcon: true }} />
            </div>
            <div className='cvvBlock'>
                <p>CVV</p>
                <CardCvcElement />
            </div>
        </div>  

        {
            errorMessage && 
                <p className='error-message'>{errorMessage}</p>
        }

        <div className="popupButtons">
            <button className='settingsWhite billingSaveBtn' onClick={e => setIsAddPaymentOpen(false)}>Cancel</button>
            <button className='settingsBlue billingSaveBtn' onClick={e => handleUpdateButton()}> 
                {isLoading ? <div className="settingPage-loader"><div className="ring-loader"></div></div> : "Add New"}
            </button>
        </div>
        </>
    )
}

function AddPaymentMethod(props) {

    const popupRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (props.isAddPaymentOpen && popupRef.current && !popupRef.current.contains(e.target)) {
                props.setIsAddPaymentOpen(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [props.isAddPaymentOpen]);

    return (
        <div className="popupContainer">
            <div className='popupOverlay'></div>
            <div className="popupBlock" ref={popupRef}>
                <div className="popupTop popupTopClose">
                    <img src={close} onClick={e => props.setIsAddPaymentOpen(false)} alt="Icon" />
                </div>

                <div className="popupContent">
                    <p className="popupTitle">Change your payment method</p>
                    <p className="popupDesc">Update your credit card details.</p>
                </div>

                <Elements stripe={stripePromise} >
                    <PaymentDetailsForm setIsAddPaymentOpen={props.setIsAddPaymentOpen} updateData={props.updateData} setCardNotify={props.setCardNotify}/>
                </Elements>
            </div>
        </div>
    );
}

export default AddPaymentMethod;
