import '.././App.css';
import note_icon from '.././images/note_icon.svg'
import note from '.././images/note.svg'
import { Link } from 'react-router-dom';

function AddNewDropdown(props) {
    const token = localStorage.getItem("userinfo");

    const handleClick = (name) =>{
        props.setActivePage(name)
        props.setClose(false)
    }

    return (
        <div className='dropdown addDropdown'>
            <ul>
                <li>
                    <Link to={token ? '/dashboard/page' : '/login'} onClick={()=> handleClick("New page")}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Page</p>
                    </Link>
                </li>
                <li>
                    <Link to={token ? '/dashboard/publication' : '/login'} onClick={()=> handleClick("New Publication")}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Publication</p>
                    </Link>
                </li>
                <li>
                    <Link to={token ?'/dashboard/newsletter': '/login'} onClick={()=> handleClick("New Newsletter")}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Newsletter</p>
                    </Link>
                </li>

            </ul>
        </div>
    );
}

export default AddNewDropdown;
