import { addDays, endOfDay, startOfDay, startOfMonth, startOfYear, endOfMonth, endOfYear, addMonths, startOfWeek, endOfWeek, isSameDay, addYears } from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
  startOfLastTwentyeightDay: startOfDay(addDays(new Date(), -28)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges) { return ranges.map(range => ({ ...staticRangeHandler, ...range })) }

export const defaultStaticRanges = createStaticRanges([
  { label: 'Today', range: () => ({ startDate: defineds.startOfToday, endDate: defineds.endOfToday }) },
  { label: 'Yesterday', range: () => ({ startDate: defineds.startOfYesterday, endDate: defineds.endOfYesterday }) },
  { label: 'Last Week', range: () => ({ startDate: defineds.startOfLastWeek, endDate: defineds.endOfLastWeek }) },
  { label: 'Last Month', range: () => ({ startDate: defineds.startOfLastMonth, endDate: defineds.endOfLastMonth }) },
  { label: 'Last Year', range: () => ({ startDate: defineds.startOflastYear, endDate: defineds.endOflastYear }) },
  { label: 'Last 28 Days', range: () => ({ startDate: defineds.startOfLastTwentyeightDay, endDate: defineds.endOfToday }) },
]);
