
import React, { useEffect, useState, useRef } from 'react'
import ".././App.css";
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'
import Chart from 'chart.js/auto'

function MonetizationAnalyticsForRevenue() {

    const chartRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);


    const [analyticsActive, setAnalyticsActive] = useState("subscription_revenue");


    return (
        <div className="dashboardGraph analyticsCharts">
            <div>
                {!isLoading &&
                    <>
                        <div className="dashboardGraphCotnent">
                            <div className="analyticsButtons">
                                <div className={analyticsActive === "subscription_revenue" ? "graphButton greenPercent dashboardGraphActiveButton graphRevenueBtn " : "graphButton graphRevenueBtn"} onClick={() => setAnalyticsActive("subscription_revenue")} >
                                    <p className="analyticsButtonTitle">Subscription Revenue</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">4821</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            16%
                                        </span>
                                    </div>
                                </div>
                                <div className={analyticsActive === "ads_revenue" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("ads_revenue")} >
                                    <p className="analyticsButtonTitle">AD Revenue</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">$1.9</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            13%
                                        </span>
                                    </div>
                                </div>
                                <div className={analyticsActive === "total_revenue" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("total_revenue")} >
                                    <p className="analyticsButtonTitle">Total Revenue</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="analyticsNumbers">7937$</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            19%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboardChart">
                            <div className="visitor-grreapoh">
                                <canvas id="main-graph-canvas" ref={chartRef} width="400" height="400"></canvas>
                            </div>
                        </div>
                    </>
                }

                {isLoading &&
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                }
            </div>
        </div>

    );
}

export default MonetizationAnalyticsForRevenue;
