import React from "react";
const CountryFlag = React.lazy(() => import('./CountryFlag'));

const getCountryFlag = (countryCode) => {
    if( countryCode == '' ) {
        return '-';
    }
    return (
        <>
            <CountryFlag countryCode={countryCode} />
        </>
    );
};

export const dataURLtoFile = async (dataurl, filename) => {
    const response = await fetch(dataurl);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: 'image/jpeg' });

    return file;
};

export const formattedDate = (date) => {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    var newDate = new Date(date);
    var formatteddate = newDate.toLocaleDateString('en-US', options);
    return formatteddate;
}

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const dimensions = `${img.width}x${img.height}`;
        resolve(dimensions);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
};

export const formatNumber = (num) => {
    const absNum = Math.abs(num);

    if (absNum >= 1e9) {
        let formatted = (num / 1e9).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'B';
    }

    if (absNum >= 1e6) {
        let formatted = (num / 1e6).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'M';
    }

    if (absNum >= 1e3) {
        let formatted = (num / 1e3).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'k';
    }
    if (absNum <= 1e3) {
        let formatted = absNum.toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = absNum.toFixed(2);
        }

        return formatted;
    }

    return num?.toString();
};

export const getRemainingDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in time
    const timeDiff = end.getTime() - start.getTime();

    // Calculate the difference in days
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Return "Today" if the difference is 0 days
    if (dayDiff <= 0) {
        return false;
    }

    return dayDiff + (dayDiff === 1 ? " day left" : " days left");
}

export const getRemainingMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let yearsDiff = end.getFullYear() - start.getFullYear();
    let monthsDiff = end.getMonth() - start.getMonth();

    if (monthsDiff < 0) {
        yearsDiff--;
        monthsDiff += 12;
    }

    const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

    if( totalMonthsDiff > 0 ) { 
        return totalMonthsDiff > 1 ? `${totalMonthsDiff} months` : '1 month';
    }

    return false
}


export default getCountryFlag;
