import { Link } from 'react-router-dom';

import dateFormatter from '../../utils/dateFormatter';

import '../.././App.css';

import paid from '../../images/Settings/paid.svg'
import unpaid from '../../images/unpaid.svg'
import { useEffect, useState } from 'react';

function BillingItemList(props) {
    var item = props.data
    var name = props?.name
    const [checkboxActive, setCheckboxActive] = useState(false)

    useEffect(() => {
        if (props?.allSelected === true) {
            setCheckboxActive(true)
        } else {
            setCheckboxActive(false)
        }
    }, [props?.allSelected])
    return (
        <div className="billingTableTop billingTableItem">
            {(name !== "" && name !== undefined ) ? <div><input type="checkbox"  checked={props?.selectedItems.includes(item?.invoiceNumber)} onChange={() => {props?.handleCheckboxChange(item?.invoiceNumber)}}/>  <p className='billingTopFix'>{item.invoiceNumber}</p> </div>: <div> <p className='billingTopFix'>{item.invoiceNumber}</p> </div>}
            <div>{dateFormatter(item.date, 'date', '', false, true)}</div>
            {(name !== "" && name !== undefined ) ? <div> </div>: ''}
            <div><img className='statusImage' src={item.status === "paid" ? paid :unpaid} alt="Icon" /></div>
            <div>${item.amount} USD</div>
            {(name !== "" && name !== undefined ) ? " ":  <div>{item.planName}</div>}
            <div className='billingDownload'><Link to={item.downloadLink} target="_blank" rel="noopener noreferrer">Download</Link></div>
        </div>
        
    );
}

export default BillingItemList;
