import React, { useEffect, useState, useRef } from "react";

function HomePageContent(props) {

    const { homepageData, setEditorContent, validateEditorContent, setValidateEditorContent, showPublication, setShowPublication } = props;


    let summary = homepageData?.summary;
    let displayPublication = homepageData?.showPublication;

    

    // Check if summary is undefined or not available
    if (summary === undefined || summary === null) {
        summary = ''; // Set summary as blank
    }

    // Check if summary is undefined or not available
    if (displayPublication === undefined || displayPublication === null) {
        displayPublication = false; // Set summary as blank
    }
    
    const summaryRef = useRef(null);

    const [contentlength, setContentLength] = useState(0);
    const [homepageSummary, setHomepageSummary] = useState(summary);

    useEffect(() => {
        setHomepageSummary(summary)
    }, [summary]);

    useEffect(() => {
        setShowPublication(displayPublication)
    }, [displayPublication]);

    useEffect(() => {
        const handleResize = () => {
            if( summaryRef.current ) {
                summaryRef.current.style.height = '130px';
                var extraSpace = 2;

                if (summaryRef.current.scrollHeight > 130) {
                    extraSpace = 15;
                }
                summaryRef.current.style.height = summaryRef.current.scrollHeight + extraSpace + 'px';

                setContentLength(summaryRef.current.value.length);
            }
        };

        handleResize();
    
        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);
    
    
        // Cleanup: Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };

    }, [homepageSummary]);

    const handleTextareaDescInput = (e) => {
        setValidateEditorContent(false);

        e.target.style.height = '130px';
        var extraSpace = 2;

        if (e.target.scrollHeight > 130) {
            extraSpace = 15;
        }
        e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

        const truncatedText = e.target.value.substring(0, 350);

        setHomepageSummary(truncatedText);   
    }

    const handleTextareaDescBlur = (e) =>{
        const newtitleVal = e.target.value.trim();
        if( newtitleVal.length > 0 ){   
            setHomepageSummary( newtitleVal );
            setValidateEditorContent(false)
        }

        setEditorContent(newtitleVal);
    }

    const changeTextarea = (e) => {        
        if (e.target.value.length >= 350 && e.key !== 'Backspace') {
            e.preventDefault();
            return true;
        }
    }

    const handleCheckbox = () => {
        setShowPublication(!showPublication);
    };

    useEffect(() => {
        const homepageContentElement = document.querySelector('.homepage-content');
    
        if (homepageContentElement) {
          document.body.classList.add('home-publication');
        }

        return () => {
          document.body.classList.remove('home-publication');
        };
    }, []);

    return (
        <div className="newpageLeftRow textInfoBlock homepage-content">
            <h2>Home</h2>
            <p className="linkDataDesc">You can update your short summary about yourself and control over whether or not your publications are displayed on your homepage. This is the first piece of information visitors see when they enter your profile. It's a perfect spot to share a little about yourself and your professional background. This brief introduction can really make your profile stand out.</p>

            <div className="newpageCard homepageCard">
                <div className="newpageLeftRow linkDataBox newpagefile">
                    <div className="page-summary linkData">
                        <p className="label field-label" >Short Summary</p>
                        <textarea
                            id="pageSummary"
                            ref={summaryRef}
                            value={homepageSummary}
                            placeholder="Write a short summary about yourself..."
                            className="pageSummary linkDataDiscripation"
                            onChange={(e) => {
                                if (e.target.value.length >= 350) {
                                    setContentLength(350)
                                } else {
                                    setContentLength(e.target.value.length)
                                }
                                handleTextareaDescInput(e)
                            }}
                            onKeyDown={(e) => changeTextarea(e)}
                            onBlur={(e) => handleTextareaDescBlur(e)}
                        ></textarea>
                        <div className="page-summary-msg">
                            {validateEditorContent && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            <p className="charactersRight">{contentlength}/350 </p>
                        </div>
                    </div>
                </div>
                <div className="newpageLeftRow linkDataBox linkBox newpagefile">
                    <div className="page-summary linkData borderBox">
                        <p className="label" >Publications list</p>
                        <p className="lbl-description">We’re gathering developers from around the world for an in-person day of programming to learn about the latest AI advancements and explore what lies ahead.</p>
                        <div className="list-search">
                            <label>
                                <input type="checkbox" checked={showPublication} onChange={handleCheckbox} />
                                <p>Display publications</p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePageContent;