import { useRef, useState, useEffect } from 'react';
import '.././App.css';

import more from '.././images/more.svg'
import DeleteSubscriberModel from './Modal/DeleteSubscriberModel';

function SubsListItem(props) {
    let data = props.data

    const ref = useRef();
    const [activateEdit, setActivateEdit] = useState(false)
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (activateEdit && ref.current && !ref.current.contains(e.target)) {
                setActivateEdit(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [activateEdit]);

    const [checkboxActive, setCheckboxActive] = useState(false)

    useEffect(() => {
        if (props.allSelected === true) {
            setCheckboxActive(true)
        } else {
            setCheckboxActive(false)
        }
    }, [props.allSelected])

    var createdAt = data.createdAt;
    var createdAtDate = new Date(createdAt);
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    var formattedCreatedAt = createdAtDate.toLocaleDateString('en-US', options);

    const handleCheckboxChange = (itemId) => {
        props.handleCheckboxChange(itemId);
    };

    const handleDelete = () => {
        setIsOpenDeletePopup(true);
        document.body.classList.add('hidden-message')
        const dropdown = document.querySelector('.dropdown');
        dropdown.style.display = 'none';
    }

    const closeDeletePopup = () => {
        setIsOpenDeletePopup(false)
        document.body.classList.remove('hidden-message');
      }

    return (
        <>
            <div className="pagesTableItem subTable subscriberWidth">
                <div>
                    <input type="checkbox" checked={props.selectedItems.includes(data._id)} onChange={() => handleCheckboxChange(data._id)}  />    
                    <span className='emailLink'>{data.email}</span>
                </div>
                <div><p className={(data.emailVerification === true) ? 'verifyStatus verifyGreen' : 'unverifyStatus verifyStatus'}>{(data.emailVerification === true)? 'Verified': 'Unverified'}</p></div>
                <div><p>{data?.planName} {data?.trialData?.isTrial === true && `(Trial)`}</p></div>
                <div>{formattedCreatedAt}</div>
                <div className='pagesTableAction' onClick={e => setActivateEdit(!activateEdit)} ref={ref}>
                    <img src={more} alt="More" />
                    {activateEdit && 
                        <div className='dropdown addDropdown dropdown-field'>
                            <ul>
                                <li onClick={() => { handleDelete(); }}><a href="#">Delete</a></li>
                            </ul>
                        </div>
                    }
                </div>           
            </div>

            {isOpenDeletePopup && 
                <>
                    <div className="welcomeOverlay"></div>
                    <DeleteSubscriberModel isOpen={isOpenDeletePopup} onClose={closeDeletePopup} subsciberIds={[data._id]} fetchData={props.fetchData} setShowNotification={props?.setShowNotification}/>
                </>
            }
        </>
    );
}

export default SubsListItem;
