import { useRef, useState, useEffect } from 'react';


import NotificationMessage from './NotificationMessage';
import EditCategoryModel from "./Modal/EditCategoryModel";
import DeleteCategoryModel from "./Modal/DeleteCategoryModel";

import more from '.././images/more.svg'
import clear from '.././images/delete.svg'
import edit from '.././images/pen.svg'

import '.././App.css';

function CategoriesPageListItem(props) {

    let data = props.data;
    let isOpen = props.isOpen;
    let togglePopup = props.togglePopup;

    const ref = useRef();
    const [activateEdit, setActivateEdit] = useState(false)
    const [showCategoryField, setShowCategoryField] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (activateEdit && ref.current && !ref.current.contains(e.target) && (e.target.classList.contains('dropdown'))) {
                // setActivateEdit(false);
                props.togglePopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    const handleCheckboxChange = (itemId) => {
        props.handleCheckboxChange(itemId);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen && (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null)) {
                togglePopup();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, togglePopup]);

    const handleEdit = () => {
        togglePopup();
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    }

    const closeCategoryField = (action = 'cancel') => {
        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');

        if (action === 'update') {
            setShowNotification(true);
        }
    }

    const handleDelete = () => {
        togglePopup();
        setShowDeleteModel(true);
        document.body.classList.add('hidden-message');
    }

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    return (
        <>
            <div className="pagesTableItem" key={data._id}>
                <div><input type="checkbox" checked={props.selectedItems.includes(data._id)} onChange={() => handleCheckboxChange(data._id)} />{data.name}</div>
                <div className='page-count'>{data.page_count}</div>
                <div className='publication-count'>{data.publication_count}</div>
                <div className='newsletter-count'>{data.newslatter_count}</div>
                <div className='pagesTableAction publicationAction' onClick={togglePopup} ref={ref}>
                    <img src={more} alt="More" />
                </div>
                {
                    (isOpen === true) &&
                    <>
                        <div className='dropdown addDropdown dropdown-field'>
                            <ul>
                                <li key={`edit-${data._id}`}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleEdit() }}>
                                        <img src={edit} alt="Icon" />
                                        <p>Edit</p>
                                    </a>
                                </li>
                                <li key={`delete-${data._id}`}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleDelete() }}>
                                        <img src={clear} alt="Icon" />
                                        <p>Delete</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </>
                }
            </div >
            {(showCategoryField || showDeleteModel) && <div className="welcomeOverlay"></div>}
            {showCategoryField && <EditCategoryModel isOpen={showCategoryField} onClose={closeCategoryField} categoryId={data._id} categoryName={data.name} updateCategoriesData={props.updateCategoriesData} />}
            {showDeleteModel && <DeleteCategoryModel isOpen={showDeleteModel} onClose={closeDeleteModel} categoryIds={[data._id]} categoryName={data.name} updateCategoriesData={props.updateCategoriesData} categoriesData={props.categoriesData} setShowNotification={props.setShowNotification}/>}


            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`Category has been successfully edited!`} />
            )}
        </>
    );
}

export default CategoriesPageListItem;
