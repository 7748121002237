import { useEffect, useState } from "react";

import axios from "axios";

import dateFormatter from '../../utils/dateFormatter';

import "../.././App.css";

import PaymentMethod from "../../Components/Settings/PaymentMethod";
import BillingItemList from "../../Components/Settings/BillingItemList";
import AddPaymentMethod from "../../Components/Settings/AddPaymentMethod";
import ChangePlan from "../../Components/Settings/ChangePlan";

import down from "../../images/down_arrow.svg";
import top from "../../images/arrow_top.svg";
import { ChangePlanSuccessModel } from "../../Components/Modal/ChangePlanSuccessModel";
import { getRemainingDays, getRemainingMonths } from "../../utils/functions";
import NotificationMessage from "../../Components/NotificationMessage";

function Billing(props) {
  props.setActivePage("Billing");

  const [planDetails, setPlanDetails] = useState({});
  const [paymentMethods, setPaymentMethods] = useState(''); 
  const [isStipeConnected, setIsStipeConnected] = useState(false); 
  const [invoices, setInvoices] = useState('');
  const [billingPlanDesc, setBillingPlanDesc] = useState('')
  const [currentDate, setCurrentDate] = useState('')
  const [isTrailPlan, setIsTrailPlan] = useState(false)
  const [discountedSubscriptionEnd, setDiscountedSubscriptionEnd] = useState(false)
  
  const [numberSort, setNumberSort] = useState(false);
  const [dateSort, setDateSort] = useState(false);
  const [statusSort, setStatusSort] = useState(false);
  const [AmountSort, setAmountSort] = useState(false);
  const [PlanSort, setPlanSort] = useState(false);
  const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
  const [notification, setNotification] = useState(false);
  const [cardNotify, setCardNotify] = useState(false);

  function sortByNumber() {
    var result;

    if (numberSort === true) {
      result = [...invoices].sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? 1 : -1));
    } else {
      result = [...invoices].sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? -1 : 1));
    }
    setInvoices(result);
  }

  function sortByDate() {
    var result = [];

    if (dateSort === false) {
        result = [...invoices].sort(function (a, b) {
            return a.date.localeCompare(b.date);
        });
    } else {
        result = [...invoices].sort(function (a, b) {
            return b.date.localeCompare(a.date);
        });
    }
    setInvoices(result);
    
  }

  function sortByStatus(a, b) {
    if (statusSort === false) {
      setInvoices(
        invoices.sort((a, b) => (a.status > b.status ? 1 : -1))
      );
      setStatusSort(false);
    } else {
      setInvoices(
        invoices.sort((a, b) => (a.status > b.status ? -1 : 1))
      );
      setStatusSort(true);
    }
  }

  function sortByAmount() {
    var result;
    if (AmountSort === true) {
      result = [...invoices].sort(
        (a, b) =>
          Number(a.amount) - Number(b.amount)
      );
    } else {
      result = [...invoices].sort(
        (a, b) =>
          -1 * Number(a.amount) - Number(b.amount)
      );
    }
    setInvoices(result);
  }

  function sortByPlan() {

    if (PlanSort === false) {
      setInvoices(
        invoices.sort((a, b) => (a.planName > b.planName ? 1 : -1))
      );
      setPlanSort(false);
    } else {
      setInvoices(
        invoices.sort((a, b) => (a.planName > b.planName ? -1 : 1))
      );
      setPlanSort(true);
    }
  }

  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
  const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const billingDetailsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
      );

      const invocesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/getInvoices/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
      );

      const _planDetails = billingDetailsResponse.data.result.subscriptions;
      const _paymentMethods = billingDetailsResponse.data.result.paymentMethods;
      const _isStipeConnected = billingDetailsResponse.data.result.stripeConnected;
      const _currentDate = billingDetailsResponse.data.result.currentDate;
      const _discountedSubscriptionEnd = billingDetailsResponse.data.result.discountedSubscriptionEnd;
      let _billingPlanDesc = ''
      let _isTrailPlan = false;

      if( (! _paymentMethods.length || _planDetails.trialEnd) && (_planDetails !== 'free_plan')) {
        _isTrailPlan = true;
      }

      if(_planDetails?.scheduleDate && _planDetails?.schedulePlan && _planDetails.schedulePlan !== _planDetails.plan ) {
        _billingPlanDesc =  `Your plan will change to the <span style="font-weight: bold">${planDetails.schedulePlanName}</span> on ${dateFormatter(planDetails.scheduleDate, 'date', '', false, true)}`  
      } else if(_isTrailPlan) {
        if(_planDetails.trialEnd && planDetails.nextPaymentAt) {
          _billingPlanDesc = `Paid subscription starts on ${dateFormatter(_planDetails.trialEnd, 'date', '', false, true)}.`
        } else {
          _billingPlanDesc = `Upgrade with 25% discount for 3 months.`
        }        
      } else if(_planDetails.canceledAt) {
        _billingPlanDesc = `Canceled At: ${dateFormatter(_planDetails.canceledAt, 'date', '', false, true)}`
      } else if(_planDetails.nextPaymentAt) {
        _billingPlanDesc = `Next Billing: ${dateFormatter(_planDetails.nextPaymentAt, 'date', '', false, true)}`
      }
      
      setPlanDetails(_planDetails);
      setPaymentMethods(_paymentMethods);
      setIsStipeConnected(_isStipeConnected);
      setBillingPlanDesc(_billingPlanDesc);
      setIsTrailPlan(_isTrailPlan);
      setCurrentDate(_currentDate);
      setDiscountedSubscriptionEnd(_discountedSubscriptionEnd)

      setInvoices(invocesResponse.data.result);
    } catch (error) {
      console.log({error: error, messgae: error.messgae});
    }
  }

  console.log({planDetails})

  return (
    <div className="general billing-card">
      <div className="generalTop">
        <div className="generalTopLeft">
          <p className="generalTopLeftTitle">Billing</p>
          <p className="generalTopLeftDesc">
            Update your company photo and details here.
          </p>
        </div>
      </div>

      <div className="billingContent">
        <div className="billingRow">
          <div className="billingText">
            <p className="settingsMenuBlockTitle">Current plan</p>
            <p className="generalTopLeftDesc">
              This will be displayed on your profile.
            </p>
          </div>
          <div className="billingOptions">
            <div className="billingPlan" >
              <div className="billingPlanText">     
                <p className="billingPlanTitle"> 
                  {planDetails.planName} 
                  { isTrailPlan ? 
                    <span>Free trial {getRemainingDays( currentDate, planDetails.canceledAt) && `(${getRemainingDays( currentDate, planDetails.canceledAt)})` }</span>
                  : 
                    ( 
                      (discountedSubscriptionEnd && getRemainingMonths(currentDate, discountedSubscriptionEnd)) ?
                        planDetails.price > 0 && <span>${(planDetails.price * 0.75)} / {planDetails.interval} billed {planDetails.interval === 'month'? `for ${getRemainingMonths(currentDate, discountedSubscriptionEnd)}` : 'for one year'}</span> 
                      :
                        planDetails.price > 0 && <span>${planDetails.price} / {planDetails.interval} billed {planDetails.interval === 'month'? 'monthly' : 'yearly'}</span>
                    )                    
                  }
                </p>
                <p className="billingPlanDesc">
                  <div dangerouslySetInnerHTML={{ __html: billingPlanDesc }} />                 
                </p>
              </div>

              <div className={`biilingRowCheckbox `}>
                <button className={`billingCheckbox ${(planDetails.plan === 'free_plan' || isTrailPlan) ? "billingBtn upgradeBtn" : 'changeBtn'}`} onClick={(e) => { setIsChangePlanOpen(true)} } >
                { (planDetails.plan === 'free_plan' || isTrailPlan) ? 'Upgrade' : 'Change Plan' }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        paymentMethods && paymentMethods.length > 0 &&
          <div className="billingContent">
            <div className="billingRow">
              <div className="billingText">
                <p className="settingsMenuBlockTitle">Payment method</p>
                <p className="generalTopLeftDesc">
                  This will be displayed on your profile.
                </p>
              </div>
              <div className="paymentMethod billingOptions">
                  <PaymentMethod paymentMethods={paymentMethods} />              
                <div className="changeMethod-btn">
                <button className="changeMethod" onClick={(e) => { setIsAddPaymentOpen(true)} } >
                  Change Payment Method
                </button>
                </div>            
              </div>
            </div>
          </div>
      }

      {
        invoices && invoices.length > 0 && 
          <>
          <div className="generalTop generalTopTable">
              <div className="generalTopLeft">
                <p className="generalTopLeftTitle">Invoices</p>
                <p className="generalTopLeftDesc">
                  Pick an account plan that fits your workflow.
                </p>
              </div>
            </div>
            <div className="BillingTableWrapper">
              <div className="billingTable">
                <div className="billingTableTop">
                  <div className="billingTableNumber">
                    <p className="billingTopText billingTopFix" onClick={(e) => { sortByNumber(); setNumberSort(!numberSort); }} > Number </p>
                    <img src={numberSort === true ? top : down} alt="Icon" onClick={(e) => { sortByNumber(); setNumberSort(!numberSort); }}
                    />
                  </div>
                  <div className="billingTableDate" onClick={(e) => { sortByDate(); setDateSort(!dateSort); }} >
                    <p className="billingTopText">Date</p>
                    <img src={dateSort === true ? top : down} alt="Icon" />
                  </div>
                  <div className="billingTableStatus" onClick={(e) => { sortByStatus(); setStatusSort(!statusSort); }} >
                    <p className="billingTopText textmt">Status</p>
                    <img src={statusSort === true ? top : down} alt="Icon" />
                  </div>
                  <div className="billingTableAmount" onClick={(e) => { sortByAmount(); setAmountSort(!AmountSort); }}>
                    <p className="billingTopText">Amount</p>
                    <img src={AmountSort === true ? top : down} alt="Icon" />
                  </div>
                  <div className="billingTablePlan" onClick={(e) => { sortByPlan(); setPlanSort(!PlanSort); }}>
                    <p className="billingTopText">Plan</p>
                    <img src={PlanSort === true ? top : down} alt="Icon" />
                  </div>
                  <div className="billingTableBorder billingDownload">
                    <p className="billingTopText downloadtxt">Download</p>
                  </div>
                </div>
      
                <div className="billingTableItems">
                  {invoices.map((item, index) => (
                    <BillingItemList data={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </>
      }

      { isAddPaymentOpen === true && (
        <AddPaymentMethod setIsAddPaymentOpen={setIsAddPaymentOpen} isAddPaymentOpen={isAddPaymentOpen} paymentMethods={paymentMethods} updateData={fetchData} setCardNotify={setCardNotify} />
      )}

      { isChangePlanOpen &&
        <ChangePlan setIsChangePlanOpen={setIsChangePlanOpen} isChangePlanOpen={isChangePlanOpen} planDetails={planDetails} paymentMethods={paymentMethods} isStipeConnected={isStipeConnected} updateData={fetchData} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} isTrailPlan={isTrailPlan} currentDate={currentDate} discountedSubscriptionEnd={discountedSubscriptionEnd} setNotification={setNotification}/>
      }
      { openSuccessPlanPopup &&
        <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={planDetails?.plan} /> 
      }
      {notification &&
          <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your plan has been successfully updated!`} />
      }
      {cardNotify &&
          <NotificationMessage  openNotify={cardNotify} setOpenNotify={setCardNotify} type={'success'} title={`Your payment method has been successfully updated!`} />
      }

    </div>
  );
}

export default Billing;
