
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

import '../../App.css'
import PageModal from './PageModal'

import TheamLight from "../../../src/images/white-regular.svg"
import TheamDark from "../../../src/images/dark-regular-publication.svg"
import Copy from '../../../src/images/copy-register.png'
import Redirect from '../../../src/images/redirect-register.png'
import close from '../../../src/images/Settings/close.svg'
function DashBoardModal({dashboardModal, setdashboardModal, openPageModal, clickCopyUrl, getTheme, getCoverImage, tooltipShow, openPublicationModal }) {
    
    const popupRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (dashboardModal  && popupRef?.current && !popupRef?.current.contains(e.target)) {
                setdashboardModal(false)
                openPublicationModal()
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [dashboardModal]);
    
    return (
        <>
             
                    
                <div className='welcomeOverlayPopup'></div>
                <div className='welcomeModal personalwebsiteModal' ref={popupRef}>
                    <div className='popupBlock-wrapper'>
                    <div className="popupTopClose closePersonalwebsiteModal" onClick={() => {setdashboardModal(false);openPublicationModal()}}>
                        <img src={close}  alt="Icon" />
                    </div>
                        <p className='welcomeTitle'>Your personal website is live!</p>
                        <p className='welcomeText'>Your personal website is now visible to the world, and  you can start editing and updating your content to make it truly yours.</p>
                        <div className='step_content'>
                            <div className="themeLayout">                            
                                <div className="theme themeLayout-register">
                                    {
                                        getTheme?.theme === "light" ?

                                            <div className={`theamLight theamBox`}>
                                                <input type="hidden" value='light' />
                                                <img src={getCoverImage} alt='' className="coverimg coverimg-light" />
                                                <img src={TheamLight} alt="TheamLight" className="themetype themeImage" />
                                            </div> :

                                            <div className={`theamDark theamBox`}>
                                                <input type="hidden" value='dark' />
                                                <img src={getCoverImage} alt='' className="coverimg coverimg-dark" />
                                                <img src={TheamDark} alt="TheamDark" className="themetype themeImage" />
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className='step_content_wrapp'>
                                <div className='user-profile'>
                                    <div className="user_email">
                                        <h1 className='website-title'>Personal website URL</h1>
                                        <div className='profile-url-input-welcome'>
                                            
                                            <input  value={`${`https://jourmal-next-front.vercel.app/${getTheme?.profileSlug}`}`}  type="text" className='first_name websiteLink'/>
                                            <div className='profile-url-default-welcome'>
                                                <Link target='_blank' to={`${`https://jourmal-next-front.vercel.app/${getTheme?.profileSlug}`}`}>
                                                    <img
                                                        src={Redirect}
                                                        alt="copyLogo"
                                                    />
                                                </Link>
                                                <div>

                                                <div onClick={clickCopyUrl} className='copied-link'>
                                                
                                                <img
                                                    src={Copy}
                                                    alt="copyLogo"
                                                />
                                                {tooltipShow && <p className="tooltip-text-welcome">URL Copied!</p>}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='changeurl welcomeText'>
                                        Do you want to change the URL? Go to <Link to="/dashboard/settings">Settings.</Link>
                                        </p>
                                    </div>
                                </div>
                                {/* <div className='regContact'>
                                    <p>
                                        Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    
                        <div className="popupButtons">
                            <button className='settingsBlue billingSaveBtn verifyLoader' type="button" onClick={openPageModal}>
                                <span>Continue</span> <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg", marginLeft: "5px", marginTop: '1px' }}>
                                    <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            
        </>
    )
}

export default DashBoardModal