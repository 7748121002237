import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion'
import { originalPagesData, originalPublicationData, selectedPagesArrayData, selectedPublicationArrayData } from './originalPages';
import NavItemEditable from '../../Components/Settings/NavItemEditable';
import NavItemLocked from '../../Components/Settings/NavItemLocked';
import NotificationMessage from "../../Components/NotificationMessage";
import '../.././App.css';
import search from '../../images/search.svg'
import rightLongArrow from '../../images/right-long-arrow.svg'
import link from '../../images/Settings/link.svg'
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import axios from 'axios';
import { json } from 'react-router-dom';

function MenuManagement(props) {

    props.setActivePage('Navigation menu')
    const [pageSearchInput, setPageSearchInput] = useState('');
    const [publicationSearchInput, setPublicationSearchInput] = useState('');
    const [showBlock, setshowBlock] = useState('page')


    const [originalPages, setOriginalPages] = useState([]);
    const [pagesList, setPagesList] = useState([])
    const [selectedPagesArray, setSelectedPagesArray] = useState([])

    // const originalPublication = originalPublicationData
    const [originalPublication, setoriginalPublication] = useState([]);
    const [publicationList, setPublicationList] = useState([])
    const [selectedPublicationArray, setSelectedPublicationArray] = useState([])

    const [searchActive, setSearchActive] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    const homagepage_id = btoa(`homepage-${user_id}`);
    const contactpage_id = btoa(`contactpage-${user_id}`);

    const fixedPages = [
        {
            "id": homagepage_id,
            "title": "Home",
            "status": "Published",
            "type": "page",
            "locked": true
        },
        {
            "id": contactpage_id,
            "title": "Contact",
            "status": "Published",
            "type": "page",
            "locked": true
        }
    ];


    function searchPages(e) {
        setPageSearchInput(e.target.value)
        setSearchActive(true)
        var result = [];
        var input = e.target.value.toLowerCase()


        originalPages.forEach(element => {
            var title = element.title.toLowerCase();

            if (title) {
                if (title.startsWith(input) === true) {
                    result.push(element)
                }
            }
        });

        if (result.length === originalPages.length) {
            var pages = originalPages.slice(0, 5)
            setPagesList(pages)

        } else if (result != []) {
            setPagesList(result)
            setSearchActive(false)
        } else {
            setPagesList([])
        }
    }


    function searchPublication(e) {
        if (e.target.value == "") {
            setPublicationSearchInput(e.target.value);
            var pages = originalPublication.slice(0, 4);
            setPublicationList(pages);
        }
        else {

            setPublicationSearchInput(e.target.value);
            setSearchActive(true);
            var resultPublication = [];
            var input = e.target.value.toLowerCase();

            originalPublication.forEach((element) => {
                var title = element.title ? element.title.toLowerCase() : ''; // Add a check for undefined
                if (title && title.startsWith(input)) {
                    resultPublication.push(element);
                }
            });

            if (resultPublication.length === originalPublication.length) {
                var pages = originalPublication.slice(0, 4);
                setPublicationList(pages);
            } else if (resultPublication.length > 0) {
                setPublicationList(resultPublication);
                setSearchActive(false);
            } else {
                setPublicationList([]);
            }
        }
    }

    const [NestableNavList, setNestableNavList] = useState([])

    const [customLinkTitle, setCustomLinkTitle] = useState('')
    const [customLinkURL, setCustomLinkURL] = useState('')
    const [customLinks, setCustomLinks] = useState([]);

    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }
    

    const handleNestableChange = (newList) => {
        setNestableNavList(newList.items)
    }
    const updateTitle = (itemId, newTitle) => {
    
        const updatedList = NestableNavList.map((item) => {
            if (item.id === itemId) {
                return {
                    ...item,
                    title: newTitle,
                    // url: newUrl
                };
            } else if (item.children && item.children.length > 0) {
                // Check if the item is present in children and update the title
                const updatedChildren = updateTitleInChildren(item.children, itemId, newTitle);
                return {
                    ...item,
                    children: updatedChildren,
                };
            }
            return item;
        });
    
        setNestableNavList(updatedList);
    };
    
    const updateTitleInChildren = (children, itemId, newTitle) => {
        return children.map((child) => {
            if (child.id === itemId) {
                return {
                    ...child,
                    title: newTitle,
                    // url: newUrl
                };
            } else if (child.children && child.children.length > 0) {
                // Recursively check in children of children
                return {
                    ...child,
                    children: updateTitleInChildren(child.children, itemId, newTitle),
                };
            }
            return child;
        });
    };
    
    const updateUrl = (itemId, newUrl) => {
        const updatedList = NestableNavList.map((item) => {
            if (item.id === itemId) {
                return {
                    ...item,
                    updateUrl: newUrl
                };
            }
            return item;
        });
        setNestableNavList(updatedList);
    };

    const addNestableItem = (item) => {
        item.children = [];
        setNestableNavList([...NestableNavList, item]);
    }

    

    const removeNestableItem = (itemToRemove) => {
        let updatedNestableNavList = [...NestableNavList];
    
        const removeItemFromList = (item, list) => {
            const index = list.findIndex((listItem) => listItem.id === item.id);
            if (index !== -1) {
                list.splice(index, 1);
                return true;
            }
            return false;
        };
    
        const removeItemFromChildren = (item, list) => {
            for (let i = 0; i < list.length; i++) {
                if (list[i].children && list[i].children.length > 0) {
                    // Recursively check in children
                    if (removeItemFromChildren(item, list[i].children)) {
                        // If an item is removed from children, check if the current item has no children left
                        if (list[i].children.length === 0) {
                            delete list[i].children;
                        }
                        return true;
                    }
                }
                if (list[i].id === item.id) {
                    list.splice(i, 1);
                    return true;
                }
            }
            return false;
        };
    
        // Remove the item from the main navigation list
        removeItemFromList(itemToRemove, updatedNestableNavList);
    
        // Remove the item from children arrays recursively
        removeItemFromChildren(itemToRemove, updatedNestableNavList);
    
        // Remove the item from selectedPagesArray if it's present there
        let updatedSelectedPagesArray = selectedPagesArray.filter((page) => page.id !== itemToRemove.id);
    
        // Update the state with the updated lists
        setNestableNavList(updatedNestableNavList);
        setSelectedPagesArray(updatedSelectedPagesArray);
    };
    

    function removeItem(itemToRemove, parent) {
        let index = parent.findIndex((item) => item.id === itemToRemove.id);
        if (index > -1) {
            if (parent[index]?.children?.length > 0) {
                parent[index].children.map((item) => {

                    let Data = selectedPagesArray.filter((obj) => obj.id !== item.id)
                    setSelectedPagesArray(Data)

                    if (item.children.length > 0) {
                        item.children.map((sub_item) => {
                            let Data = selectedPagesArray.filter((obj) => obj.id !== sub_item.id)
                            setSelectedPagesArray(Data)
                        });
                    }
                });
            }
            parent.splice(index, 1);
            setNestableNavList(parent);
            return true;
        }
    }
    const handlepages = (item) => {
        if (item.locked === true) {
            return false;
        }
        let value = selectedPagesArray.some((data) => data.id === item.id)
        if (value === false) {
            setSelectedPagesArray([...selectedPagesArray, item])
            addNestableItem(item)
        } else {
            let Data = selectedPagesArray.filter((obj) => obj.id !== item.id)
            setSelectedPagesArray(Data)
            removeNestableItem(item)
        }
    }

    const handlePublications = (item) => {
        if (item.locked === true) {
            return false;
        }
        let value = selectedPublicationArray.some((data) => data.id === item.id)
        if (value === false) {
            setSelectedPublicationArray([...selectedPublicationArray, item])
            addNestableItem(item)
        } else {
            let Data = selectedPublicationArray.filter((obj) => obj.id !== item.id)
            setSelectedPublicationArray(Data)
            removeNestableItem(item)
        }
    }

    useEffect(() => {
        // setSelectedPagesArray(selectedPagesArray)
        if (NestableNavList.length > 0) {
            //do nothing            
        } else setSelectedPagesArray(fixedPages)
    }, [selectedPagesArray]);


    const checkboxesRef = useRef({});

    useEffect(() => {
        fetchPublication();
        fetchPages();
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const userMeta = response?.data?.result?.userMeta;
            const userNavigationData = userMeta.navigation
            const selectedPagenNav = userMeta.selectedPagenNav
            const selectedPublicationNav = userMeta.selectedPublicationNav
            setSubscriberCount(response?.data?.subscriberCount)

            setNestableNavList(userNavigationData);
            setSelectedPagesArray(selectedPagenNav);
            setSelectedPublicationArray(selectedPublicationNav);
            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }
            setCancelLoading(false);
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    }

    const fetchPages = async () => {
        try {

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/publishedpageslist/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )

            const result = response?.data?.result;

            const transformedResult = result.map(({ _id, url_address, title, status }) => ({ id: _id, url_address, title, status, type: "page", locked: false }));

            const updatedResult = [...fixedPages, ...transformedResult];

            setOriginalPages(updatedResult)

            setPagesList(updatedResult.slice(0, 5))

            setSelectedPagesArray(fixedPages)

        } catch (error) {
            console.log(error, "error");
        }
    }

    const fetchPublication = async () => {

        try {

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/publishedpublicationlist/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,

            )

            const result = response?.data?.result;

            const transformedResult = result.map(({ _id, url_address, title, status }) => ({ id: _id, url_address, title, status, type: "publication", locked: false }));

            const updatedResult = [...transformedResult];

            setoriginalPublication(updatedResult)

            setPublicationList(updatedResult.slice(0, 4))

        } catch (error) {
            console.log(error, "error");
        }
    }

    const generateUniqueId = () => {
        // Generate a unique ID based on the current timestamp
        return Date.now().toString();
    };

    const handleAddCustomLink = () => {
        const uniqueId = generateUniqueId();
        const customLinkData = {
            id: uniqueId,
            title: customLinkTitle,
            url_address: customLinkURL,
            locked: false,
            type: 'customLink',
        };
        setNestableNavList([...NestableNavList, customLinkData]);

        setCustomLinkTitle('');
        setCustomLinkURL('');
    };

    const addData = async () => {
        try {
            setIsLoading(true);
            const navigationData = {
                navigation: JSON.stringify(NestableNavList),
                selectedPagenNav: JSON.stringify(selectedPagesArray),
                selectedPublicationNav: JSON.stringify(selectedPublicationArray),
                customLinkData: JSON.stringify(customLinks)
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/usernavigation/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                navigationData
            )
            setIsLoading(false);
            setNotification(true);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "Data not add");
        }
    }

    return (
        <div className="general">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className="generalTopLeftTitle">Navigation menu</p>
                    <p className="generalTopLeftDesc">
                        Update your company photo and details here.
                    </p>
                </div>
            </div>

            <div className="settingsMenuList">
                <div className="settingsListWrap">
                    <div className="navLeftList">
                        <p className="settingsMenuBlockTitle titleBottom">
                            Select to add the navigation menu
                        </p>

                        <div className="navigationLeft">
                            <div className="navigationBlocks">
                                <p
                                    className={`settingsMenuBlockTitle pagesTitle ${showBlock === "page" ? "active" : ""
                                        }`}
                                    onClick={(e) =>
                                        showBlock != "page"
                                            ? setshowBlock("page")
                                            : setshowBlock("")
                                    }
                                >
                                    Pages
                                </p>
                                <motion.div
                                    className=""
                                    initial={{ opacity: 1, height: 0 }}
                                    animate={{
                                        opacity: showBlock === "page" ? 1 : 0,
                                        height: showBlock === "page" ? "auto" : 0,
                                        paddingBottom: showBlock === "page" ? "15px" : 0,
                                    }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <>
                                        <div className="navigationTop">
                                            <div className="pageSearchInput">
                                                <input
                                                    type="text"
                                                    name="pages_search"
                                                    id="pages_search"
                                                    placeholder={"Search for page..."}
                                                    value={pageSearchInput}
                                                    onChange={(e) => searchPages(e)}
                                                />
                                                <img src={search} alt="Search" />
                                            </div>
                                        </div>

                                        <div className="settingsMenuPages">
                                            {pagesList && pagesList.length > 0 ? (
                                                pagesList?.map((item, index) => {
                                                    const isSelected = selectedPagesArray.some(
                                                        (data) => data.id === item.id
                                                    );
                                                    return (
                                                        <div key={index} className="settingsMenuItem">
                                                            <input
                                                                type="checkbox"
                                                                ref={(el) =>
                                                                    (checkboxesRef.current[item.id] = el)
                                                                }
                                                                id={`menu-item-${item.id}`}
                                                                value={item.id}
                                                                onClick={() => handlepages(item)}
                                                                checked={isSelected}
                                                                {...(item.locked ? { readOnly: 'readOnly' } : {})}
                                                            />
                                                            <label for={`menu-item-${item.id}`}>
                                                                {item.title}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <p className="nothingFound">Nothing found!</p>
                                            )}

                                            {searchActive === true && pagesList && originalPages && ( pagesList.length != originalPages.length ) ? (
                                                pagesList.length < originalPages.length ? (
                                                    <p
                                                        className="showMorePages"
                                                        onClick={(e) => {
                                                            setPagesList(originalPages);
                                                        }}
                                                    >
                                                        Show {originalPages.length - pagesList.length}{" "}
                                                        more
                                                    </p>
                                                ) : (
                                                    <p
                                                        className="showMorePages"
                                                        onClick={(e) => {
                                                            setPagesList(originalPages.slice(0, 5));
                                                        }}
                                                    >
                                                        Hide
                                                    </p>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </>
                                </motion.div>
                            </div>

                            <div className="navigationBlocks">
                                <p
                                    className={`settingsMenuBlockTitle pagesTitle ${showBlock === "publications" ? "active" : ""
                                        }`}
                                    onClick={(e) =>
                                        showBlock != "publications"
                                            ? setshowBlock("publications")
                                            : setshowBlock("")
                                    }
                                >
                                    Publications
                                </p>
                                <motion.div
                                    className=""
                                    initial={{ opacity: 1, height: 0 }}
                                    animate={{
                                        opacity: showBlock === "publications" ? 1 : 0,
                                        height: showBlock === "publications" ? "auto" : 0,
                                        paddingBottom: showBlock === "publications" ? "15px" : 0,
                                    }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <>
                                        <div className="navigationTop">
                                            <div className="pageSearchInput">
                                                <input
                                                    type="text"
                                                    name="pages_search"
                                                    id="pages_search"
                                                    placeholder={"Search for publication..."}
                                                    value={publicationSearchInput}
                                                    onChange={(e) => searchPublication(e)}
                                                />
                                                <img src={search} alt="Search" />
                                            </div>
                                        </div>

                                        <div className="settingsMenuPages">
                                            {publicationList.length > 0 ? (
                                                publicationList.map((item, index) => {
                                                    const isSelected = selectedPublicationArray.some(
                                                        (data) => data.id === item.id
                                                    );
                                                    return (
                                                        <div key={index} className="settingsMenuItem">
                                                            <input
                                                                type="checkbox"
                                                                ref={(el) =>
                                                                    (checkboxesRef.current[item.id] = el)
                                                                }
                                                                id={`menu-item-${item.id}`}
                                                                value={item.id}
                                                                onClick={() => handlePublications(item)}
                                                                checked={isSelected}
                                                                {...(item.locked ? { disabled: true } : {})}
                                                            />
                                                            <label for={`menu-item-${item.id}`}>
                                                                {item.title}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <p className="nothingFound">Nothing found!</p>
                                            )}

                                            {searchActive === true && ( publicationList.length != originalPublication.length ) ? (
                                                publicationList.length < originalPublication.length ? (
                                                    <p
                                                        className="showMorePages"
                                                        onClick={(e) => {
                                                            setPublicationList(originalPublication);
                                                        }}
                                                    >
                                                        Show{" "}
                                                        {originalPublication.length -
                                                            publicationList.length}{" "}
                                                        more
                                                    </p>
                                                ) : (
                                                    <p
                                                        className="showMorePages"
                                                        onClick={(e) => {
                                                            setPublicationList(
                                                                originalPublication.slice(0, 4)
                                                            );
                                                        }}
                                                    >
                                                        Hide
                                                    </p>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </>
                                </motion.div>
                            </div>

                            <div className="navigationBlocks linkAdd">
                                <p
                                    className={`settingsMenuBlockTitle pagesTitle ${showBlock === "custom" ? "active" : ""
                                        }`}
                                    onClick={(e) =>
                                        showBlock != "custom"
                                            ? setshowBlock("custom")
                                            : setshowBlock("")
                                    }
                                >
                                    Custom link
                                </p>

                                <motion.div
                                    className=""
                                    initial={{ opacity: 1, height: 0 }}
                                    animate={{
                                        opacity: showBlock === "custom" ? 1 : 0,
                                        height: showBlock === "custom" ? "auto" : 0,
                                        paddingBottom: showBlock === "custom" ? "15px" : 0,
                                    }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <>
                                        <div className="customLinkRow">
                                            <p>Title</p>
                                            <input
                                                type="text"
                                                placeholder="Type the title"
                                                value={customLinkTitle}
                                                onChange={(e) => setCustomLinkTitle(e.target.value)}
                                            />
                                        </div>

                                        <div className="customLinkRow">
                                            <p>Paste URL to link</p>
                                            <input
                                                className="linkInput"
                                                type="text"
                                                placeholder="Paste URL to link"
                                                value={customLinkURL}
                                                onChange={(e) => setCustomLinkURL(e.target.value)}
                                            />
                                            <img className="inputLink" src={link} alt="Link" />
                                        </div>

                                        <button
                                            onClick={(e) => {
                                                handleAddCustomLink(e);
                                            }}
                                            className="addToMenu"
                                        >
                                            <p>Add to menu</p>
                                            <img src={rightLongArrow} alt="Plus" />
                                        </button>
                                    </>
                                </motion.div>
                            </div>
                        </div>
                    </div>

                    <div className="navigationRight">
                        <p className="settingsMenuBlockTitle">My navigation menu</p>
                        <p className="settingsMenuBlockSubtitle">
                            Update your company photo and details here.
                        </p>

                        <div className="navRightList">
                            <NavItemLocked key={homagepage_id} name="Home" />
                            <Nestable
                                items={NestableNavList}
                                onChange={handleNestableChange}
                                renderItem={({ item }) => <NavItemEditable key={item.id} name={item.title} data={item} updateTitle={updateTitle} updateUrl={updateUrl} removeNavItem={removeNestableItem} />}
                                maxDepth={3}
                            />
                            <NavItemLocked key={contactpage_id} name="Contact" />
                        </div>
                    </div>
                </div>

                <div className="saveButtons">
                    <button className={`settingsWhite ${cancelLoading ? 'btn_loading' : ''}`} onClick={(e) => clearAll()}>
                        {cancelLoading ?
                            <div className="settingPage-cancelLoader ">
                                <div className="ring-loader cancel"></div>
                            </div>
                            : "Cancel"}
                    </button>
                    <button className={`settingsBlue ${isLoading ? 'btn_loading' : ''}`} onClick={addData}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Save"}
                    </button>
                </div>
            </div>
            {notification &&
                <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your navigation menu has been successfully updated!`} />
            }
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}
        </div>
    );
}

export default MenuManagement;
