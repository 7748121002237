import { formatNumber, formattedDate } from '../utils/functions';

import '.././App.css';

import currencies from '../utils/currency.json'
import { TotalEarningChart } from './TotalEarningChart';

function MonetizationAnalyticsBoxes({stripeBalance}) {

    return (
        <div className="dashboardBoxes dashboard-wrap monetizationBox">
            <div className='dashboardUserbox dashboardUserboxFlex'>
                <span>
                    <p className='dashUserStatus'>Total Earnings</p>
                    <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.totalEarnings > 0 ? stripeBalance.totalEarnings/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                </span>
                <TotalEarningChart />
            </div>

            <div className='dashboardUserbox'>
                <p className='dashUserStatus'>Total Balance</p> 
                <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.totalBalance > 0 ? stripeBalance.totalBalance/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
            </div>

            <div className='dashboardUserbox'>
                <p className='dashUserStatus'>Future Payouts</p>
                <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.futureBalance > 0 ? stripeBalance.futureBalance/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                {(stripeBalance.futureBalance > 0 && stripeBalance.expectedDate) && 
                    <span className='graphPercent payoutDate'>
                        {formattedDate(stripeBalance.expectedDate)}
                    </span>
                }
            </div>
        </div>
    );
}

export default MonetizationAnalyticsBoxes;
