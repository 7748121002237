import React, { useState, useEffect } from 'react';
import Datamap from 'datamaps'
import * as d3 from "d3";


const Map = ({topCountries, totalVisitors, handleElementClick, setCurrentLocations}) => {
    const [map, setMap] = useState(null);

    const paletteScale = d3.scaleLinear()
      .domain([0, totalVisitors])
      .range(["#f3ebff", "#a779e9" ])

    useEffect(() => {
        const drawMap = () => {
            const dataset = {};

            if( topCountries && topCountries.length > 0 ) {
                topCountries.map(country => {
                    dataset[country.code] = {
                        numberOfThings: country.visitors,
                        fillColor: paletteScale(country.visitors),
                        isoCode: country.isoCode,
                        title: country.title
                    };
                });
            }

            const defaultFill = '#f8fafc';
            const borderColor = '#dae1e7';
            const highlightFill = '#F5F5F5';
            const highlightBorderColor = '#a779e9';

            if (map) {
                document.getElementById('map-container').innerHTML = '';
            }

            const mapInstance = new Datamap({
                element: document.getElementById('map-container'),
                responsive: true,
                projection: 'mercator',
                fills: { defaultFill },
                data: dataset,
                geographyConfig: {
                    borderColor,
                    highlightBorderWidth: 2,
                    highlightFillColor: (geo) => geo.fillColor || highlightFill,
                    highlightBorderColor,
                    popupTemplate: (geo, data) => {
                        if (!data) return null;
                        return `<div class="hoverinfo"><div class="hoverinfo-title">${geo.properties.name}</div><div class="hoverinfo-subtitle" ><span class="hoverinfo-visitors">${data.numberOfThings}</span> ${data.numberOfThings > 1 ? 'Visitors' : 'Visitor' }</div></div>`;
                    },
                },                
                done: (datamap) => {
                    datamap.svg.selectAll('.datamaps-subunit').on('click', (geography) => {
                      const countryCode = geography.id
            
                      if (dataset[countryCode]) {                        
                        handleElementClick(dataset[countryCode].isoCode, 'country', dataset[countryCode].title);
                        setCurrentLocations('countries');
                      }            
                    })
                }
            });

            setMap(mapInstance);
        };

        drawMap();

        return () => {
            map && setMap(null);
        };
    }, [topCountries, totalVisitors]);

    return (
        <div className="map-container" style={{ width: 'calc(100% - 30px)', maxWidth: '600px', height: '400px' }} id="map-container"></div>
    );
};

export default Map;
