import { useRef, useState, useEffect } from 'react';
import '.././App.css';
import link from '.././images/link.svg'
import more from '.././images/more.svg'
import { Link } from 'react-router-dom';
import PageActionDropdown from './PageActionDropdown';

function PublicationListItem(props) {
    let data = props.data;
    let type = props.type;
    let isOpen = props.isOpen;
    let togglePopup = props.togglePopup;

    const ref = useRef();
    const [activateEdit, setActivateEdit] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (activateEdit && ref.current && !ref.current.contains(e.target) && (e.target.classList.contains('dropdown'))) {
                // setActivateEdit(false);
                props.togglePopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    const [checkboxActive, setCheckboxActive] = useState(false)

    useEffect(() => {
        if (props.allSelected === true) {
            setCheckboxActive(true)
        } else {
            setCheckboxActive(false)
        }
    }, [props.allSelected])


    var category = data.categories.slice(0, 2)
    var categoryFull = data.categories.slice(2)

    var createdAt = data.createdAt;
    var updatedAt = data.updatedAt;

    var createdAtDate = new Date(createdAt);
    var updatedAtDate = new Date(updatedAt);

    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    var formattedCreatedAt = createdAtDate.toLocaleDateString('en-US', options);
    var formattedUpdatedAt = updatedAtDate.toLocaleDateString('en-US', options);

    const handleCheckboxChange = (itemId) => {
        props.handleCheckboxChange(itemId);
    };
    
    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const changeStatus = (newStatus) => {
        data.status = newStatus;
        props.updateStatus(data._id, newStatus);
      };

    useEffect(() => {
        function handleClickOutside(event) {            
            if (isOpen && (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, togglePopup]); 
    
    const profileSlug = localStorage.getItem('userProfileSlug')
    
    const pageUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/${data.url_address}`;

    return (
        <div className="pagesTableItem publicationTableItem pagesTableList">                                                                                       
            <div><input type="checkbox" checked={props.selectedItems.includes(data._id)} onChange={() => handleCheckboxChange(data._id)} /> {data.status === "Deleted" ? data?.title : <Link className='editTitle' to={`/dashboard/publication/${props.data._id}`}>{data.title}</Link>}</div>
            <div className='statusTab'><span className={(data.status === 'Published') ? 'greenStatus' : (data.status === 'Draft') ? 'draftStatus' : (data.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {data.status}</span></div>
            <div className='publicationsCategory'>
                <div>
                    {
                        category?.map((category, index) => (
                            <span className={(category === 'Sport') ? 'categoryGeneral' : (category === 'Games' ? 'categoryNature' : '')}>{category}</span>
                        ))}

                    {
                        (data.categories.length > 2)
                            ? <div className='categoryList'>
                                <span>+{data.categories.length - 2}</span>
                                <div className="categoryTooltip">

                                    {
                                        categoryFull.map((item) => (
                                            <p> {item}</p>
                                        ))
                                    }

                                </div>
                            </div>
                            : ''
                    }
                </div>
            </div>
            <div>{formattedCreatedAt}</div>
            <div>{formattedUpdatedAt}</div>
            <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'><img src={link} alt="Link" /> Link</button></Link></div>
            <div className='pagesTableAction publicationAction' onClick={togglePopup} ref={ref}>
                <img src={more} alt="More" />
            </div>
            {
                (isOpen === true) && <PageActionDropdown togglePopup={togglePopup}  changeStatus={changeStatus} type={type} data={data} updateActivateEdit={updateActivateEdit} updateOriginalData={props.updateOriginalData} index={props.data._id} setShowNotification={props.setShowNotification} />
            }
        </div >
    );
}

export default PublicationListItem;
