import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import '.././App.css';

import phoneInput from "../images/icon-phone.svg";
import mailInput from "../images/icon-mail-input.svg";
import link from "../images/Settings/link.svg";
import Sms from '../images/sms.png'
import gmail from '../images/gmail-icon.png'
import whatsapp from '../images/whatsapp-icon.png'
import messager from '../images/messanger.png'
import closed from '../images/closed.svg'

function ProfileAssistant(props) {
    // Set active page
    props.setActivePage('ProfileAssistant');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [publicEmail, setPublicEmail] = useState('');
    const [shareUrl, setShareUrl] = useState("");
    const [isValidURL, setIsValidURL] = useState('');
    const [validateShareUrl, setValidateShareUrl] = useState(false);
    const [linkData, setlinkData] = useState(false);
    const [isLinkDataLoad, setLinkDataLoad] = useState(false);
    const [errors, setErrors] = useState({
        email: "",
        phone: "",
        shareUrl: ""
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user/getProfileAssistant/${JSON.parse(localStorage.getItem("userinfo"))?._id}`)
        .then((res) => {
          if (res) {  
            let profileAssistantData = res?.data?.result;
            
            setPhoneNumber(profileAssistantData.phone);
            setPublicEmail(profileAssistantData.email);
            setShareUrl(profileAssistantData.facebookMessenger);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const handleShareInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setShareUrl(trimmedValue);
        setErrors({ ...errors, shareUrl: "" }); // Clear error message
        setIsValidURL('');

        if (trimmedValue.length > 0) {
            setValidateShareUrl(false);
            setIsValidURL(false);
        }

        setlinkData(false);
        setLinkDataLoad(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateInputs()) {
            try {
                const updatedProfileAssistantData = {
                    phone: phoneNumber,
                    email: publicEmail,
                    facebookMessenger: shareUrl
                }

                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateProfileAssistant/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    updatedProfileAssistantData
                    ).then((res) => {
                      setIsLoading(false)
                    })
                    .catch((error) => {
                      setIsLoading(false)
                });
                
                // Reset form values after successful submission                
                setErrors({
                    email: "",
                    phone: "",
                    shareUrl: ""
                });
            } catch (error) {
                console.error("Form submission failed:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};
    
        if (publicEmail.trim() !== "") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(publicEmail)) {
                newErrors.email = "Invalid email format";
                valid = false;
            }
        }
    
        if (phoneNumber.trim() !== "") {
            const phoneRegex = /^[0-9]+$/;
            if (!phoneRegex.test(phoneNumber)) {
                newErrors.phone = "Phone Number must be numeric";
                valid = false;
            }
        }
    
        if (shareUrl.trim() !== "") {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            if (!urlRegex.test(shareUrl)) {
                newErrors.shareUrl = "The URL is not valid";
                valid = false;
            }
        } 
        setErrors(newErrors);
        return valid;
    };
    

    return (
        <section className='settings profileAssistant'>
            <div className="container">
                <h1 className="pageTitle">Profile Assistant</h1>
                <span className="borderLine"></span>
                <div className="newpageLeftRow textInfoBlock">
                    <h2 className="generalLabel">General</h2>
                    <p>Upload and display PDF and Office documents for your visitors to view, from detailed reports and data-rich spreadsheets to compelling presentations. You can share important content without the need for external downloads or additional software. </p>
                    <p>It's designed to provide a smooth, in-browser viewing experience that keeps your audience engaged and ensures your files are presented </p>
                </div>
                <div className="profileAssistantWrap">
                    <div className="newpageLeftRow textBlock">
                        <p className="label">Phone Number</p>
                        <div className="adressInputBlock">
                            <img className="adressLink adressLinkLeftInput" src={phoneInput} alt="Link" />
                            <input
                                className="urlInputLeft"
                                type="text"
                                value={phoneNumber}
                                placeholder="054-3140602"
                                onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                } />
                            {errors.phone && (
                                <div className="error-message">
                                    {errors.phone}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="newpageLeftRow textBlock">
                        <p className="label">Email Address</p>
                        <div className="adressInputBlock">
                            <img className="adressLink adressLinkLeftInput" src={mailInput} alt="Link" />
                            <input
                                className="urlInputLeft"
                                type="text"
                                value={publicEmail}
                                placeholder="tovbar@gmail.com"
                                onChange={(e) => setPublicEmail(e.target.value)} />
                            {errors.email && (
                                <div className="error-message">
                                    {errors.email}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="newpageLeftRow textBlock">
                        <p className="label">Facebook Messenger</p>
                        <div className="adressInputBlock urlInput">
                            <img
                                className="adressLink adressLinkLeftInput"
                                src={link}
                                alt="Link"
                            />
                            <input
                                className="urlInputLeft"
                                type="text"
                                placeholder="https://www.facebook.com/bar-tov   "
                                value={shareUrl}
                                onChange={handleShareInputChange}
                            />
                            {errors.shareUrl && (
                                <div className="error-message">
                                    {errors.shareUrl}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <button
                    onClick={handleFormSubmit}
                    className={`form-btn save-change ${isLoading ? 'btn_loading' : ''}`}
                    type='submit'>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save Changes"}
                </button>

                <span className="borderLine"></span>

                <div className="newpageLeftRow textInfoBlock">
                    <h3 className="generalLabel">Contact details</h3>
                    <p>Upload and display PDF and Office documents for your visitors to view, from detailed reports and data-rich spreadsheets to compelling presentations. You can share important content without the need for external downloads or additional software.  </p>
                    <ul className="profileContact profileContactImage">
                        <li>
                            <Link to="#"><img src={whatsapp} alt="whatsapp" />
                                <p className="contactTitle">WhatsApp</p>
                                <p className="profileLink">+972543140602</p>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="#"><img src={messager} alt="messager" />
                                <p className="contactTitle">Messenger</p>
                                <p className="profileLink">Link</p>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="#"><img src={Sms} alt="sms" />
                                <p className="contactTitle">SMS</p>
                                <p className="profileLink">+972543140602</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="#"><img src={gmail} alt="gmail" />
                                <p className="contactTitle">Email</p>
                                <p className="profileLink">assistant@netupy.com</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="#"><img src={closed} alt="closed" />
                                <p className="contactTitle">Chat</p>
                                <p className="profileLink">Open</p>
                            </Link>
                        </li>
                    </ul>
                    <div>
                        <p>We are looking forward to hear from you,</p>
                        <p>The Netupy Team</p>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ProfileAssistant;
