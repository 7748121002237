import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom"

import Map from "../Pages/Map";
import calculatePercentage from './Function';

import getCountryFlag from "../utils/functions"

import defaultIcon from ".././images/Settings/link.svg";
import vector from ".././images/Vector.png";
import linkExternal from ".././images/link-external.svg";

import ".././App.css";

function InfoListAnalytics({ analyticsData, fetchData, isLoading, filtersData, apiFilter }) {

  const [topPages, setTopPages] = useState(analyticsData.topPages);
  const [devicesData, setDevicesData] = useState(analyticsData.topBrowser);
  const [filters, setFilters] = useState(apiFilter);
  const [showPopup, setShowPopup] = useState(false);
  const aggregateData = analyticsData.aggregateData;
  const topSource = analyticsData.topSource;
  const topCountries = analyticsData.topCountry;
  const topRegions = analyticsData.topRegions;
  const topCities = analyticsData.topCities;

  const [currentState, setCurrentState] = useState("all");
  const [currentDevices, setCurrentDevices] = useState("browser");
  const [currentLocations, setCurrentLocations] = useState("locations");

  const [devicesTitle, setDevicesTitle] = useState("Browsers");

  const [data, setData] = useState([]);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [excludedKeys, setExcludedKeys] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [popupIsOff, setPopupIsOff] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const popupRef = useRef(null);

  // Close the popup when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const generateTableHeadersAndRows = (data, customHeaders, excludedKeys) => {
    const generatedHeaders = [];
    const generatedRows = [];

    if (data.length > 0) {
      const headers = (
        <tr key="header">
          {customHeaders.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      );
      generatedHeaders.push(headers);

      data.forEach((item, index) => {
        const tabName = popupIsOff;

        let itemName = '';
        let itemTitle = '';
        let displayTitle = '';

        if (tabName === 'page') {
          itemName = item?.page || '/';
          itemTitle = item?.page || '/';
          displayTitle = item?.page || '/';
        } else if (tabName === 'source') {
          itemName = item.source;
          itemTitle = item.source;
          displayTitle = `<img src=${item?.iconSrc || defaultIcon} alt=${item.source} /> ${item.source}`;
        } else if (tabName === 'country') {
          itemName = item.isoCode;
          itemTitle = item.title;
          displayTitle = `${item.isoCode} ${item.title}`;
        } else if (tabName === 'region') {
          itemName = item.code;
          itemTitle = item.title;
          displayTitle = `${item.countryCode} ${item.title}`;
        } else if (tabName === 'city') {
          itemName = item.code;
          itemTitle = item.city;
          displayTitle = `${item.countryCode} ${item.city}`;
        }

        const filteredValues = Object.entries(item).filter(([key]) => !excludedKeys.includes(key)).map(([key, value]) => {
          return value === null ? '-' : (key === 'bounce_rate' ? `${value}%` : value);
        });

        const row = (
          <tr key={index}>
            {filteredValues.map((value, i) => (
              <td key={i} onClick={i === 0 ? () => handleElementClick(itemName, tabName, itemTitle) : undefined}>
                {i === 0 ? <div dangerouslySetInnerHTML={{ __html: displayTitle }} /> : value}
              </td>
            ))}
          </tr>
        );
        generatedRows.push(row);
      });
    }

    return { generatedHeaders, generatedRows };
  };

  useEffect(() => {
    setFilters(apiFilter)
  }, [apiFilter]);

  useEffect(() => {
    const { generatedHeaders, generatedRows } = generateTableHeadersAndRows(data, customHeaders, excludedKeys);
    setHeaders(generatedHeaders);
    setRows(generatedRows);
  }, [data]);

  const handleDetailsClick = (currentData, title) => {
    setPopupTitle(title);
    if (currentData === 'topPages') {
      setCustomHeaders(['Page url', 'Visitors', 'Pageviews', 'Bounce rate', 'Time on Page']);
      setExcludedKeys(['pageType']);
      setPopupIsOff('page');
      setData(topPages);
    }
    if (currentData === 'topSource') {
      setCustomHeaders(['Source', 'Visitors', 'Bounce rate', 'Visit duration']);
      setExcludedKeys(['iconSrc']);
      setPopupIsOff('source');
      setData(topSource);
    }
    if (currentData === 'currentLocations') {
      if (currentLocations === "locations" || currentLocations === "countries") {
        setCustomHeaders(['Country', 'Visitors', '%']);
        setExcludedKeys(['isoCode', 'code']);
        setPopupIsOff('country');
        setData(topCountries);
      }
      if (currentLocations === "cities") {
        setCustomHeaders(['City', 'Visitors']);
        setExcludedKeys(['countryCode', 'code']);
        setPopupIsOff('city');
        setData(topCities);
      }
      if (currentLocations === "regions") {
        setCustomHeaders(['Region', 'Visitors']);
        setExcludedKeys(['countryCode', 'code']);
        setPopupIsOff('region');
        setData(topRegions);
      }
    }
    setShowPopup(true);
  };

  useEffect(() => {
    if (currentState == 'publications') {
      setTopPages(analyticsData.topPages.filter(page => page.pageType === 'publication'));
    } else if (currentState == 'pages') {
      setTopPages(analyticsData.topPages.filter(page => ['home', 'page', 'contact'].includes(page.pageType)));
    } else {
      setTopPages(analyticsData.topPages);
    }
  }, [currentState, analyticsData]);

  useEffect(() => {
    if (currentDevices == 'browser') {
      if (filters.includes('visit:browser==')) {
        setDevicesData(analyticsData.browserVersion);
        setDevicesTitle('Browser version');
      } else {
        setDevicesData(analyticsData.topBrowser);
        setDevicesTitle('Browsers');
      }
    } else if (currentDevices == 'os') {
      if (filters.includes('visit:os==')) {
        setDevicesData(analyticsData.osVersion);
        setDevicesTitle('Operating System Version');
      } else {
        setDevicesData(analyticsData.topOs);
        setDevicesTitle('Operating systems');
      }
    } else if (currentDevices == 'size') {
      setDevicesData(analyticsData.topDevice);
      setDevicesTitle('Screen sizes');
    } else {
      setDevicesData(analyticsData.topBrowser);
      setDevicesTitle('Browsers');
    }
  }, [currentDevices, analyticsData]);

  const handleElementClick = async (itemName, tabName, itemTitle) => {
    let filterData = filters;

    if (tabName === 'page' && !filters.includes('visit:page')) {
      filterData += `;event:page==${itemName}`;
      filtersData[tabName] = { title: 'Page', value: itemTitle, filterName: `;event:page==${itemName}`, operation: 'is' };
    } else if (tabName === 'source' && !filters.includes('visit:source')) {
      filterData += `;visit:source==${itemName}`;
      filtersData[tabName] = { title: 'Source', value: itemTitle, filterName: `;visit:source==${itemName}`, operation: 'is' };
    } else if (tabName === 'browser') {
      if (filters.includes('visit:browser') && !filters.includes('visit:browser_version')) {
        filterData += `;visit:browser_version==${itemName}`;
        filtersData['browserVersion'] = { title: 'Browser Version', value: itemTitle, filterName: `;visit:browser_version==${itemName}`, operation: 'is' };
      }
      if (!filters.includes('visit:browser')) {
        filterData += `;visit:browser==${itemName}`;
        filtersData[tabName] = { title: 'Browser', value: itemTitle, filterName: `;visit:browser==${itemName}`, operation: 'is' };
      }
    } else if (tabName === 'os') {
      if (filters.includes('visit:os') && !filters.includes('visit:os_version')) {
        filterData += `;visit:os_version==${itemName}`;
        filtersData['osVersion'] = { title: 'OS Version', value: itemTitle, filterName: `;visit:os_version==${itemName}`, operation: 'is' };
      }
      if (!filters.includes('visit:os')) {
        filterData += `;visit:os==${itemName}`;
        filtersData[tabName] = { title: 'OS', value: itemTitle, filterName: `;visit:os==${itemName}`, operation: 'is' };
      }
    } else if (tabName === 'size' && !filters.includes('visit:device')) {
      filterData += `;visit:device==${itemName}`;
      filtersData[tabName] = { title: 'Device', value: itemTitle, filterName: `;visit:device==${itemName}`, operation: 'is' };
    } else if (tabName === 'country' && !filters.includes('visit:country')) {
      filterData += `;visit:country==${itemName}`;
      filtersData[tabName] = { title: 'Country', value: itemTitle, filterName: `;visit:country==${itemName}`, operation: 'is' };
    }

    if (filterData !== filters) {
      setShowPopup(false);
      setFilters(filterData);
      await fetchData(filterData, filtersData);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="infoBoxes">
        <div className="infoTitle infobox-content">
          <div className="infoTitleHeader">
            <p className="dashUserStatus">Top Content</p>
            <div className="infoTabBtnAnalytics">
              <button className={currentState === "all" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("all")} >
                All
              </button>
              <button className={currentState === "pages" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("pages")} >
                Pages
              </button>
              <button className={currentState === "publications" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("publications")} >
                Publications
              </button>
              <button className={currentState === "newsletters" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("newsletters")} >
                Newsletters
              </button>
            </div>
          </div>

          <div className="infoTitleGrid infoTitleGridAnalytics">
            <div className="infoReferrersBlock infoTitles">
              <div> Page </div>
              <div> Visitors </div>
            </div>
            <div className="infoRefWrap">
              {!isLoading ? (
                topPages && topPages.length > 0 ? (
                  topPages.map((item, index) => (
                    <div key={index} className="infoReferrersBlock">
                      <div className="title">
                        <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, topPages.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                        <p onClick={() => handleElementClick(item?.page || "/", "page", item?.page || "Home")}>{item?.page || "/"} </p>
                        <Link to={`https://jourmal-next-front.vercel.app${item?.page}`} target="blank"><img src={linkExternal} alt={item?.page || "/"} /></Link>
                      </div>
                      <div>
                        <p className="graphNumber">{item?.visitors || 0}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No data yet</p>
                )
              ) : (
                <>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </>
              )}
            </div>
            {!isLoading && topPages && topPages.length > 0 && (
              <div
                className="detailsLogo"
                onClick={() => {
                  if (currentState === "all") {
                    handleDetailsClick("topPages", "Top Content");
                  } else if (currentState === "pages") {
                    handleDetailsClick("topPages", "Top Pages");
                  } else if (currentState === "publications") {
                    handleDetailsClick("topPages", "Top Publications");
                  } else {
                    handleDetailsClick("topPages", "Top Newsletters");
                  }
                }}
              >
                <Link to="#">
                  <img src={vector} alt="Logo" />
                  DETAILS
                </Link>
              </div>
            )}
          </div>

        </div>

        <div className="infoReferrers infoReferrersAnalytics infobox-source">
          <div className="infoTitleHeader">
            <p className="dashUserStatus">Top Sources</p>
          </div>
          <div className="infoTitleGrid">
            <div className="infoReferrersBlock referrersBlock referrersTitle">
              <div>Source</div>
              <div>Visitors</div>
            </div>
            <div className="infoRefWrap">
              {isLoading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              ) : (
                topSource && topSource.length > 0 ? (
                  topSource.map((item, index) => (
                    <div key={index} className="infoReferrersBlock referrersBlock">
                      <div className="titleLogo" onClick={() => handleElementClick(item.source, 'source', item.source)}>
                        <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, topSource.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                        <img src={item?.iconSrc || defaultIcon} alt={item.source} />
                        <p>
                          {item.source}
                        </p>
                      </div>
                      <div>
                        <p className="graphNumber">{item.visitors}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="infoReferrersBlock referrersBlock">
                    <p className="no-data">No data yet</p>
                  </div>
                )
              )}
            </div>
            {(!isLoading && topSource && topSource.length > 0) && (
              <div className="detailsLogo" onClick={() => handleDetailsClick('topSource', 'Top Sources')}>
                <Link to="#">
                  <img src={vector} alt="Logo" />
                  DETAILS
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="infoBoxes">
        <div className="infoTitle infoLocation">
          <div className="infoTitleHeader">
            <p className="dashUserStatus">{currentLocations}</p>
            <div className="infoTabBtnAnalytics">
              <button className={currentLocations === "locations" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentLocations("locations")} >
                Map
              </button>
              <button className={currentLocations === "countries" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentLocations("countries")} >
                Countries
              </button>
            </div>
          </div>

          <div className="infoTitleGrid infoTitleGridAnalytics">

            {currentLocations === 'locations' &&
              (
                isLoading ?
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                  :
                  topCountries &&
                  <Map topCountries={topCountries} totalVisitors={aggregateData?.visitors?.value} handleElementClick={handleElementClick} setCurrentLocations={setCurrentLocations} />
              )

            }

            {currentLocations === 'countries' &&
              <>
                <div className="infoReferrersBlock referrersBlock referrersTitle">
                  <div>Country</div>
                  <div>Visitors</div>
                </div>
                {!isLoading && topCountries && topCountries.length > 0 ? (
                  <React.Suspense>
                    <div className="infoRefWrap">
                      {topCountries.map((item, index) => (
                        <>
                          <div key={index} className="infoReferrersBlock referrersBlock">
                            <div className="titleLogo" onClick={() => { handleElementClick(item.isoCode, 'country', item.title); }} >
                              <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, topCountries.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                              <p>
                                <span>{getCountryFlag(item.isoCode)}</span> {item.title}
                              </p>
                            </div>
                            <div>
                              <p className="graphNumber">{item.visitors}</p>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </React.Suspense>
                ):(!isLoading && <p className="no-data">No data yet</p>)}
                {isLoading &&
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                }
                </>
            }
          </div>

          {/* Details Logo */}
          {(!isLoading && topCountries && topCountries.length > 0) &&
            <div className="detailsLogo" onClick={() => handleDetailsClick('currentLocations', currentLocations === 'locations' ? 'Top countries' : `Top ${currentLocations}`)}>
              <Link to="#">
                <img src={vector} alt="Logo" />
                DETAILS
              </Link>
            </div>
          }
        </div>

        <div className="infoReferrers infoReferrersAnalytics infoDevices infobox-device">
          <div className="infoTitleHeader">
            <p className="dashUserStatus">Devices</p>
            <div className="infoTabBtnAnalytics">
              <button className={currentDevices === "browser" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentDevices("browser")} >
                Browsers
              </button>
              <button className={currentDevices === "os" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentDevices("os")} >
                OS
              </button>
              <button className={currentDevices === "size" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentDevices("size")} >
                Size
              </button>
            </div>
          </div>

          <div className="infoTitleGrid">
            <div className="infoReferrersBlock referrersBlock referrersTitle">
              <div> {devicesTitle} </div>
              <div> Visitors </div>
              <div> % </div>
            </div>

            <div className="infoRefWrap">
              {!isLoading && devicesData && devicesData.length > 0 ? (
                devicesData.map((item, index) => (
                  <div key={index} className="infoReferrersBlock referrersBlock">
                    {item.title && ( // Check if item.title exists
                      <div className="titleLogo" onClick={() => handleElementClick(item.title, currentDevices, item.title)}>
                        <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, devicesData.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                        <p>{item.title}</p>
                      </div>
                    )}
                    <div>
                      <p className="graphNumber">{item.visitors}</p>
                    </div>
                    <div>
                      <p className="graphNumber">{item.percentage}</p>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {isLoading &&
                    <div className="loader-container">
                      <div className="loader"></div>
                    </div>}
                  {!isLoading && (!devicesData || devicesData.length === 0) && <p className="no-data">No data yet</p>}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="detailsPopup">
          <div className="detailsPopup-inner" ref={popupRef}>
            <span className="detailsClose" onClick={handleClosePopup}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#b8c2cc" className="popupClose">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z">
                </path>
              </svg>
            </span>
            <div className="detailsPopup-content">

              <h2>{popupTitle}</h2>
              <div className="detailsTableWrap">
                <table>
                  <thead>{headers}</thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
}

export default InfoListAnalytics;
