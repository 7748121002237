import React, { useEffect, useState } from "react";

import { formatTime } from "../utils/dateFormatter";

function AnalyticsOnlineVisitors({onlineVisitors, showTooltip}) {
    
    const [timeSinceLastLoad, setTimeSinceLastLoad] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setTimeSinceLastLoad(prevTime => prevTime + 1);
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <p>
              { onlineVisitors > 0 ?
                <><span>{onlineVisitors}</span> Online {onlineVisitors > 1 ? 'Visitors' : 'Visitor'}</>
                : 
                'No online visitors'  
              }              
            </p>
            {showTooltip && (
              <div className="online-visitors-tooltip">
                <p>
                  Last updated {formatTime(timeSinceLastLoad)} ago
                </p>
              </div>
            )}
        </>
    )
}

export default AnalyticsOnlineVisitors;