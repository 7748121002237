import { useEffect, useState } from 'react';
import '.././App.css';
function CategoriesListItem(props) {

    const [checkbox, setCheckbox] = useState(false)

    useEffect(() => {
        if (props.selected.indexOf(props.category) !== -1) {
            setCheckbox(true)
        }
    }, [props.selected])



    return (
        <li onClick={e => {
            setCheckbox(!checkbox)

            if (checkbox !== true) {

                props.changeCategory([...props.selected, props.category])

            } else {

                const result = props.selected.filter((category) => category !== props.category)
                props.changeCategory(result)

            }
        }}>
            <input className='categoriesSelect' type="checkbox" checked={checkbox} />
            <p>{props.category}</p>
        </li>
    );
}

export default CategoriesListItem;
