
import React, { useEffect, useState, useRef } from 'react'
import ".././App.css";
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'
import Chart from 'chart.js/auto'

function MonetizationAnalyticsForAdsMrr() {

    const chartRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);


    const [analyticsActive, setAnalyticsActive] = useState("ads_mrr");


    return (
        <div className="dashboardGraph analyticsCharts">
            <div>
                {!isLoading &&
                    <>
                        <div className="dashboardGraphCotnent">
                            <div className="analyticsButtons">
                                <div className={analyticsActive === "ads_mrr" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("ads_mrr")} >
                                    <p className="analyticsButtonTitle">ADS MRR</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="analyticsNumbers">7937$</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            19%
                                        </span>
                                    </div>
                                </div>
                                <div className={analyticsActive === "total_clicks" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("total_clicks")} >
                                    <p className="analyticsButtonTitle">TOTAL CLICKS</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">4821</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            16%
                                        </span>
                                    </div>
                                </div>
                                <div className={analyticsActive === "cost_per_clicks" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("cost_per_clicks")} >
                                    <p className="analyticsButtonTitle">COST PER CLICKS</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">$1.9</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={down} alt="Down" className="svg-icon" />
                                            13%
                                        </span>

                                    </div>
                                </div>
                                <div className={analyticsActive === "tier2_subscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("tier2_subscribers")} >
                                    <p className="analyticsButtonTitle">AD REQUEST RPM</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">3.44</p>
                                        </div>
                                        <span className="graphPercent greenPercent">
                                            <img src={up} alt="Up" className="svg-icon" />
                                            1%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboardChart">
                            <div className="visitor-grreapoh">
                                <canvas id="main-graph-canvas" ref={chartRef} width="400" height="400"></canvas>
                            </div>
                        </div>
                    </>
                }

                {isLoading &&
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                }
            </div>
        </div>

    );
}

export default MonetizationAnalyticsForAdsMrr;
