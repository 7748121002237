import React, { useEffect, useState, useRef } from 'react'
import ".././App.css";
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'
import dateFormatter, { formatTime } from '../utils/dateFormatter';
import { Chart } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';

import currencies from '../utils/currency.json'
import { formatNumber } from '../utils/functions';

function MonetizationAnalyticsCharts({ graphData, compareData, isLoading, aggregateData, compareAggregateData, selectedPeriod, setSelectedPeriod, setSelectedDate, showCompare, setTempPeriod, setPreviousDate, stripeBalance}) {
    const chartRef = useRef(null)

    // const [isLoading, setIsLoading] = useState(false);
    const [totalSubscriberCount,setTotalSubscriberCount] = useState(0)
    const [totalFreeSubscriberCount,setTotalFreeSubscriberCount] = useState(0)
    const [totalPaidSubscriberCount,setTotalPaidSubscriberCount] = useState(0)
    const [totalMonthlySubscribersCount,setTotalMonthlySubscribersCount] = useState(0)
    const [totalYearlySubscribersCount,setTotalYearlySubscribersCount] = useState(0)
    const [totalTrialSubscriber,setTotalTrialSubscriber] = useState(0)
    const [totalPayout, setTotalPayout] = useState(0)
    const [openRate, setOpenRate] = useState(0)

    const [totalSubscribersCompareCount, setTotalSubscribersCompareCount ] = useState(0)
    const [totalFreeSubscribersCompareCount, setTotalFreeSubscribersCompareCount] = useState(0)
    const [totalPaidSubscribersCompareCount, setTotalPaidSubscribersCompareCount] = useState(0)
    const [totalMonthlySubscribersCompareCount, setTotalMonthlySubscribersCompareCount] = useState(0)
    const [totalYearlySubscribersCompareCount, setTotalYearlySubscribersCompareCount] = useState(0)
    const [totalTrialSubscribersCompareCount, setTotalTrialSubscribersCompareCount] = useState(0)
    const [totalPayoutCompare, setTotalPayoutCompare] = useState(0)
    const [openRateCompare, setOpenRateCompare] = useState(0)

    const [totalSubscriberRate, setTotalSubscriberRate] = useState(0)
    const [totalFreeSubscribersRate, setTotalFreeSubscribersRate] = useState(0)
    const [totalPaidSubscribersRate, setTotalPaidSubscribersRate] = useState(0)
    const [totalMonthlySubscribersRate, setTotalMonthlySubscribersRate] = useState(0)
    const [totalYearlySubscribersRate, setTotalYearlySubscribersRate] = useState(0)
    const [totalTrialSubscribersRate, setTotalTrialSubscribersRate] = useState(0)
    const [totalPayoutRate, setTotalPayoutRate] = useState(0)
    const [openRateChanges, setOpenRateChanges] = useState(0)

    const currencySymbol = currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'
    
    const [analyticsActive, setAnalyticsActive] = useState("payout");
    const analyticsFilter = {
      "payout": {
        title: 'Total Earnings'
      },
      subscribers: {
        title: 'Subscriber'
      },
      freeSubscribers: {
        title: 'Free Subscriber'
      },
      paidSubscribers: {
        title: 'Paid Subscriber'
      },
      monthlySubscribers: {
        title: 'Monthly Subscriber'
      },
      yearlySubscribers: {
        title: 'Annual Subscriber'
      },
      trialSubscribers:{
        title: 'Trial Subscriber'
      }
    }
    useEffect(() => {
      setTotalSubscriberCount(aggregateData?.totalSubscribers?.value || 0);
      setTotalFreeSubscriberCount(aggregateData?.freeSubscribers?.value || 0);
      setTotalPaidSubscriberCount(aggregateData?.paidSubscribers?.value || 0);
      setTotalMonthlySubscribersCount(aggregateData?.monthlySubscribers?.value || 0);
      setTotalYearlySubscribersCount(aggregateData?.yearlySubscribers?.value || 0);
      setTotalTrialSubscriber(aggregateData?.trialSubscribers?.value)
      setTotalPayout(aggregateData?.totalPayout?.value || 0);
      setOpenRate(aggregateData?.openRate?.value || 0)

      setTotalSubscribersCompareCount(aggregateData?.totalSubscribers?.compareValue || 0);
      setTotalFreeSubscribersCompareCount(aggregateData?.freeSubscribers?.compareValue || 0);
      setTotalPaidSubscribersCompareCount(aggregateData?.paidSubscribers?.compareValue || 0)
      setTotalMonthlySubscribersCompareCount(aggregateData?.monthlySubscribers?.compareValue || 0)
      setTotalYearlySubscribersCompareCount(aggregateData?.yearlySubscribers?.compareValue || 0)
      setTotalTrialSubscribersCompareCount(aggregateData?.trialSubscribers?.compareValue)
      setTotalPayoutCompare(aggregateData?.totalPayout?.compareValue || 0)
      setOpenRateCompare(aggregateData?.openRate?.compareValue || 0)

      setTotalSubscriberRate(aggregateData?.totalSubscribers?.change || 0);
      setTotalFreeSubscribersRate(aggregateData?.freeSubscribers?.change || 0);
      setTotalPaidSubscribersRate(aggregateData?.paidSubscribers?.change || 0);
      setTotalMonthlySubscribersRate(aggregateData?.monthlySubscribers?.change || 0);
      setTotalYearlySubscribersRate(aggregateData?.yearlySubscribers?.change || 0);
      setTotalTrialSubscribersRate(aggregateData?.trialSubscribers?.change || 0);
      setTotalPayoutRate(aggregateData?.totalPayout?.change || 0)
      setOpenRateChanges(aggregateData?.openRate?.change || 0)


    }, [aggregateData]);
   
    useEffect(() => {
      if (graphData) {
        const ctx = chartRef.current.getContext('2d');
  
        var gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, 'rgba(101,116,205, 0.2)');
        gradient.addColorStop(1, 'rgba(101,116,205, 0)');
  
        const datasets = [{
          label: analyticsFilter[analyticsActive]?.['title'],
          data: graphData.map(data => data[analyticsActive]),
          borderWidth: 3,
          pointHoverRadius: 4,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(101,116,205)',
          pointBorderColor: "transparent",
          pointBackgroundColor: 'rgba(101,116,205)',
          pointHoverBackgroundColor: 'rgba(71, 87, 193)',
          yAxisID: 'y',
        }]
  
        if( showCompare ) {
          datasets.push({
            label: analyticsFilter[analyticsActive]?.['title'],
            data: compareData?.map(data => data[analyticsActive]),
            borderWidth: 3,
            pointHoverRadius: 4,
            fill: true,
            backgroundColor: gradient,
            borderColor: 'rgba(60,70,110,0.2)',
            pointBorderColor: "transparent",
            pointBackgroundColor: 'rgba(60,70,110,0.2)',
            pointHoverBackgroundColor: 'rgba(60, 70, 110)',
            yAxisID: 'yComparison',
          })
        }
  
        // Dummy data
        const data = {
          labels: graphData.map(data => dateFormatter(data?.date, selectedPeriod?.value === 'day' ? 'hour' : 'date', '', false)),
          datasets: datasets
        };
        // Create the chart
        const myChart = new Chart(ctx, {
          type: 'line',
          data: data,
          options: {
            animation: false,
            plugins: {
              legend: { display: false },
              tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
                position: 'average',
                external: GraphTooltip()
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            elements: { line: { tension: 0 }, point: { radius: 0 } },
            onClick: handleClick,
            scale: {
              ticks: { precision: 0, maxTicksLimit: 8 }
            },
            scales: {
              y: {
                min: 0,
                suggestedMax: calculateMaximumY(datasets),
                ticks: {
                  callback: function (value, index, values) {
                    return value;
                  }
                },
                border: {
                  display: false
                },
                grid: {
                  zeroLineColor: 'transparent',
                  drawBorder: false,
                }
              },
              yComparison: {
                min: 0,
                display: false,
                suggestedMax: calculateMaximumY(datasets),
                grid: { display: false },
              },
              x: {
                grid: { display: false },
              }
            },
            interaction: {
              mode: 'index',
              intersect: false,
            }
          },
        });
  
        window.addEventListener('mousemove', repositionTooltip);
  
        function handleClick(event) {
          if (selectedPeriod.value !== 'day') {
            const canvasPosition = getRelativePosition(event, myChart);
  
            const dataX = myChart.scales.x.getValueForPixel(canvasPosition.x);
            
            const data = graphData?.[dataX]
            const compareDate = compareData?.[dataX];
            const formatedDate = dateFormatter(data.date, 'date', '');
            setPreviousDate(compareDate?.date || false);
            setSelectedDate(data.date);
            setSelectedPeriod({ value: "day", label: formatedDate })
            setTempPeriod({ value: "day", label: formatedDate })
          }
        }
        return () => {
          // Cleanup chart on unmount
          myChart.destroy();
        };
  
      }

    }, [graphData, analyticsActive]);
  
    const GraphTooltip = () => {
  
      return (context) => {
        const tooltipModel = context.tooltip;
        const offset = document.getElementById("main-graph-canvas").getBoundingClientRect();
        let tooltipEl = document.getElementById('chartjs-tooltip');
  
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.style.display = 'none';
          tooltipEl.style.opacity = 0;
          document.body.appendChild(tooltipEl);
        }
  
        if (tooltipEl && offset && window.innerWidth < 768) {
          tooltipEl.style.top = offset.y + offset.height + window.scrollY + 15 + 'px'
          tooltipEl.style.left = offset.x + 'px'
          tooltipEl.style.right = null
          tooltipEl.style.opacity = 1
        }
  
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.display = 'none';
          return;
        }
  
        const data = tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "y");
        const value = analyticsActive === 'payout' ? currencySymbol+data?.raw || 0 : data?.raw || 0
        const updatedData = graphData[data?.dataIndex];
        const formatedDate = dateFormatter(updatedData?.date, 'date', '');
        const formatedTime = dateFormatter(updatedData?.date, 'hour', '');
        const date = `${formatedDate}${selectedPeriod.value === 'day' ? `, ${formatedTime}` : ''}`;
  
        
        const comparisonData = showCompare ? tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "yComparison") : {};
        
        const isCompareIndex = comparisonData && comparisonData.hasOwnProperty('dataIndex')
  
        const comparisonValue = analyticsActive === 'payout' ? currencySymbol+comparisonData?.raw || 0 : comparisonData?.raw || 0
        const comparisonUpdatedData = showCompare && isCompareIndex ? compareData[comparisonData.dataIndex] : {};
        
        const comparisonFormatedDate = showCompare && isCompareIndex? dateFormatter(comparisonUpdatedData?.date, 'date', '') : '';
        const comparisonFormatedTime = showCompare && isCompareIndex ? dateFormatter(comparisonUpdatedData?.date, 'hour', '') : '';
        const comparisonDate = showCompare && isCompareIndex ? `${comparisonFormatedDate}${selectedPeriod.value === 'day' ? `, ${comparisonFormatedTime}` : ''}` : '';
  
        if (tooltipModel.body) {
          const comparisonTooltip = showCompare && isCompareIndex ? `<div class="tooltip-subtitle"><div class="tooltip-mark compare-mark"></div>${comparisonDate} <div class="tooltip-value">${(analyticsActive === 'visit_duration') ? formatTime(comparisonValue) : comparisonValue}</div></div>` : '';
  
          tooltipEl.innerHTML = `
            <div class="grapph-tooltip">
              <div class="tooltip-title">${data?.dataset?.label}</div>
              <div class="tooltip-subtitle"> <div class="tooltip-mark"></div>${date} <div class="tooltip-value">${(analyticsActive === 'visit_duration') ? formatTime(value) : value}</div> </div>
              ${showCompare ? comparisonTooltip : ''}
            </div>
          `;
        }
        tooltipEl.style.display = null;
      };
    };
  
    const repositionTooltip = (e) => {
      const tooltipEl = document.getElementById('chartjs-tooltip');
      if (tooltipEl && window.innerWidth >= 768) {
        if (e.clientX > 0.66 * window.innerWidth) {
          tooltipEl.style.right = (window.innerWidth - e.clientX) + window.pageXOffset + 'px';
          tooltipEl.style.left = null;
        } else {
          tooltipEl.style.right = null;
          tooltipEl.style.left = e.clientX + window.pageXOffset + 'px';
        }
        tooltipEl.style.top = e.clientY + window.pageYOffset + 'px';
        tooltipEl.style.opacity = 1;
      }
    };
  
    const calculateMaximumY = function (dataset) {
      const yAxisValues = dataset
        .flatMap((item) => item.data)
        .map((item) => item || 0)
  
      if (yAxisValues) {
        return Math.max(...yAxisValues)
      } else {
        return 1
      }
    }
    return (
        <div className="dashboardGraph analyticsCharts dashboard-analytics">
            <div>
                {!isLoading &&
                    <>
                        <div className="dashboardGraphCotnent">
                            <div className="analyticsButtons">
                                <div className={analyticsActive === "payout" ? "graphButton totalEarning greenPercent dashboardGraphActiveButton" : "graphButton totalEarning"} onClick={() => setAnalyticsActive("payout")} >
                                    <p className="analyticsButtonTitle">Total Earnings</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="analyticsNumbers">{currencySymbol}{formatNumber(totalPayout)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>${formatNumber(totalPayoutCompare)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        } 
                                        {! showCompare &&
                                          <span className="graphPercent greenPercent">
                                              {totalPayoutRate !== 0 ? (
                                              totalPaidSubscribersRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                              ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                              )) : (
                                                "〰"
                                              )}
                                              {Math.abs(totalPayoutRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>

                                <div className={analyticsActive === "subscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("subscribers")} >
                                    <p className="analyticsButtonTitle">Total Subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="analyticsNumbers">{formatNumber(totalSubscriberCount)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalSubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        } 
                                        {! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalSubscriberRate !== 0 ? (
                                              totalSubscriberRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                              ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                              )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalSubscriberRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "freeSubscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("freeSubscribers")} >
                                    <p className="analyticsButtonTitle">Free subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">{formatNumber(totalFreeSubscriberCount)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalFreeSubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        }
                                        {! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalFreeSubscribersRate !== 0 ? (
                                            totalFreeSubscribersRate >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalFreeSubscribersRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "paidSubscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("paidSubscribers")} >
                                    <p className="analyticsButtonTitle">Paid subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">{formatNumber(totalPaidSubscriberCount)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalPaidSubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        }
                                        { ! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalPaidSubscribersRate !== 0 ? (
                                            totalPaidSubscribersRate >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalPaidSubscribersRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "monthlySubscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("monthlySubscribers")} >
                                    <p className="analyticsButtonTitle">Monthly subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">{formatNumber(totalMonthlySubscribersCount)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalMonthlySubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        }
                                        { ! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalMonthlySubscribersRate !== 0 ? (
                                            totalMonthlySubscribersRate >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalMonthlySubscribersRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "yearlySubscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("yearlySubscribers")} >
                                    <p className="analyticsButtonTitle">Annual subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">{formatNumber(totalYearlySubscribersCount)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalYearlySubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        }
                                        { ! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalYearlySubscribersRate !== 0 ? (
                                            totalYearlySubscribersRate >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalYearlySubscribersRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "trialSubscribers" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("trialSubscribers")} >
                                    <p className="analyticsButtonTitle">Trial subscribers</p>
                                    <div>
                                        <div className='analytics-data'>
                                            <p className="graphNumber">{formatNumber(totalTrialSubscriber)}</p>
                                            {
                                              showCompare && graphData && graphData.length > 0 && 
                                                <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                                            }
                                        </div>
                                        {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{formatNumber(totalTrialSubscribersCompareCount)}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                        }
                                        { ! showCompare &&
                                          <span className="graphPercent greenPercent">
                                            {totalTrialSubscribersRate !== 0 ? (
                                            totalTrialSubscribersRate >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                            {Math.abs(totalTrialSubscribersRate)}%
                                          </span>
                                        }
                                    </div>
                                </div>
                                <div className={analyticsActive === "average-open-rate" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} >
                                    <p className="analyticsButtonTitle">AVERAGE OPEN RATE</p>
                                    <div>
                                      <div className='analytics-data'>
                                        <p className="analyticsNumbers">{`${formatNumber(openRate)}%`}</p>
                                      </div>
                                      {
                                          showCompare && compareData && compareData.length > 0 &&
                                          <div className='compare-data'>
                                              <p className='analyticsNumbers compareNumbers'>{`${formatNumber(openRateCompare)}%`}</p>
                                              <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                                            </div>
                                      }
                                      { ! showCompare &&
                                        <span className="graphPercent greenPercent">
                                          {openRateChanges !== 0 ? (
                                            openRateChanges >= 0 ? (
                                              <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                              <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                              "〰"
                                            )}
                                          {Math.abs(openRateChanges)}%
                                        </span>
                                      }
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="dashboardChart">
                            <div className="visitor-grreapoh">
                                <canvas id="main-graph-canvas" ref={chartRef} width="400" height="400"></canvas>
                            </div>
                        </div>
                    </>
                }

                {isLoading &&
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                }
            </div>
        </div>

    );
}

export default MonetizationAnalyticsCharts;
