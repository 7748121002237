import React, { useEffect, useRef } from 'react'
import close from '../../images/Settings/close.svg'
export const InvitationModel = ({setOpenInvitationPopup, isOpenInvitationPopup, handleInvitateToChange, handleInvitateToKeyPress, handleSendInvitation, invitateTo, isLoading, invitationError, invitationMessage ,setIsLoading, setInvitateTo, setInvitationError, setInvitationMessage, userMetaData}) => {
    const popupRef = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpenInvitationPopup && setOpenInvitationPopup && popupRef?.current && !popupRef?.current.contains(e.target)) {
                setOpenInvitationPopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpenInvitationPopup]);
    return (
        <div>
            <div className='popupOverlay'></div>
            <div className='welcomeModal inviteModal' ref={popupRef}>
                <div className='popupBlock-wrapper'>
                <div className="popupTop popupTopClose closePlan">
                    <img src={close} onClick={e => {setOpenInvitationPopup(false); setIsLoading(false); setInvitationError(false); setInvitationMessage("")}} alt="Icon" />
                </div>
                <p className='welcomeTitle'>Invite your colleagues</p>
                <p className='welcomeText'>Invited people enjoy exclusive discounts on our paid plans:</p>
                <div className='step_content'>
                    <div className="invite-box-popup">
                        <div className="giftbox">
                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 4.5V16.5M9 4.5H6.34821C5.95749 4.5 5.58278 4.34196 5.30649 4.06066C5.03021 3.77936 4.875 3.39782 4.875 3C4.875 2.60218 5.03021 2.22064 5.30649 1.93934C5.58278 1.65804 5.95749 1.5 6.34821 1.5C8.41071 1.5 9 4.5 9 4.5ZM9 4.5H11.6518C12.0425 4.5 12.4172 4.34196 12.6935 4.06066C12.9698 3.77936 13.125 3.39782 13.125 3C13.125 2.60218 12.9698 2.22064 12.6935 1.93934C12.4172 1.65804 12.0425 1.5 11.6518 1.5C9.58929 1.5 9 4.5 9 4.5ZM15 8.25V14.1C15 14.9401 15 15.3601 14.8365 15.681C14.6927 15.9632 14.4632 16.1927 14.181 16.3365C13.8601 16.5 13.4401 16.5 12.6 16.5L5.4 16.5C4.55992 16.5 4.13988 16.5 3.81901 16.3365C3.53677 16.1927 3.3073 15.9632 3.16349 15.681C3 15.3601 3 14.9401 3 14.1V8.25M1.5 5.7L1.5 7.05C1.5 7.47004 1.5 7.68006 1.58175 7.84049C1.65365 7.98161 1.76839 8.09635 1.90951 8.16825C2.06994 8.25 2.27996 8.25 2.7 8.25L15.3 8.25C15.72 8.25 15.9301 8.25 16.0905 8.16825C16.2316 8.09635 16.3463 7.98161 16.4183 7.84049C16.5 7.68006 16.5 7.47004 16.5 7.05V5.7C16.5 5.27996 16.5 5.06994 16.4183 4.90951C16.3463 4.76839 16.2316 4.65365 16.0905 4.58175C15.9301 4.5 15.72 4.5 15.3 4.5L2.7 4.5C2.27996 4.5 2.06994 4.5 1.90951 4.58175C1.76839 4.65365 1.65365 4.76839 1.58175 4.90951C1.5 5.06994 1.5 5.27996 1.5 5.7Z" stroke="#0A2540" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                            <div className="LatesInfoContent">
                                <p className='LatesInfoTitel'>
                                    30 days free
                                </p>
                                <p className='welcomeText'>Unlimited plan for 30 days, no credit card required.</p>
                            </div>
                        </div>
                        <div className="giftbox">
                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 6.75H6.7575M11.25 11.25H11.2575M12 6L6 12M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM7.125 6.75C7.125 6.95711 6.95711 7.125 6.75 7.125C6.54289 7.125 6.375 6.95711 6.375 6.75C6.375 6.54289 6.54289 6.375 6.75 6.375C6.95711 6.375 7.125 6.54289 7.125 6.75ZM11.625 11.25C11.625 11.4571 11.4571 11.625 11.25 11.625C11.0429 11.625 10.875 11.4571 10.875 11.25C10.875 11.0429 11.0429 10.875 11.25 10.875C11.4571 10.875 11.625 11.0429 11.625 11.25Z" stroke="#0A2540" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                            <div className="LatesInfoContent">
                                <p className='LatesInfoTitel'>
                                    25% off
                                </p>
                                <p className='welcomeText'>Special discount on upgrade for the first 3 months.</p>
                            </div>
                        </div>
                    </div>
                    <div className='step_content_wrapp'>
                        <div className='invite-box-user invite-box-user-popup'>
                            <div className='invite-text-box'>
                                    <p className='field-label-invite'>Email Address</p>
                                <div className='invite-mail'>
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="invite-mail-icon">
                                        <path d="M1.66669 3.83594L8.47079 8.59881C9.02176 8.98449 9.29725 9.17733 9.59691 9.25203C9.8616 9.31801 10.1384 9.31801 10.4031 9.25203C10.7028 9.17733 10.9783 8.98449 11.5293 8.59881L18.3334 3.83594M5.66669 14.6693H14.3334C15.7335 14.6693 16.4336 14.6693 16.9683 14.3968C17.4387 14.1571 17.8212 13.7747 18.0609 13.3042C18.3334 12.7695 18.3334 12.0694 18.3334 10.6693V5.33594C18.3334 3.93581 18.3334 3.23574 18.0609 2.70096C17.8212 2.23056 17.4387 1.8481 16.9683 1.60842C16.4336 1.33594 15.7335 1.33594 14.3334 1.33594H5.66669C4.26656 1.33594 3.56649 1.33594 3.03171 1.60842C2.56131 1.8481 2.17885 2.23056 1.93917 2.70096C1.66669 3.23574 1.66669 3.93581 1.66669 5.33594V10.6693C1.66669 12.0694 1.66669 12.7695 1.93917 13.3042C2.17885 13.7747 2.56131 14.1571 3.03171 14.3968C3.56649 14.6693 4.26656 14.6693 5.66669 14.6693Z" stroke="#868C98" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <input className={`${userMetaData?.isInvitedCount <= 0 ? `disabledInvitationField` :''}`} type="text" placeholder="Type an email address..." value={invitateTo} onChange={handleInvitateToChange} onKeyPress={handleInvitateToKeyPress} />
                                </div>
                                { invitationMessage && 
                                    <p className={invitationError ? 'error-message' : 'success-message'} dangerouslySetInnerHTML={{ __html: invitationMessage }} ></p>
                                }
                            </div>
                        </div>
                    </div>
                    <p className='invitation welcomeText'>You have <a href=" #">{userMetaData?.isInvitedCount}</a> invitations left.</p>
                </div>
                <div className="popupButtons">
                <button className='settingsWhite billingSaveBtn' onClick={ () => setOpenInvitationPopup(false) }  >
                    Cancel
                </button>
                <button className={`settingsBlue billingSaveBtn ${userMetaData?.isInvitedCount > 0 ? ` verifyLoader invitation-model` : " UpgradePlanbtn disable"}`} onClick={handleSendInvitation}>
                {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Send Invite"}
                    
                </button>
            </div>
                </div>
            </div>
        </div>
    )
}
