import { useState } from "react";
import { Link } from "react-router-dom";
import ".././App.css";
import reset from "../images/reset.svg";
import back from "../images/back.svg";
import CheckEmail from "../Components/CheckEmail";
import axios from "axios";
import BgFormAnimate from './BgFormAnimate';

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [resetDone, setResetDone] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.length > 0) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true);
    }

    if (emailPattern.test(email)) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true)
    }
    if (emailPattern.test(email)) {
      userSendmail();
    }
  };

  const userSendmail = () => {
    setIsLoading(true);
    const data = {
      email: email,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/user/emailsend`, data)
      .then((response) => {
        setError(false);
        setResetDone(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
        setResetDone(false);
        setErrorData(error?.response?.data?.message);
      });
  };

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <>
    <BgFormAnimate />
    <form className='form-design' onSubmit={handleSubmit}>
      <section className="login">
        {resetDone === false || error === true ? (
          <div className="loginWindow">
            <img src={reset} alt="Login" />
            <p className="loginTitle">Forgot password?</p>
            <p className="loginSubtitle">Please enter your details.</p>
            <div className="loginRow">
              <p>Email</p>
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={email}
                autoFocus={true}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value.length > 0) {
                    setValidateEmail(false);
                    setError(false);
                  }
                }}
                className={validateEmail === true ? "inputError" : error === true ? "inputError" : " "}
              />
              {validateEmail === true ? (
                <p className="validateError">Please enter a valid email address.</p>
              ) : (
                ""
              )}
              {error === true ? <p className="validateError">{errorData}</p> : ""}
            </div>
            <button className={`loginButton ${isLoading ? 'btn_loading' : ''}`} type="submit">
              {isLoading ?
                <div className="settingPage-loader">
                  <div className="ring-loader"></div>
                </div>
                : "Send reset email"}
            </button>
            <p className="loginBottom resetBottom">
              <Link to={"/login"}>
                <img src={back} alt="Back" />
                Back to log in
              </Link>
            </p>
          </div>
        ) : (
          <CheckEmail email={email} />
        )}

        <div className="pvivacy_mobile">
          <p>
            ©Jourmal 2022. All Rights Reserved. <span>Terms</span> &{" "}
            <span>Privacy</span>.
          </p>
        </div>
      </section>
    </form>
    </>
  );
}

export default ForgotPassword;
