import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function MultipleDeletePages(props) {
  const history = useNavigate()
  const inputRef = useRef(null);
  const { activeFilter, type, selectedItems, name, updateOriginalData, customKey, setPages, isOpen, onClose, setShowMultiNotify, setShowNotification, setSelectedPubLenght, setSelectedItems } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  const [error, setError] = useState(false);
  const [errorMassage, setErrorMassage] = useState('');
 
  useEffect(() => {
    if (isOpen && inputRef.current) {
        inputRef.current.focus();
    }

}, [isOpen]);
  
  if (!isOpen) {
    return null;
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleDelete();
    }
  };

  const handleDelete = async () => {
    try {      
      setError(false)
      setValidatePassword(false)
      if (activeFilter !== "Deleted") {
        setIsLoading(true);
        
        const updatePromises = selectedItems?.map(async (id) => {
            let updateAPI = `${process.env.REACT_APP_API_URL}/${type}/update/${id}`;

            if(type == 'page') {
                updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${id}`;
            }
            if(type == 'publication') {
                updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepublication/${id}`;
            }

            const response = await axios.post(
              updateAPI,
              {
                status: "Deleted",
                member_id: JSON.parse(localStorage.getItem("userinfo"))?._id,
                password: userPassword ,
                selectedItems:selectedItems?.length
              });  
              if(response?.status === 200){
                if(selectedItems.length > 1){
                  setShowMultiNotify(true)
                }else{
                  setShowNotification(true)
                }
                setSelectedPubLenght(selectedItems.length)
              }
              setSelectedItems([])             
        })
        
        await Promise.all(updatePromises);

        if (name) {
          history(`/dashboard/${name}`)
          setIsLoading(false);
          updateOriginalData((prevData) => {
            const updatedData = prevData.filter(item => !selectedItems.includes(item._id));
            return updatedData;
          });
          onClose()
        }

      } else {
        setIsLoading(true);

        const deletePromises = selectedItems?.map(async (id) => {

          let deleteAPI = `${process.env.REACT_APP_API_URL}/${type}/remove/${id}`;

          if(type == 'page') {
              deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepage/${id}`;
          }
          if(type == 'publication') {
              deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepublication/${id}`;
          }

          await axios.delete(
            deleteAPI,
            {
              status: "Deleted",
              member_id: JSON.parse(localStorage.getItem("userinfo"))?._id,
              password: userPassword 
            });
        })

        await Promise.all(deletePromises);
        
        updateOriginalData((prevData) => {
          const updatedData = prevData.filter(item => !selectedItems.includes(item._id));          
          return updatedData;
        });

        //{ setPages && setPages(prevPages => prevPages.filter(item => !selectedItems.includes(item._id))); }

        onClose()
        setIsLoading(false);
        document.body.classList.remove('hidden-message');
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 400) {
          setError(true);
      } else {
          setError(true);
          setErrorMassage(error.response.data.error);
      }
    }
  }

  return (
    <>
      {
        isOpen && (
          <div className='welcomeModal'>
            <h3 className='welcomeTitle'>Delete {name}</h3>
            <p className='welcomeText'>Are you sure you want to delete these {name}? This action cannot be undone.</p>
            <div className='popupFields'>
                <label className='fieldsLabel'>Enter Password</label>
                <input type="password" name='password' className='inputFields'  placeholder='Type Password' autoComplete='off' ref={inputRef} onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) } onKeyPress={(e) => handleKeyPress(e)}/>
                {validatePassword === true && (
                    <p className="validateError">The field is not filled</p>
                )}
                {error === true && (
                    <p className="validateError">{errorMassage}</p>
                )}
            </div>
            <div className="welcomeModalbtn">
              <button className="cancel" type="button" onClick={onClose}>Cancel</button>
              <button className="savePicture" type="button" onClick={handleDelete}>
                {isLoading ?
                  <div className="settingPage-loader">
                    <div className="ring-loader"></div>
                  </div>
                  : "Delete"}
              </button>
            </div>
          </div>
        )
      }      
    </>
  )
}

export default MultipleDeletePages