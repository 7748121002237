import { Link } from 'react-router-dom';
import '.././App.css';
import noResults from '.././images/noResults.svg'
import plus from '.././images/plus.svg'

function NoResultsSubscriber(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No Subscriber found</p>
                <p className='noResDesc'>Your search “Landing page design” did not match any projects. Please try again.</p>
            </div>
        </div>
    );
}

export default NoResultsSubscriber;
