import React, { useEffect, useState } from 'react'
import card from '../../images/credit-card-02.svg';
import arrow from '../../images/leftArrow-icon.svg';
import pay_method from '../../images/Settings/pay_method.svg'
import BusinessPlan_Step3 from './BusinessPlan_Step3';
import axios from 'axios';

function BusinessPlan_Step2({ activeStep, setActiveStep }) {

    const [name, setName] = useState('');
    const [monthInput, setMonthInput] = useState('');
    const [yearInput, setYearInput] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cvvCode, setCvvCode] = useState('');
    const [validateName, setValidateName] = useState(false);
    const [validateExpiry, setValidateExpiry] = useState(false);
    const [validateNumber, setValidateNumber] = useState(false);
    const [validateCVV, setValidateCVV] = useState(false);
    const [step2Modal, setStep2Modal] = useState(true);
    const [step3Modal, setStep3Modal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    function cc_format(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []

        var i;
        var len;

        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    const focusSibling = function (target, direction, callback) {
        const nextTarget = target[direction];
        nextTarget && nextTarget.focus();

        callback && callback(nextTarget);
    }

    const handleMonthInput = (event) => {
        const value = event.target.value.toString();
        if (value.length === 1 && value > 1) {
            event.target.value = "0" + value;
        }
        if (value === "00") {
            event.target.value = "01";
        } else if (value > 12) {
            event.target.value = "12";
        } {
            2 <= event.target.value.length && focusSibling(event.target, "nextElementSibling", (nextTarget) => {
                if (nextTarget.tagName === "INPUT" && nextTarget.type === "text") {
                    nextTarget.focus();
                    nextTarget.select();
                }
            });
        }
        setMonthInput(value);
        if (value.length > 0) {
            setValidateExpiry(false);
        }
    };

    const handleYearInput = (event) => {
        const value = event.target.value.toString();
        if (event.key === "Backspace" && event.target.selectionStart === 0) {
            focusSibling(event.target, "previousElementSibling");
        }
        setYearInput(value);
        if (value.length > 0) {
            setValidateExpiry(false);
        }

    };

    const openStep3 = async () => {
        setIsLoading(true);
        try {
            const updateData = {
                nameCard: name,
                cardNumber: cardNumber,
                expirationDate: monthInput + '/' + yearInput,
                cvv: cvvCode,
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/billingaddress/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                updateData
            );
            setStep3Modal(true);
            setStep2Modal(false);
            setActiveStep(3);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <>
            {step2Modal && (<div className='welcomeModal planModal'>
                <div className='welcomeFace'>
                    <img
                        src={card}
                        alt="card-icon" />
                </div>
                <h3 className='welcomeTitle'> Payment method</h3>
                <p className='welcomeText'>Update your card details.</p>
                <div className="popupRow popupRow-one">
                    <div className='card-holder-name'>
                        <p>Name on card</p>
                        <input autoFocus={true} type="text" onChange={e => {
                            setName(e.target.value)
                            setValidateName(false)
                        }} onBlur={e => {
                            if (e.target.value.length === 0) {
                                setValidateName(true)
                            }
                        }} value={name} placeholder='The name on the card' className={(validateName === true) ? 'inputError' : ''} />
                        {
                            (validateName === true) ? <p className='validateError'>The field is not filled</p> : ''
                        }
                    </div>

                    <div>
                        <p>Expiry</p>
                        <div className={`${validateExpiry ? 'inputError' : ''} exp-wrapper`}>
                            <input
                                className='expMonth'
                                autocomplete="off"
                                id="month"
                                maxlength="2"
                                pattern="[0-9]*"
                                inputmode="numerical"
                                placeholder="MM"
                                type="text"
                                value={monthInput}
                                data-pattern-validate
                                onInput={handleMonthInput}
                                onChange={(e) => setMonthInput(e.target.value)}
                            />

                            <input
                                className='expYear'
                                autocomplete="off"
                                id="year"
                                maxlength="2"
                                pattern="[0-9]*"
                                inputmode="numerical"
                                placeholder="YY"
                                type="text"
                                value={yearInput}
                                data-pattern-validate
                                onInput={handleYearInput}
                                onChange={(e) => setYearInput(e.target.value)}
                                onKeyDown={handleYearInput} />
                        </div>
                        {
                            (validateExpiry === true) ? <p className='validateError'>The field is not filled</p> : ''
                        }
                    </div>
                </div>
                <div className="popupRow popupRow-two">
                    <div className='paymentMethod'>
                        <p>Card number</p>
                        <input type="text" placeholder='1234 1234 1234 1234' className={(validateNumber === true) ? 'cardNumber inputError' : 'cardNumber'} value={cardNumber} onChange={e => {

                            var test = cc_format(e.target.value)
                            setCardNumber(test)

                            if (test.length > 0) {
                                setValidateNumber(false)
                            }

                        }} onBlur={e => {
                            if (e.target.value.length === 0) {
                                setValidateNumber(true)
                            }
                        }} />
                        {
                            (validateNumber === true) ? <p className='validateError'>The field is not filled</p> : ''
                        }
                        <img className='payMethod' src={pay_method} alt="Icon" />
                    </div>
                    <div className='cvvBlock'>
                        <p>CVV</p>
                        <input type="text" value={cvvCode} onChange={e => {
                            if (e.target.value.length > 4) {
                                return false
                            } else {
                                setCvvCode(e.target.value)
                                if (e.target.value.length > 0) {
                                    setValidateCVV(false)
                                }
                            }
                        }} onBlur={e => {
                            if (e.target.value.length === 0) {
                                setValidateCVV(true)
                            }
                        }} className={(validateCVV === true) ? 'inputError' : ''} />
                        {
                            (validateCVV === true) ? <p className='validateError'>The field is not filled</p> : ''
                        }
                    </div>
                </div>
                <h3 className='welcomeTitle'>Billing info</h3>
                <p className='welcomeText'>Update your card details.</p>
                <ul className='welcomeDots'>
                    <li className={activeStep === 1 ? 'active' : ''}></li>
                    <li className={activeStep === 2 ? 'active' : ''}></li>
                    <li className={activeStep === 3 ? 'active' : ''}></li>
                </ul>
                <div className="welcomeModalbtn">
                    <button className="savePicture" type="button" onClick={openStep3}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : <>Continue <img
                                src={arrow}
                                alt="arrow-icon" /></>}
                    </button>
                </div>
            </div>)}
            {step3Modal && <BusinessPlan_Step3 activeStep={activeStep} setActiveStep={setActiveStep} />}
        </>

    )
}

export default BusinessPlan_Step2