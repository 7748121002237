import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '.././App.css';
import mail from '../images/mail.svg'
import back from '../images/back.svg'
import axios from "axios";

function CheckEmail(props) {
  // user send email function
  const userSendmail = () => {
    const data = {
      email: props?.email,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/user/emailsend`, data)
      .then((response) => { 
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="loginWindow checkEmail">
      <img src={mail} alt="Login" />

      <p className="loginTitle">Check your email</p>
      <p className="loginSubtitle">
        We sent a password reset link to: <br/ ><span>{props?.email}</span>
      </p>

      <p className="loginBottom" onClick={userSendmail}>
        Didn’t receive the email? <Link>Click to resend</Link>
      </p>

      <p className="loginBottom resetBottom">
        <Link to={"/login"}>
          <img src={back} alt="Back" />
          Back to log in
        </Link>
      </p>
    </div>
  );
}

export default CheckEmail;
