import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '.././App.css';
import done from '../images/done.svg'
import back from '../images/back.svg'

function PassReset(props) {


    return (
        <div className="loginWindow checkEmail">
            <img className='passResetImg' src={done} alt="Login" />

            <p className='loginTitle'>Password reset</p>
            <p className='loginSubtitle'>Your password has been successfully reset. Click below to log in magically.</p>

            <Link to={'/login'}><button className='blueButton loginButton passResetButton'>Login to your account</button></Link>
        </div>
    );
}

export default PassReset;
