import '../.././App.css';
import locked from '../../images/Settings/locked.svg'

function NavItemLocked(props) {


    return (
        <div className='navItem navItemLocked'>
            <div className="navEditableVisible">
                <p>{props.name}</p>
                <img src={locked} alt="Down" />
            </div>
        </div>
    );
}

export default NavItemLocked;
