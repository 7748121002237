import { useEffect } from 'react';
import '.././App.css';
import notify_ok from '.././images/notification_ok.svg'
import notify_close from '.././images/notify_close.svg'
import { Link } from 'react-router-dom';

function Notification(props) {
    setTimeout(() => {
        props.setOpenNotify(false)
    }, 5000);

    return (
        <div className={(props.openNotify === true) ? 'notification notificationActive' : 'notification'}>
            <img className='notifyStatus' src={(props.type === 'success') ? notify_ok : ''} alt="Notify Status" />
            <div className="notificationMessage">
                <p className="notificationTitle">{props.title}</p>
                {(props.link == "")?
                    <p className="notificationDesc">{props.text}</p>
                :
                <div className='notificationLinks'>
                    <p className="notificationDesc">{props.text}</p>
                    <p className='linkText'><Link to={props.to}>{props.link}</Link></p>
                </div>
                }
            </div>
            <img onClick={e => props.setOpenNotify(false)} className='notifyClose' src={notify_close} alt="Close Notify" />
        </div>
    );
}

export default Notification;
