import CustomLinkIcon from "../images/Settings/social/Custom Link.svg";
import AirbnbIcon from "../images/Settings/social/Airbnb.svg";
import AmazonIcon from "../images/Settings/social/Amazon.svg";
import AppleIcon from "../images/Settings/social/Apple.svg";
import ApplePodcastIcon from "../images/Settings/social/Apple Podcast.svg";
import BehanceIcon from "../images/Settings/social/Behance.svg";
import DeviantArtIcon from "../images/Settings/social/DeviantArt.svg";
import DiscordIcon from "../images/Settings/social/Discord.svg";
import DribbbleIcon from "../images/Settings/social/Dribbble.svg";
import DropboxIcon from "../images/Settings/social/Dropbox.svg";
import EtsyIcon from "../images/Settings/social/Etsy.svg";
import FacebookIcon from "../images/Settings/social/Facebook.svg";
import FacebookMessengerIcon from "../images/Settings/social/Facebook Messenger.svg";
import FigmaIcon from "../images/Settings/social/Figma.svg";
import FlickrIcon from "../images/Settings/social/Flickr.svg";
import GitHubIcon from "../images/Settings/social/GitHub.svg";
import GitLabIcon from "../images/Settings/social/GitLab.svg";
import GoogleIcon from "../images/Settings/social/Google.svg";
import HouzzIcon from "../images/Settings/social/Houzz.svg";
import InstagramIcon from "../images/Settings/social/Instagram.svg";
import KickstarterIcon from "../images/Settings/social/Kickstarter.svg";
import LinkedinIcon from "../images/Settings/social/Linkedin.svg";
import MediumIcon from "../images/Settings/social/Medium.svg";
import NotionIcon from "../images/Settings/social/Notion.svg";
import PayPalIcon from "../images/Settings/social/PayPal.svg";
import PinterestIcon from "../images/Settings/social/Pinterest.svg";
import ProductHuntIcon from "../images/Settings/social/Product Hunt.svg";
import QuoraIcon from "../images/Settings/social/Quora.svg";
import RedditIcon from "../images/Settings/social/Reddit.svg";
import SubstackIcon from "../images/Settings/social/Substack.svg";
import SignalIcon from "../images/Settings/social/Signal.svg";
import SkypeIcon from "../images/Settings/social/Skype.svg";
import SlackIcon from "../images/Settings/social/Slack.svg";
import SnapchatIcon from "../images/Settings/social/Snapchat.svg";
import SoundCloudIcon from "../images/Settings/social/Soundcloud.svg";
import SpotifyIcon from "../images/Settings/social/Spotify.svg";
import StackOverflowIcon from "../images/Settings/social/Stack Overflow.svg";
import TelegramIcon from "../images/Settings/social/Telegram.svg";
import TikTokIcon from "../images/Settings/social/TikTok.svg";
import TripAdvisorIcon from "../images/Settings/social/Tripadvisor.svg";
import TumblrIcon from "../images/Settings/social/Tumblr.svg";
import TwitchIcon from "../images/Settings/social/Twitch.svg";
import TwitterIcon from "../images/Settings/social/Twitter.svg";
import VimeoIcon from "../images/Settings/social/Vimeo.svg";
import VKontakteIcon from "../images/Settings/social/VKontakte.svg";
import WhatsAppIcon from "../images/Settings/social/WhatsApp.svg";
import WikipediaIcon from "../images/Settings/social/Wikipedia.svg";
import YahooIcon from "../images/Settings/social/Yahoo.svg";
import YelpIcon from "../images/Settings/social/Yelp.svg";
import YouTubeIcon from "../images/Settings/social/YouTube.svg";
import ZoomIcon from "../images/Settings/social/Zoom.svg";

const Socialicons = [
  {
    title: "Custom Link",
    icon: CustomLinkIcon,
  },
  {
    title: "Airbnb",
    icon: AirbnbIcon,
  },
  {
    title: "Amazon",
    icon: AmazonIcon,
  },
  {
    title: "Apple",
    icon: AppleIcon,
  },
  {
    title: "Apple Podcast",
    icon: ApplePodcastIcon,
  },
  {
    title: "Behance",
    icon: BehanceIcon,
  },
  {
    title: "DeviantArt",
    icon: DeviantArtIcon,
  },
  {
    title: "Discord",
    icon: DiscordIcon,
  },
  {
    title: "Dribbble",
    icon: DribbbleIcon,
  },
  {
    title: "Dropbox",
    icon: DropboxIcon,
  },
  {
    title: "Etsy",
    icon: EtsyIcon,
  },
  {
    title: "Facebook",
    icon: FacebookIcon,
  },
  {
    title: "Facebook Messenger",
    icon: FacebookMessengerIcon,
  },
  {
    title: "Figma",
    icon: FigmaIcon,
  },
  {
    title: "Flickr",
    icon: FlickrIcon,
  },
  {
    title: "GitHub",
    icon: GitHubIcon,
  },
  {
    title: "GitLab",
    icon: GitLabIcon,
  },
  {
    title: "Google",
    icon: GoogleIcon,
  },
  {
    title: "Houzz",
    icon: HouzzIcon,
  },
  {
    title: "Instagram",
    icon: InstagramIcon,
  },
  {
    title: "Kickstarter",
    icon: KickstarterIcon,
  },
  {
    title: "Linkedin",
    icon: LinkedinIcon,
  },
  {
    title: "Medium",
    icon: MediumIcon,
  },
  {
    title: "Notion",
    icon: NotionIcon,
  },
  {
    title: "PayPal",
    icon: PayPalIcon,
  },
  {
    title: "Pinterest",
    icon: PinterestIcon,
  },
  {
    title: "Product Hunt",
    icon: ProductHuntIcon,
  },
  {
    title: "Quora",
    icon: QuoraIcon,
  },
  {
    title: "Reddit",
    icon: RedditIcon,
  },
  {
    title: "Substack",
    icon: SubstackIcon,
  },
  {
    title: "Signal",
    icon: SignalIcon,
  },
  {
    title: "Skype",
    icon: SkypeIcon,
  },
  {
    title: "Slack",
    icon: SlackIcon,
  },
  {
    title: "Snapchat",
    icon: SnapchatIcon,
  },
  {
    title: "SoundCloud",
    icon: SoundCloudIcon,
  },
  {
    title: "Spotify",
    icon: SpotifyIcon,
  },
  {
    title: "Stack Overflow",
    icon: StackOverflowIcon,
  },
  {
    title: "Telegram",
    icon: TelegramIcon,
  },
  {
    title: "TikTok",
    icon: TikTokIcon,
  },
  {
    title: "TripAdvisor",
    icon: TripAdvisorIcon,
  },
  {
    title: "Tumblr",
    icon: TumblrIcon,
  },
  {
    title: "Twitch",
    icon: TwitchIcon,
  },
  {
    title: "Vimeo",
    icon: VimeoIcon,
  },
  {
    title: "VKontakte",
    icon: VKontakteIcon,
  },
  {
    title: "WhatsApp",
    icon: WhatsAppIcon,
  },
  {
    title: "Wikipedia",
    icon: WikipediaIcon,
  },
  {
    title: "X (Formerly Twitter)",
    icon: TwitterIcon,
  },
  {
    title: "Yahoo",
    icon: YahooIcon,
  },
  {
    title: "Yelp",
    icon: YelpIcon,
  },
  {
    title: "YouTube",
    icon: YouTubeIcon,
  },
  {
    title: "Zoom",
    icon: ZoomIcon,
  },
];

export default Socialicons;
