import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Register from './Pages/Register';
import ErrorPage from './Pages/Error';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import NewPass from './Pages/NewPass';
import RegisterNotification from './Pages/RegisterNotification';
import './App.css';

function App() {
  
  // Retrieving user info and completion status from localStorage
  const userinfo = localStorage.getItem("userinfo");
  const isCompleted = localStorage.getItem("isCompleted") ? JSON.parse(localStorage.getItem("isCompleted")) : true;

  // Function to check if the user is authenticated and completed necessary steps
  const isAuthenticated = userinfo && isCompleted;

  return (
    <section className='board' >
      <BrowserRouter>
        <Routes>
        <Route path="/" element={ isAuthenticated ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) : ( <Navigate to="/login" /> ) } />
          {/* Route for '/' */}
          <Route path="/*" element={ isAuthenticated ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) : ( <Navigate to="/login" /> ) } />
          {/* Route for dashboard sub-pages */}
          <Route path="/dashboard/*" element={ isAuthenticated ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) : ( <Navigate to="/login" /> ) } />
          {/* Route for forgot password */}
          <Route path="/forgot" element={ isAuthenticated ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <ForgotPassword /> ) } />
          {/* Route for setting new password */}
          <Route path="/setnewpassword" element={ isAuthenticated ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <NewPass /> ) } />
          {/* Route for registration pages */}
          <Route path="/register/*" element={ isAuthenticated ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <Register /> ) } />
          {/* Route for login */}
          <Route path="/login" element={ isAuthenticated ? ( isCompleted ? <Navigate to="/dashboard" /> : <Login /> ) : ( <Login /> ) } />
          {/* Route for any other undefined paths */}
          <Route path="*" element={isCompleted ? <ErrorPage /> : <RegisterNotification />} />
        </Routes>
      </BrowserRouter>
    </section >
  );
}

export default App;