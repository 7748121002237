import React, { useEffect, useState } from 'react';
import axios from "axios";

import Loader from "../Components/Loader/Loader";
import EditMediaPopup from "../Components/EditMediaPopup"
import NoResults from '../Components/NoResults';

import filePdfIcon from "../images/file-pdf-icon.svg";
import fileDocIcon from ".././images/file-doc-icon.svg";
import fileDocxIcon from ".././images/file-docx-icon.svg";
import filePptIcon from ".././images/file-ppt-icon.svg";
import filePptmIcon from ".././images/file-pptm-icon.svg";
import filePptxIcon from ".././images/file-pptx-icon.svg";
import fileXlsIcon from ".././images/file-xls-icon.svg";
import fileXlsmIcon from ".././images/file-xlsm-icon.svg";
import fileXlsxIcon from ".././images/file-xlsx-icon.svg";
import fileVideoIcon from ".././images/file-video-icon.svg";
import fileAudioIcon from ".././images/file-audio-icon.svg";

const GridLayout = ({ userMedia, setUserMedia, setUploadNewmedia, uploadNewmedia, selectedItems, setSelectedItems, isLoading,setShowUpgradeModel ,paymentMethods, setSaveNotify}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((_id) => _id !== itemId);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                return updatedSelectedItems;
            }
        });
    };

    const handleEditClick = (itemId) => {
        setShowPopup(true)
        setEditedItemId(itemId);
    };

    const maxSizeInBytes = 128 *  1024*  1024; // 128MB
    const [fileList, setFileList] = useState({ '^image/': 'Images' });
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessages, setErrorMessages] = useState([])
    const allowFIleType = {
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    }

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        let newErrorMessages = [];
        setErrorMessages([]);
        setShowUpgradeBtn(false);
        setIsImageDragging(false);
        const files = e.dataTransfer.files;

        let uploadedMediaId = 1;

        for (const file of files) {
            const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));

            if (!isAllowedFileType) {
                const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                newErrorMessages.push(errorMessage);

                if( Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type)) ) {
                    setShowUpgradeBtn(true);
                }
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }

            setErrorMessages(newErrorMessages)

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: '',
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setUserMedia((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                // Update the uploadMedia object with the response data
                setUserMedia((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }
        }

        setIsImageDragging(false);
        setErrorMessages(newErrorMessages);
    };

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const handleUpgrade = () =>{
        setShowUpgradeModel(true);
    }
    useEffect(() => {

        if( paymentMethods?.plan !== 'free_plan' ) {
            setFileList({
                '^image/' : 'Images', 
                '^video/': 'Videos', 
                'application/pdf': 'PDF', 
                'application/doc': 'DOC', 
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX', 
                'application/vnd.ms-powerpoint': 'PPT', 
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX', 
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM', 
                'application/vnd.ms-excel': 'XLS', 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX', 
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            })
        } else {
            setFileList({
                '^image/' : 'Images', 
            })
        }

        
    }, [paymentMethods]);

    
    
    return (
        <div className={isImageDragging ? 'grid-container media-grid-layout draging' : 'grid-container media-grid-layout '} onDragOver={handleFileDragOver}
            onDragLeave={handleFileDragLeave}
            onDrop={handleGalleryImageDrop}>
            {isLoading ?
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
                :
                <>
                    {(userMedia && userMedia.length > 0) ?
                        <div className="gridIitem">
                            {userMedia?.map((media) => (
                                <div className={`gridImg ${(media.type).includes('image') ? 'type-image' : 'type-doc'}`} key={media._id}>
                                    {media?.isLoading ?
                                        <div className="uploadPicture">
                                            <Loader />
                                        </div>
                                        :
                                        <>
                                            <img src={mediaIconSet[media.type] ? mediaIconSet[media.type] : media.url} onClick={() => handleEditClick(media._id)} alt={media.name} className='item' />

                                            {(!(media.type).includes('image')) && <div className='itemName'> {media.name} </div>}

                                            <input type="checkbox" checked={selectedItems.includes(media._id)} onChange={() => handleCheckboxChange(media._id)} />
                                        </>
                                    }
                                </div>
                            ))}

                            {showPopup &&
                                <EditMediaPopup setShowPopup={setShowPopup} item={userMedia.find(item => item._id === editedItemId)} userMedia={userMedia} setUserMedia={setUserMedia} setSaveNotify={setSaveNotify} />
                            }
                        </div>
                        :
                        <NoResults type="media-gallery" name="Media Library" onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} />
                    }
                </>

            }
            {errorMessages && errorMessages.length > 0 && (
                <div className="errorMessages">
                    {errorMessages.map((errorMessage, index) => (
                        <>
                            <p key={index} className="error-message" >{errorMessage}</p>
                            {showUpgradeBtn && <p className='upgrade-message' onClick={handleUpgrade}>Upgrade Now</p>} 
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GridLayout;