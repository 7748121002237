import React, { useEffect, useState } from "react";
import axios from 'axios';

import NoResults from "./NoResults";
import '.././App.css';

import { Link } from "react-router-dom";

import defaulPicture from '.././images/defaulPicture.svg'
import Copy from '.././images/copy-i.svg'
import Facebook from '.././images/Fb-i.svg'
import Twitter from '.././images/X-icon.svg'
import Linkdin from '.././images/Linkdin-i.svg'
import WhatsApp from '.././images/whatsApp-i.svg'

function stripHtmlTags(html) {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}

function foramteDate(date) {
    var newDate = new Date(date);

    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    return newDate.toLocaleDateString('en-US', options);
}

function LatesInfoBox() {

    const [latestPublication, setLatestPublication] = useState();
    const [latestNewsLatter, setLatestNewsLatter] = useState();
    const [tooltipVisible, setTooltipVisible] = useState(false); 

    const profileSlug = localStorage.getItem('userProfileSlug')
    const baseUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/`;
    const [urlInput, setURLInput] = useState('')

    useEffect(() => {

        const fetchData = async () => {
            try {
              const latestInfoResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getLatestInfo/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
              );

              setLatestPublication( latestInfoResponse.data.result.latestPublication );
              setLatestNewsLatter( latestInfoResponse.data.result.latestNewsletter );
              setURLInput(`${baseUrl}${latestInfoResponse.data.result.latestPublication?.url_address || latestInfoResponse.data.result.latestPublication?.title }`)
              
            } catch (error) {
              console.log(error, "error");
            }
          }


        fetchData();
    }, []);
    
    const handleCopyUrl = () => {
        navigator.clipboard.writeText(urlInput);
        setTooltipVisible(true); 
        setTimeout(() => {
            setTooltipVisible(false); 
        }, 2000);
    };

    return (
        <>
            <div className="LatesInfo">                      
                <div className="LatesBox">
                
                    {latestPublication ?
                        <>
                            <div className="LatesInfoBox">
                                <p className='LatesInfoTitle'>Latest Publication</p>
                                <div className="LatesInfoNotification">
                                    <div className="LatesInfoImg">
                                        <img src={defaulPicture} alt="defaulPicture" />
                                    </div>
                                    <div className="LatesInfoContent">
                                        <p className='LatesInfoTitel'>
                                        <Link className='editTitle' to={`/dashboard/publication/${latestPublication?._id}`}>{latestPublication?.title}</Link>
                                            </p>
                                        <p className='LatesInfoText'>{stripHtmlTags(latestPublication?.description)}</p>
                                        <p className="LatesInfoDate">{foramteDate(latestPublication?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="analyticsButtons">
                                <div className="graphButton greenPercent">
                                    <p className="analyticsButtonTitle">
                                        Visitors
                                    </p>
                                    <div>
                                        <p className="graphNumber">210k</p>
                                        <span className="graphPercent">
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 1.5V8.5M5 8.5L1.5 5M5 8.5L8.5 5" stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                                </path>
                                            </svg>
                                            19%
                                        </span>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">Pageviews</p>
                                    <div>
                                        <p className="graphNumber">384k</p>
                                        <span className="graphPercent">
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 1.5V8.5M5 8.5L1.5 5M5 8.5L8.5 5" stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                                </path>
                                            </svg>
                                            16%
                                        </span>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">SHARE:</p>
                                    <div>
                                        <div className="LatesInfoIcon">
                                            <img
                                                src={Copy}
                                                alt="copyLogo"
                                                onClick={handleCopyUrl} 
                                            />
                                            <img src={Facebook} alt="facebookLogo" />
                                            <img src={Twitter} alt="twitterLogo" />
                                            <img src={Linkdin} alt="LinkdinLogo" />
                                            <img src={WhatsApp} alt="whatsAppLogo" />
                                            {tooltipVisible && <div className="text-tooltip">URL Copied!</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>  
                            <NoResults type="publication" name="publication" />
                        </>
                    }
                </div>

                <div className="LatesBox">
                    { latestNewsLatter ? 
                        <>
                            <div className="LatesInfoBox">
                                <p className='LatesInfoTitle'>Latest Newsletter</p>
                                <div className="LatesInfoNotification">
                                    <div className="LatesInfoImg">
                                        <img src={defaulPicture} alt="defaulPicture" />
                                    </div>
                                    <div className="LatesInfoContent">
                                        <p className='LatesInfoTitel'>
                                            <Link className='editTitle' to={`/dashboard/newsletter/${latestNewsLatter?._id}`}>{latestNewsLatter?.subject}</Link>
                                        </p>
                                        <p className='LatesInfoText'>{stripHtmlTags(latestNewsLatter?.description)}</p>
                                        <p className="LatesInfoDate">{foramteDate(latestNewsLatter?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="analyticsButtons">
                                <div className="graphButton greenPercent">
                                    <p className="analyticsButtonTitle">
                                    Recipients
                                    </p>
                                    <div>
                                        <p className="graphNumber">104</p>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">visitors</p>
                                    <div>
                                        <p className="graphNumber">50</p>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">Open RATE</p>
                                    <div>
                                        <p className="graphNumber">48%</p>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <NoResults type="newsletter" name="Newsletters" />
                        </>
                    }                    
                </div>
            </div>
        </>
    );
}

export default LatesInfoBox;
