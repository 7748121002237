import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import '../.././App.css';

import close from '../../images/Settings/close.svg'
import lock_icon from '../../images/lock-icon.svg'
import { getRemainingMonths } from '../../utils/functions';

const stripePromise = loadStripe("pk_test_51PFt1t07v2TyS3y7ylP92wKRAwxymdw9dojOSJZ2nBMGtYUtFdV0FpuLHK6ZgBv9a9cJZQu2IgXRE7GfT7q9BLHq00Ou7adzyJ");

const planPricing = {
    unlimited_plan : {
        month: 18,
        year: 172
    },
    personal_assistant_plan: {
        month: 24,
        year: 230
    }
};

const PaymentDetailsForm = ({setIsChangePlanOpen, updateData, selectedPlan, selectedInterval, activatedPlan, setOpenSuccessPlanPopup, isTrailPlan, setNotification}) => {

    const stripe = useStripe();
    const elements = useElements();
    
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const buttonText = ( activatedPlan === 'free_plan' || isTrailPlan ) ? 'Upgrade' : 'Change Plan'

    let price = 0; 
    if( selectedPlan !== 'free_plan' ) {
        price = planPricing?.[selectedPlan]?.[selectedInterval];    
        if( isTrailPlan ) {
            price = (planPricing?.[selectedPlan]?.[selectedInterval]) * 0.75;
        }   
    }


    const cardElementStyles = {
        base: {
            color: '#000000',
            fontFamily: 'Inter, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '22px',
            height: '22px',
          '::placeholder': {
            color: '#667085',
          },
        },
    };

    const handleUpdateButton = async () => {        
        try {
            setIsLoading(true);

            setErrorMessage('')

            if (!stripe || !elements) {
                setIsLoading(false);
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);

            const userResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );

            const userData = userResponse?.data?.result?.user;

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    name: name,
                    email: userData.email,
                },
            });

            if (error) {
                setErrorMessage(error.message)
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateSubscription/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    {
                        paymentMethodId: paymentMethod.id,
                        selectedPlan: selectedPlan, 
                        selectedInterval: selectedInterval,
                    }
                );

                await updateData();

                if(setIsChangePlanOpen) {
                    setIsChangePlanOpen(false);
                }
                setOpenSuccessPlanPopup(true)
                if(selectedPlan === "freePlan"){
                    setNotification(true)
                }
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log({error, message: error.message});
        }        
    }

    return(
        <>
        <div className="popupRow popupRow-one">
            <div className='card-holder-name'>
                <p>Name on card</p>
                <input type="text" onChange={e => setName(e.target.value)} value={name} placeholder='Name on the card'/>
            </div>

            <div>
            <p>Expiry</p>
                <div className="exp-wrapper">
                    <CardExpiryElement options={{style: cardElementStyles}} />            
                </div>
            </div>
        </div>

        <div className="popupRow popupRow-two">
            <div className='paymentMethod'>
                <p>Card number</p>
                <CardNumberElement options={{ style: cardElementStyles, showIcon: true }} />
            </div>
            <div className='cvvBlock'>
                <p>CVV</p>
                <CardCvcElement />
            </div>
        </div>  

        { errorMessage && 
            <p className='error-message'>{errorMessage}</p>
        }

        { selectedPlan !== 'free_plan' &&
            <div className='billing-price-wrapp'>
                { isTrailPlan &&
                    <>                    
                        <p className='billing-price'><strong>Subtotal:</strong> ${planPricing?.[selectedPlan]?.[selectedInterval]}/{selectedInterval}, biilled {selectedInterval === 'year' ? 'yearly' : 'monthly'}</p>
                        <p className='billing-price'><strong>Discount:</strong> 25% off for {selectedInterval === 'year' ? 'one year' : '3 months' }</p>
                    </>   
                }
                <p className='billing-price'><strong>Total:</strong> ${price}/{selectedInterval}, biilled {selectedInterval === 'year' ? 'yearly' : 'monthly'}</p>
            </div>
        }

        <div className="popupButtons">
            {setIsChangePlanOpen && <button className='settingsWhite billingSaveBtn' onClick={e => setIsChangePlanOpen(false)}>Cancel</button> }
            <button className={`settingsBlue billingSaveBtn btnText ${activatedPlan}-plan`} onClick={e => handleUpdateButton()}> 
            {isLoading ? <div className="settingPage-loader"><div className="ring-loader"></div></div> : buttonText}
            </button>
        </div>

        <div className='popupLockIcon'>
            <div className='LockIcon'>
                <img src={lock_icon} alt="lock_icon" />
                <div className='popupLockIcon-content'>
                    <h2>Secured Checkout - SSL Encrypted</h2>
                    <p>Ensuring your financial and personal details are secured during every transaction. </p>
                </div>
            </div>
        </div>
        </>
    )
}

function ChangePlan(props) {

    const popupRef = useRef();

    const [selectedPlan, setSelectedPlan] = useState(props?.planDetails?.plan === 'free_plan' ? 'unlimited_plan' : props?.planDetails?.plan);
    const [selectedInterval, setSelectedInterval] = useState(props?.planDetails?.interval);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showDiscountedPrice, setShowDiscountedPrice] = useState(false);
    
    let activatedPlan  = (props?.isExpired === 'yes') ? '' : props?.planDetails?.plan;
    let activeInterval = (props?.isExpired === 'yes') ? '' : props?.planDetails?.interval;
    const activePriceId = props.planDetails?.pirceId || '';

    const discountedMonthlyPrices = ['price_1PsVb707v2TyS3y7Dj8ydpre', 'price_1PsVed07v2TyS3y703F0IJHW'];
    const discountedYearlyPrices = ['price_1PsVbg07v2TyS3y7sjSftV1D', 'price_1PsVfE07v2TyS3y7i9rK60xI'];
    
    const defaultPaymentMethod = props?.paymentMethods?.length > 0 ? props?.paymentMethods.find(paymentMethod => paymentMethod?.default) : 0;
    const selectedMehod = defaultPaymentMethod?.id || 0;

    const currentDate = props?.currentDate || '';
    const discountedSubscriptionEnd = props.discountedSubscriptionEnd || '';

    const popupTitle = (activatedPlan === 'free_plan' || props?.isTrailPlan) ? 'Upgrade your account' : 'Change your plan';
    let activeText = 'Active';
    let isScheduled = false;

    const isStipeConnected = props?.isStipeConnected || false;

    if(props.planDetails?.scheduleDate && props.planDetails?.schedulePlan && props.planDetails?.plan !== props.planDetails?.schedulePlan ) {
        activatedPlan = props.planDetails?.schedulePlan;
        activeInterval = props.planDetails?.scheduleInterval;
        activeText = 'Scheduled';
        isScheduled = true;
    }
    
    useEffect(() => {
        setShowDiscountedPrice(false);

        if(selectedInterval === 'month' && ! discountedYearlyPrices.some(priceId => priceId === activePriceId)  ) {
            setShowDiscountedPrice(true);
        }

        if(selectedInterval === 'year' && ! discountedMonthlyPrices.some(priceId => priceId === activePriceId)  ) {
            setShowDiscountedPrice(true);
        }
    }, [selectedInterval]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (props?.isChangePlanOpen && props?.setIsChangePlanOpen && popupRef?.current && !popupRef?.current.contains(e.target)) {
                props?.setIsChangePlanOpen(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [props?.isChangePlanOpen]);

    const handleChangePlan = async () => {
        try {
            setIsLoading(true);
            setErrorMessage('')
            if( selectedInterval === activeInterval && selectedPlan === activatedPlan ) {
                setErrorMessage('Selected plan already activated');
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateSubscription/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    {
                        selectedPlan: selectedPlan, 
                        selectedInterval: selectedInterval,
                        plan: activatedPlan, 
                        interval: activeInterval,
                        paymentMethodId: selectedMehod,
                        isScheduled: isScheduled                        
                    }
                );

                await props.updateData();
                props.setIsChangePlanOpen(false);
                if (selectedPlan !== "free_plan" && isScheduled === false) {
                    props?.setOpenSuccessPlanPopup(true)
                }
                if(selectedPlan === "free_plan" && (activatedPlan === "unlimited_plan" || activatedPlan === "personal_assistant_plan")){
                    props.setNotification(true)
                }
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log({error, message: error.message});
        }
    }

    const handleSelectPlan = (plan) => {
        setErrorMessage('');

        if( isStipeConnected && plan === 'free_plan' ) {
            return false;
        }

        if( (activatedPlan === 'free_plan' && plan === 'free_plan') || (activatedPlan === plan && activeInterval === selectedInterval) ) {
            return false;
        } else {
            setSelectedPlan(plan);
        }        
    }

    return (
        <div className="popupContainer">
            <div className='popupOverlay'></div>
            <div className={`popupBlock popupBlockPlan ${PaymentDetailsForm ? 'activeClose' : ''}`} ref={popupRef}>
                <div className='popupBlock-wrapper'>
                <div className="popupBlock-inner">
                    { props?.setIsChangePlanOpen && 
                        <div className="popupTop popupTopClose closePlan">
                            <img src={close} onClick={e => props.setIsChangePlanOpen(false)} alt="Icon" />
                        </div>
                    }

                    <div className="popupContent">
                        <p className="popupTitle">{popupTitle}</p>
                        <p className="popupDesc">Flexibly adapt to your changing needs by easily adjusting your plan at any time.</p>
                    </div>

                    {( (selectedPlan === "unlimited_plan" || selectedPlan === "personal_assistant_plan" )) && (
                    <div className="step-plan-buttons">
                        <button onClick={() => setSelectedInterval('month')} className={selectedInterval === 'month' ? 'active' : ''} >Monthly</button>
                        <button onClick={() => setSelectedInterval('year')} className={selectedInterval === 'year' ? 'active' : ''}>Yearly (Save 20%)</button>
                        <button className='step-plan-background'></button>
                    </div>
                    )}

                    <div className="popupContent-main">
                        <div className={`popupContent-item ${(activatedPlan === 'free_plan' || isStipeConnected ) && 'active-plan-background'}`}> 
                            <div className='popupContent-inner' onClick={(e) => handleSelectPlan("free_plan")}>
                                { (activatedPlan !== 'free_plan' && ! isStipeConnected) && (
                                        <div className='changePlan-check'>
                                            <input type="radio" className="changePlan-checkBox" name='changePlan' checked={selectedPlan === 'free_plan'}/>
                                        </div>
                                    )
                                }
                                <div className='changePlan-subText'>
                                    <div className='active-plan'>
                                        <h2>Free Plan</h2>
                                        { (activatedPlan === 'free_plan') && <span className='active-sub'>{activeText}</span> }
                                    </div>
                                    <p className='changePlan-text'>We are here to help manage your web profile, in order to make sure it’s always updated with your latest content. <br />
                                    <Link to={"/"}>Read more</Link> about the service.</p>
                                    {isStipeConnected && <p className="validateError">To switch to the free plan, you first need to disconnect your Stripe account.</p>}
                                </div>
                            </div>
                        </div>
                        <div className={`popupContent-item ${(activatedPlan === 'unlimited_plan' && activeInterval === selectedInterval) && 'active-plan-background'}`}> 
                            <div className='popupContent-inner' onClick={(e) => handleSelectPlan("unlimited_plan")}>
                                {
                                    !(activatedPlan === 'unlimited_plan' && activeInterval === selectedInterval)  &&  (
                                        <div className='changePlan-check'>
                                            <input type="radio" className="changePlan-checkBox" name='changePlan' checked={selectedPlan === "unlimited_plan"} />
                                        </div>
                                    )
                                }
                                <div className='changePlan-subText'>
                                    <div className='active-plan'>
                                        <h2>Unlimited Plan <span className='changePlan-inner-text'>{selectedInterval === 'year' ? '$172/year' : '$18/month'}</span></h2>
                                        { (activatedPlan === 'unlimited_plan' && activeInterval === selectedInterval) && <span className='active-sub'>{activeText}</span> }
                                    </div>
                                    <p className='changePlan-text'>We are here to help manage your web profile, in order to make sure it’s always updated with your latest content. <br />
                                    <Link to={"/"}>Read more</Link> about the service.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`popupContent-item ${(activatedPlan === 'personal_assistant_plan' && activeInterval === selectedInterval) && 'active-plan-background'}`}> 
                            <div className='popupContent-inner' onClick={(e) => handleSelectPlan("personal_assistant_plan")}>
                                {
                                    !(activatedPlan === 'personal_assistant_plan' && activeInterval === selectedInterval) && (
                                        <div className='changePlan-check'>
                                            <input type="radio" className="changePlan-checkBox" name='changePlan' checked={selectedPlan === "personal_assistant_plan"} />
                                        </div>
                                    )
                                }
                                <div className='changePlan-subText'>
                                    <div className='active-plan'>
                                        <h2>Personal Assistant Plan <span className='changePlan-inner-text'>{selectedInterval === 'year' ? '$230/year' : '$24/month'}</span></h2>
                                        { (activatedPlan === 'personal_assistant_plan' && activeInterval === selectedInterval) && <span className='active-sub'>{activeText}</span> }
                                    </div>
                                    <p className='changePlan-text'>We are here to help manage your web profile, in order to make sure it’s always updated with your latest content. <br />
                                    <Link to={"/"}>Read more</Link> about the service.</p>
                                </div>
                            </div>
                        </div>

                        { ((activatedPlan === 'personal_assistant_plan' && (selectedPlan === "free_plan" || selectedPlan === "unlimited_plan")) ||  (activatedPlan === 'unlimited_plan' && selectedPlan === "free_plan") ) &&                        
                            <div className="popupContent-item popupContent-item-last"> 
                                <div className='popupContent-inner'>
                                    <p className='notice-text'><span>Notice: </span>Downgrading to the Free plan will limit your content access and you will lose your pro features.</p>
                                </div>
                            </div>
                        }
                        {(selectedPlan == 'free_plan' && selectedMehod === 0) && (
                            <div className="popupButtons">
                                {props?.setIsChangePlanOpen && <button className='settingsWhite billingSaveBtn' onClick={e => props.setIsChangePlanOpen(false)}>Cancel</button> }
                                <button className={`settingsBlue billingSaveBtn btnText ${activatedPlan}-plan`} onClick={handleChangePlan}> 
                                {isLoading ? <div className="settingPage-loader"><div className="ring-loader"></div></div>
                                : "Change Plan"}</button>
                            </div>  
                        )}
                    </div>   

                    { (selectedMehod !== 0) && 
                        <>
                            { errorMessage &&  <p className='error-message'>{errorMessage}</p> }

                            { selectedPlan !== 'free_plan' &&
                                ((discountedSubscriptionEnd && showDiscountedPrice && getRemainingMonths(currentDate, discountedSubscriptionEnd)) ?                                    
                                    <p className='billing-price'>Total: ${(planPricing[selectedPlan][selectedInterval]) * 0.75} / {selectedInterval}, biilled {selectedInterval === 'year' ? 'for one year' : `for ${getRemainingMonths(currentDate, discountedSubscriptionEnd)}`}</p>
                                    :
                                    <p className='billing-price'>Total: ${planPricing[selectedPlan][selectedInterval]} / {selectedInterval}, biilled {selectedInterval === 'year' ? 'yearly' : 'monthly'}</p>
                                )
                            }
                            <div className="popupButtons">
                                {props?.setIsChangePlanOpen && <button className='settingsWhite billingSaveBtn' onClick={e => props.setIsChangePlanOpen(false)}>Cancel</button> }
                                <button className='settingsBlue billingSaveBtn' onClick={handleChangePlan}> 
                                    {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    : "Change Plan"}</button>
                            </div>
                        </>
                    }
                    
                </div>

                {(selectedPlan !== 'free_plan' && selectedMehod === 0) && (
                    <>
                        <div className="popupChange">
                            {props?.setIsChangePlanOpen && 
                                <div className="popupTop popupTopClose closePayment">
                                    <img src={close} onClick={e => props.setIsChangePlanOpen(false)} alt="Icon" />
                                </div>
                            }
                            
                            <p className="popupTitle">Payment details</p>
                            <p className="popupDesc">Add your payment details in order to activate your new plan.</p>

                            <Elements stripe={stripePromise} >
                                <PaymentDetailsForm setIsChangePlanOpen={props?.setIsChangePlanOpen} updateData={props.updateData} selectedPlan={selectedPlan} selectedInterval={selectedInterval} activatedPlan={activatedPlan} isScheduled={isScheduled} setOpenSuccessPlanPopup={props?.setOpenSuccessPlanPopup} isTrailPlan={props?.isTrailPlan || false} setNotification={props?.setNotification}/>
                            </Elements>
                        </div>
                    </>
                )}
                </div>
            </div>
        </div>
    );
}

export default ChangePlan;
