import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import NotificationMessage from '../NotificationMessage';

function EditCategoryModel(props) {
    const { isOpen, onClose, categoryId, categoryName, updateCategoriesData } = props;

    const modelRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(categoryName);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }

        const checkIfClickedOutside = (e) => {
            if (modelRef.current && !modelRef.current.contains(e.target)) {
                onClose('cancel')
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    }, [isOpen]);
    useEffect(() => {
        if (isOpen) {
          const handleKeyDown = (e) => {
            const deleteMediaElement = document.getElementById('deleteMedia');
            if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
                editCategory()
            } else if (e?.key === "Escape") {
              onClose('cancel');
    
            }
          };
          document.addEventListener("keydown", handleKeyDown);
          return () => {
            document.removeEventListener("keydown", handleKeyDown);
          };
        }
      }, [isOpen]);
    if (!isOpen) {
        return null;
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            editCategory();
        }
    };

    const editCategory = async () => {
        setErrorMessage('');

        if( inputValue.trim() !== '' ) {

            setIsLoading(true);

            //make first letter capital
            const new_iv = inputValue.trim().charAt(0).toUpperCase() + inputValue.slice(1);

            const updateStatus = await updateCategoriesData(categoryId, new_iv, 'edit');

            if( updateStatus != true ) {
                setErrorMessage(updateStatus);
            }

            if( updateStatus == true) {
                const authorId = JSON.parse(localStorage.getItem("userinfo"))?._id;

                const newCategoryData = {
                    author_id: authorId,
                    name: new_iv
                }


                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/categories/updatecategory/${categoryId}`,
                    newCategoryData,
                );

                if (response.status == 201) {
                    setErrorMessage(response.data.message);
                    setIsLoading(false);
                }

                if (response.status == 200) {
                    setErrorMessage('');
                    setIsLoading(false);

                    setShowNotification(true);
                    onClose('update')
                    // setShowNotification(true);
                }
            }
        } else {
            // Display an error message or take appropriate action here
            setErrorMessage("Category name is required!");
            setIsLoading(false);
        }
    };

    return (
        <>
            {isOpen && (
                <div className='welcomeModal addCategoryPage' ref={modelRef} id="deleteMedia">
                    <h3 className='welcomeTitle'>Edit Category</h3>
                    <label className="welcomeLabel">Name</label>
                    <input id="category_name_inp" className='welcomeInput' ref={inputRef} value={inputValue} onChange={handleInputChange} onKeyPress={(e) => handleKeyPress(e)} type="text" placeholder="Category name" />

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <div className="welcomeModalbtn">
                        <button className="cancel" type="button" onClick={() => { onClose('cancel'); setInputValue(''); setErrorMessage(''); }} >Cancel</button>
                        <button className="savePicture" type="button" onClick={editCategory}>
                            {isLoading ?
                                <div className="updatebutton-loader ">
                                    <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                    Update
                                </>}
                        </button>
                    </div>

                </div>
            )}    
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`Category has been successfully edited!`} />
            )}
        </>
    )
}

export default EditCategoryModel