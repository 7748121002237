import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SingleDeletePage(props) {

    const { activeFilter, selectedItems, type, index, updateOriginalData, setPages, isOpen, onClose, name ,data, setShowNotification} = props;

    const history = useNavigate()
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [validatePassword, setValidatePassword] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const [error, setError] = useState(false);
    const [errorMassage, setErrorMassage] = useState('');

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef?.current.focus();
        }
        if (isOpen) {
            const handleKeyDown = (e) => {
                const deleteMediaElement = document.getElementById('deleteMedia');
                if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
                    deletePages()
                }else if(e?.key === "Escape"){
                    onClose()

                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isOpen]);
    
    if (!isOpen) {
        return null;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            deletePages(index);
        }
    };


    if( activeFilter && selectedItems ) {
        data = data.find(item => item._id === selectedItems[0]);
    }

    const deletePages = async (id) => {
        try {
            setError(false)
            setValidatePassword(false)
            if (data.status !== "Deleted"){
                setIsLoading(true);

                let updateAPI = `${process.env.REACT_APP_API_URL}/${type}/update/${id}`;

                if(type == 'page') {
                    updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${id}`;

                }
                if(type == 'publication') {
                    updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepublication/${id}`;

                }

                const response = await axios.post(
                    updateAPI,
                    {
                        status: "Deleted",
                        previous_status: data.status,
                        member_id: JSON.parse(localStorage.getItem("userinfo"))?._id,
                        password: userPassword 
                    }
                );
                if(response?.status === 200){
                    setShowNotification(true)
                }
                { name && history(`/dashboard/${name}`) 
                document.body.classList.remove('hidden-message');  }

                updateOriginalData((prevData) => {
                    const updatedData = prevData.filter((item) => item._id !== index);
                    return updatedData;
                });
                if (setPages) {
                    setPages((prevPages) => prevPages.filter((item) => item._id !== index));
                }
                onClose();
                setIsLoading(false);
            }else {
                setIsLoading(true);

                let deleteAPI = `${process.env.REACT_APP_API_URL}/${type}/remove/${id}`;

                if(type == 'page') {
                    deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepage/${id}`;
                }
                if(type == 'publication') {
                    deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepublication/${id}`;
                }

                await axios.delete(deleteAPI);
                { name && history(`/dashboard/${name}`) }
                updateOriginalData((prevData) => {
                    const updatedData = prevData.filter((item) => item._id !== index);
                    return updatedData;
                });
                if (setPages) {
                    setPages((prevPages) => prevPages.filter((item) => item._id !== index));
                }
                onClose();
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                setErrorMassage(error?.response?.data?.error);
            }
        }
    };
    return (
        <>
            {isOpen && (<div className='welcomeModal singleDeletePage'>
                <h3 className='welcomeTitle'>Delete {type}</h3>
                <p className='welcomeText'>Are you sure you want to delete this {type}? This action cannot be undone.</p>
                <div className='popupFields'>
                    <label className='fieldsLabel'>Enter Password</label>
                    <input type="password" name='password' className='inputFields' placeholder='Type Password' autoComplete='off' ref={inputRef} onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) } onKeyPress={(e) => handleKeyPress(e)}/>
                    {validatePassword === true && (
                        <p className="validateError">The field is not filled</p>
                    )}
                    {error === true && (
                        <p className="validateError">{errorMassage}</p>
                    )}
                </div>
                <div className="welcomeModalbtn" id='deleteMedia'>
                    <button className="cancel" type="button" onClick={onClose}>Cancel</button>
                    <button className="savePicture" type="button" onClick={() => deletePages(index)}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}
                    </button>
                </div>
            </div>)}

        </>
    )
}

export default SingleDeletePage