import React, { useState } from 'react'
import heart from '../../images/heart.svg';
import arrow from '../../images/leftArrow-icon.svg';
import BusinessPlan_Step2 from './BusinessPlan_Step2';

function BusinessPlan_Step1(props) {

    const [step1Modal, setStep1Modal] = useState(true);
    const [step2Modal, setStep2Modal] = useState(false);
    const [activeStep, setActiveStep] = useState(1);

    const toConvertUppercase = (string) => {
        const words = string.split(" ");

        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    const Name = toConvertUppercase(props.name);


    const openStep2 = () => {
        setStep2Modal(true);
        setStep1Modal(false);
        setActiveStep(2);
    }

    return (
        <>
            {step1Modal && (
                <div className='welcomeModal planModal'>
                    <div className='welcomeFace'>
                        <img
                            src={heart}
                            alt="heart-icon" />
                    </div>
                    <h3 className='welcomeTitle'>{Name}, welcome back!</h3>
                    <p className='welcomeText'>We noticed that when you deactivated your account, you used one of our premium plans. Therefore, to keep all your content live and continue enjoying the benefits of that plan, please update your payment information.<br /><br />
                        Remember, you always have the flexibility to switch to another plan later if you wish. <br /><br />
                        Let's get you back on track!</p>
                    <ul className='welcomeDots'>
                        <li className={activeStep === 1 ? 'active' : ''}></li>
                        <li className={activeStep === 2 ? 'active' : ''}></li>
                        <li className={activeStep === 3 ? 'active' : ''}></li>
                    </ul>
                    <div className="welcomeModalbtn">
                        <button className="savePicture" type="button" onClick={openStep2}>Continue <img
                            src={arrow}
                            alt="arrow-icon" /></button>
                    </div>
                </div>)}
            {step2Modal && <BusinessPlan_Step2 activeStep={activeStep} setActiveStep={setActiveStep} />}
        </>
    )
}

export default BusinessPlan_Step1